import axios from "axios";
import { BASE_URL } from "../../constants/config";

// Parent Flow

export const createConversation =
  (parent = "", child = "") =>
  async (dispatch) => {
    try {
      let token = localStorage.getItem("token");
      dispatch({
        type: "createConvoRequest",
      });
      const { data } = await axios.post(
        `${BASE_URL}/api/conversation/createConversation`,
        {
          parentId: parent,
        },
        {
          headers: {
            Authorization: `token ${JSON.parse(token)}`,
          },
        }
      );
      dispatch({
        type: "createConvoSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "createConvoFailure",
        payload: error,
      });
    }
  };

export const getConvo =
  (id = "") =>
  async (dispatch) => {
    let token = localStorage.getItem("token");
    try {
      dispatch({
        type: "getConvoRequest",
      });
      const { data } = await axios.get(
        `${BASE_URL}/api/message/getMessage?conversationId=${id}`,

        {
          headers: {
            Authorization: `token ${JSON.parse(token)}`,
          },
        }
      );
      dispatch({
        type: "getConvoSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "getConvoFailure",
        payload: error,
      });
    }
  };

export const getConvoId = () => async (dispatch) => {
  let token = localStorage.getItem("token");
  try {
    dispatch({
      type: "getConvoIdRequest",
    });
    const { data } = await axios.get(
      `${BASE_URL}/api/conversation/createConversation`,

      {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      }
    );
    dispatch({
      type: "getConvoIdSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "getConvoIdFailure",
      payload: error,
    });
  }
};

export const postMessage =
  (conv = "", msg = "", sender = "", role = "") =>
  async (dispatch) => {
    let token = localStorage.getItem("token");
    try {
      dispatch({
        type: "postMsgRequest",
      });
      const { data } = await axios.post(
        `${BASE_URL}/api/message/createMessage`,
        {
          conversationId: conv,
          text: msg,
          senderId: sender,
          senderRole: role,
        },
        {
          headers: {
            Authorization: `token ${JSON.parse(token)}`,
          },
        }
      );
      dispatch({
        type: "postMsgSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "postMsgFailure",
        payload: error,
      });
    }
  };
