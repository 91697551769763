import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../../redux/dispatcher/login";
import { useForm } from "react-hook-form";
import { Backdrop, CircularProgress } from "@mui/material";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordToggle, setPasswordToggle] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [errorState, seterrorState] = useState(false);

  const { loginData, loading, loginError, isAuthenticated, error } =
    useSelector((state) => state.log);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/assignee");
    }
  }, [isAuthenticated, loading]);

  const onSubmit = (e) => {
    dispatch(setLogin(email, password));
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <section>
      <Header />
      <body>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="main">
          <div className="grid">
            <div className="child1">
              <img
                src={require("../../images/logo.png")}
                width="150px"
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              />
              <div>
                <h1>
                  Ladder of
                  <br /> Remote Access
                </h1>
                <p>
                  A sophisticated, yet easy way to communicate within
                  <br />a family unit where parents get the support they need
                  <br />
                  to convey messages to assignee in the household for
                  <br />
                  task purpose, etc.
                </p>

                <Link to={"/sign-up"}>
                  <button id="signup">Sign Up</button>
                </Link>
                <Link to={"/sign-in-child"}>
                  <button id="signup" style={{ marginLeft: "5px" }}>
                    Sign In as an Assignee
                  </button>
                </Link>
              </div>
            </div>
            <div className="child2">
              <h1>Sign In</h1>
              <p>
                Hey, enter your details to get
                <br />
                sign in to your account
              </p>
              <p
                style={{
                  color: "red",
                  alignSelf: "flex-start",
                }}
              >
                {`${
                  error?.response?.data?.message === undefined
                    ? ""
                    : error?.response?.data?.message
                }`}
              </p>

              {errors.email && (
                <p
                  style={{
                    color: "red",
                    alignSelf: "flex-start",
                    paddingBottom: 0,
                  }}
                >
                  *Email address is required
                </p>
              )}
              <input
                type="email"
                id="email"
                name="email"
                {...register("email", { required: true })}
                aria-invalid={errors.email ? "true" : "false"}
                placeholder="Enter email address"
                required
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginTop: errors.email || loginError ? 5 : 16 }}
              />
              {errors.password && (
                <p
                  style={{
                    color: "red",
                    alignSelf: "flex-start",
                    paddingBottom: 0,
                  }}
                >
                  *Password is required
                </p>
              )}
              <div
                className="psswrd"
                style={{ marginTop: errors.password ? 0 : 16 }}
              >
                <input
                  type={!passwordToggle ? "password" : "text"}
                  id="password"
                  name="password"
                  {...register("password", { required: true })}
                  aria-invalid={errors.password ? "true" : "false"}
                  placeholder="Enter password"
                  required
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span onClick={() => setPasswordToggle(!passwordToggle)}>
                  {passwordToggle ? (
                    <i className="material-symbols-outlined" id="eye">
                      visibility
                    </i>
                  ) : (
                    <i className="material-symbols-outlined" id="eye">
                      visibility_off
                    </i>
                  )}
                </span>
              </div>
              <button onClick={() => navigate("/forget-password")} id="trouble">
                Having trouble in sign in?
              </button>

              <button
                id="signin"
                onClick={handleSubmit(onSubmit)}
                style={{ cursor: "pointer" }}
              >
                Sign In
              </button>

              <img id="img5" src="./images/Vector.png" width="60" alt="" />
              <img id="img2" src="./images/Vector2.png" width="100px" alt="" />
            </div>
          </div>
        </div>
      </body>
    </section>
  );
}
