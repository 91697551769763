import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./constants/PrivateRoute";
import Assignee from "./Pages/Parent/Assignee";
import Chat from "./Pages/Parent/Chat";
import ChatChild from "./Pages/Child/ChatChild";
import DashboardChild from "./Pages/Child/DashboardChild";
import LoraPackages from "./Pages/LoraPackages";
import LoraWebPage from "./Pages/LoraWebPage";
import SignIn from "./Pages/Parent/SignIn";
import SignUp2 from "./Pages/Parent/SignUp2";
import TaskChild from "./Pages/Child/TaskChild";
import TaskParent from "./Pages/Parent/TaskParent";
import Test from "./Pages/Test";
import ChildSignIn from "./Pages/Child/ChildSignIn";
import Dashboard from "./Pages/Parent/Dashboard";
import FirstTimePackage from "./components/FirstTimePackage";
import ViewPackageDetails from "./Pages/Parent/ViewPackageDetails";
import ForgetPassword from "./Pages/Parent/ForgetPassword";
import ResetPassword from "./Pages/Parent/ResetPassword";
import { useSelector } from "react-redux";

function App() {
  const { childLoginData, loginData } = useSelector((state) => state.log);
  return (
    <Router>
      <Routes>
        {/* Private Routes */}
        <Route element={<PrivateRoute />}>
          <Route
            path="/dashboard"
            element={childLoginData ? <DashboardChild /> : <Dashboard />}
          />
          <Route path="/packages" element={<LoraPackages />} />
          <Route path="/package" element={<FirstTimePackage />} />
          {/* <Route path="/dashboard-child" element={<DashboardChild />} /> */}
          <Route
            path="/assignee"
            element={childLoginData ? <DashboardChild /> : <Assignee />}
          />
          <Route path="/chat" element={<Chat />} />
          <Route path="/chat-child" element={<ChatChild />} />
          <Route path="/task-child" element={<TaskChild />} />
          <Route
            path="/task"
            element={childLoginData ? <TaskChild /> : <TaskParent />}
          />
          <Route path="/my-packages" element={<ViewPackageDetails />} />
        </Route>

        {/* Public Routes */}
        <Route path="/" element={<LoraWebPage />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-in-child" element={<ChildSignIn />} />
        <Route path="/sign-up" element={<SignUp2 />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
        {/* <Route path="/test" element={<Test />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
