import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import HeaderChild from "../../components/HeaderChild";
import { BASE_URL } from "../../constants/config";
import {
  createConversation,
  getConvo,
  postMessage,
} from "../../redux/dispatcher/chat";
import {
  Sidebar,
  Menu as Meno,
  MenuItem as MenoItem,
  useProSidebar,
} from "react-pro-sidebar";
import SideDrawer from "../../components/SideDrawer";
import SideDrawerChild from "../../components/SideDrawerChild";

const ChatChild = () => {
  const dispatch = useDispatch();

  const { collapseSidebar } = useProSidebar();

  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [convoID, setConvoID] = useState("");
  const [attendees, setAttendees] = useState([]);

  const { childLoginData, token } = useSelector((state) => state.log);

  useEffect(() => {
    dispatch(createConversation(1, childLoginData?.id));
    getParent();
  }, []);

  useEffect(() => {
    getConvoChild();
  }, []);

  useEffect(() => {
    dispatch(getConvo(convoID));
  }, [msg, convoID]);

  const { message } = useSelector((state) => state.chat);

  const [parent, setParent] = useState([]);

  const getParent = () => {
    let token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}/api/children/getParent`, {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setParent(res.data.parent);
      })
      .catch((e) => {
        console.log(`msg error ${e}`);
      });
  };

  const getConvoChild = () => {
    let token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}/api/conversation/getConversationIdChild`, {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setConvoID(res.data.conversation.id);
        setAttendees(res.data.attendees);
      })
      .catch((e) => {
        console.log(`msg error ${e}`);
      });
  };

  const PostMessage = (msg) => {
    if (msg === "") return false;
    axios
      .post(`${BASE_URL}/api/message/createMessage`, {
        conversationId: convoID,
        text: msg,
        senderId: childLoginData.id,
        senderRole: "child",
      })
      .then((res) => {
        console.log(res.data);
        setMsg("");
      })
      .catch((e) => {
        console.log(`msg error ${e}`);
      });
  };

  let menuBar = document.getElementById("menuBar");
  let mainBar = document.getElementById("mainBar");

  function toggleBar() {
    menuBar.classList.toggle("open-bar");
    mainBar.classList.toggle("open-bar2");
  }

  return (
    <section>
      <Header />
      <div class="sidebar" id="menuBar">
        <SideDrawerChild />
      </div>
      <div className="main" id="mainBar">
        <HeaderChild title="Chat" toggle={toggleBar} />
        <main>
          <div className="grid4">
            <div className="col2">
              <div className="openchat">
                <div className="name">
                  <img
                    src={
                      parent?.image
                        ? parent?.image
                        : require("../../images/user.png")
                    }
                    width="55px"
                    height="55px"
                    alt=""
                    style={{ borderRadius: 360 }}
                  />

                  <div>
                    <h4 style={{ marginRight: "15px", color: "black" }}>
                      {parent.name}
                    </h4>
                  </div>
                  {attendees?.map((i) =>
                    i?.children?.map((j, ind) => {
                      return (
                        j?.id !== childLoginData?.id && (
                          <div
                            style={{
                              marginRight: "15px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                j.image
                                  ? j.image
                                  : require("../../images/user.png")
                              }
                              width="55px"
                              height="55px"
                              alt=""
                              style={{ borderRadius: 360 }}
                            />

                            <div>
                              <h4
                                style={{
                                  color: "black",
                                  fontWeight: "inherit",

                                  fontFamily: "inherit",
                                }}
                              >
                                {j?.name}
                              </h4>
                            </div>
                          </div>
                        )
                      );
                    })
                  )}
                </div>
                <div id="spans">
                  <span
                    className="material-symbols-outlined"
                    onClick={() => dispatch(getConvo(convoID))}
                  >
                    refresh
                  </span>
                  <span className="material-symbols-outlined">more_vert</span>
                </div>
              </div>
              <hr />
              <div className="chatntime">
                <div
                  style={{
                    height: 350,
                    overflowY: "scroll",
                  }}
                >
                  {message?.map((i, index) => {
                    return message?.length === 0 ? (
                      <div>You are connected</div>
                    ) : (
                      <>
                        {i?.senderId === childLoginData?.id ? (
                          <div
                            className="darker"
                            key={index}
                            style={{ paddingRight: 10 }}
                          >
                            <div>
                              <span className="time-left">
                                {moment(i?.createdAt).startOf("LTS").fromNow()}
                              </span>
                              <p>{i.text}</p>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="container"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "0px",
                            }}
                          >
                            {i?.senderRole === "parent" ? (
                              <>
                                <img
                                  src={
                                    parent?.image
                                      ? parent?.image
                                      : require("../../images/user.png")
                                  }
                                  alt="Avatar"
                                  className="right"
                                  style={{
                                    height: 40,
                                    width: 40,
                                    borderRadius: "50%",
                                    marginRight: 5,
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    i?.child?.image
                                      ? i?.child?.image
                                      : require("../../images/user.png")
                                  }
                                  alt="Avatar"
                                  className="right"
                                  style={{
                                    height: 40,
                                    width: 40,
                                    borderRadius: "50%",
                                    marginRight: 5,
                                  }}
                                />
                              </>
                            )}
                            <div style={{ flexDirection: "column" }}>
                              <span className="time-left">
                                {moment(i?.createdAt).startOf("LTS").fromNow()}
                              </span>
                              <p>{i?.text}</p>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
                {/* <div ref={messagesEndRef} /> */}

                <hr />
                <div className="message">
                  <span
                    className="material-symbols-outlined"
                    onClick={() => setOpen(!open)}
                  >
                    sentiment_satisfied
                  </span>

                  <input
                    type="text"
                    placeholder="Enter your message"
                    onChange={(e) => setMsg(e.target.value)}
                    value={msg}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch(PostMessage(msg));
                      }
                    }}
                  />
                  <span
                    className="material-symbols-outlined"
                    onClick={() => dispatch(PostMessage(msg))}
                  >
                    send
                  </span>
                </div>
              </div>
            </div>
            {open && (
              <div
                style={{
                  position: "absolute",
                  bottom: "2.5%",
                  right: "2.5%",
                }}
              >
                <Picker
                  data={data}
                  previewPosition="none"
                  // onClickOutside={() => setOpen(false)}
                  onEmojiSelect={(e) => {
                    setMsg(msg?.concat(e.native));
                    setOpen(false);
                  }}
                />
              </div>
            )}
          </div>
        </main>
      </div>
    </section>
  );
};

export default ChatChild;
