import React, { useEffect } from "react";
import Banner from "../../components/Banner";
import Header from "../../components/Header";
import SideDrawer from "../../components/SideDrawer";
import HeaderComp from "../../components/HeaderParent";
import { useState } from "react";
import { useSelector } from "react-redux";

import MaterialTable from "material-table";
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import { ThemeProvider, createTheme } from "@mui/material";
import moment from "moment";

const ViewPackageDetails = () => {
  const [editProfile, seteditProfile] = useState(false);

  let menuBar = document.getElementById("menuBar");
  let mainBar = document.getElementById("mainBar");

  function toggleBar() {
    document.getElementById("menuBar").classList.toggle("open-bar");
    document.getElementById("mainBar").classList.toggle("open-bar2");
  }

  const {
    isAuthenticated,
    getParent,
    loginData,
    updateLoading,
    update,
    token,
  } = useSelector((state) => state.log);

  const [paymentDetail, setpaymentDetail] = useState([]);

  const getPaymentTable = () => {
    let token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}/api/parent/getPaymentDetails`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        setpaymentDetail(res.data.payments);
      })
      .catch((e) => {
        console.log(`msg error ${e}`);
      });
  };

  useEffect(() => {
    getPaymentTable();
  }, []);

  const columns = [
    { title: "ID", field: "id" },
    { title: "Amount", field: "amount" },
    { title: "Currency", field: "currency" },
    { title: "Package", field: "package" },
    {
      title: "Expiry Date",
      field: "expiry",
      render: (rowData) => moment(rowData).format("DD/MM/YYYY"),
    },
  ];

  const defaultMaterialTheme = createTheme();

  return (
    <section>
      <Header />
      <div class="sidebar" id="menuBar" style={{ zIndex: "999" }}>
        <SideDrawer />
      </div>
      <div className="main" id="mainBar">
        <Banner />
        <HeaderComp
          title={"Payments"}
          seteditProfile={seteditProfile}
          toggle={toggleBar}
        />

        <div style={{ margin: "40px" }}>
          {/* Name */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "50px",
            }}
          >
            {getParent?.image === null ? (
              <img
                src={require("../../images/user.png")}
                width="85px"
                height="85px"
                alt=""
                style={{ borderRadius: "360px" }}
              />
            ) : (
              <img
                src={getParent?.image}
                width="85px"
                height="85px"
                alt=""
                style={{ borderRadius: "360px" }}
              />
            )}
            <h4 style={{ marginLeft: "15px" }}>{getParent?.name}</h4>
          </div>

          {/* Table */}
          <ThemeProvider theme={defaultMaterialTheme}>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <MaterialTable
              title={`${getParent?.name}'s payments`}
              data={paymentDetail}
              columns={columns}
              direction="ltr"
            />
          </ThemeProvider>
        </div>
      </div>
    </section>
  );
};

export default ViewPackageDetails;
