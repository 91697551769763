import React, { useState } from "react";
import Header from "../components/Header";
import HeaderComp from "../components/HeaderParent";
import { Slide, Backdrop, CircularProgress, Typography } from "@mui/material";
import { packages } from "../assets/data";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, publishableKey } from "../constants/config";
import axios from "axios";
import StripeCheckout from "react-stripe-checkout";
import moment from "moment";
import { getParentbyId } from "../redux/dispatcher/login";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SideDrawer from "../components/SideDrawer";
import { GreenDialogModal, RedDialogModal } from "../components/DialogModal";

export default function LoraPackages() {
  // const publishableKey =
  //   "pk_test_51MX0ZzFE9Md7HTdle9owXmA2hQ2K7w0EDDhRkgamMUMVJ11TihXy7pYQsxXhqQUHY8Iz0hukZnbrUn9lzcRpuGBu00L2n8KKhE";

  const { loginData, getParent } = useSelector((state) => state.log);

  const dispatch = useDispatch();

  const [confirm, setConfirm] = useState(false);

  const navigate = useNavigate();

  const current = new Date();

  const [loading, setloading] = useState(false);
  const [restrict, setRestrict] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const payNow = async (token, price, assignee, id, expiry) => {
    setloading(true);
    setConfirm(false);
    setError(false);
    console.log(expiry);
    try {
      let access_token = localStorage.getItem("token");
      console.log(price);
      const response = await axios.post(
        `${BASE_URL}/api/payment/paymentApi`,
        {
          price: price,
          currency: "usd",
          token: token,
          userId: loginData.id,
          maxAssignee: assignee,
          expiryDate: expiry
            ? moment(current).add(1, "M").format("MM-DD-YYYY")
            : moment(getParent?.expiryDate).add(1, "M").format("MM-DD-YYYY"),
          packageId: id,
        },
        {
          headers: {
            Authorization: `token ${JSON.parse(access_token)}`,
          },
        }
      );
      if (response.status === 200) {
        console.log(response);
        setConfirm(true);
        setloading(false);
        setError(false);
      }
    } catch (error) {
      console.log(error);
      setConfirm(false);
      setloading(false);
      setError(true);
      seterrorMsg(error);
    }
  };

  useEffect(() => {
    dispatch(getParentbyId(loginData?.id));
  }, []);

  let menuBar = document.getElementById("menuBar");
  let mainBar = document.getElementById("mainBar");

  function toggleBar() {
    menuBar.classList.toggle("open-bar");
    mainBar.classList.toggle("open-bar2");
  }

  return (
    <>
      <div class="sidebar" id="menuBar">
        <SideDrawer />
      </div>
      <div className="main" id="mainBar">
        <Header />
        {/* <!--HEADER--> */}

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <RedDialogModal
          value={restrict}
          setValue={setRestrict}
          onClick={() => setRestrict(false)}
          title="Package"
          subtitle="You cannot buy the same package again."
          width={"20%"}
          buttonText="Okay"
        />
        <RedDialogModal
          value={error}
          setValue={setError}
          onClick={() => setError(false)}
          title="Package"
          subtitle="Your card has insufficient funds."
          width={"20%"}
          buttonText="Okay"
        />
        <GreenDialogModal
          value={confirm}
          setValue={setConfirm}
          onClick={() => {
            setConfirm(false);
            navigate("/assignee");
            dispatch(getParentbyId(loginData?.id));
          }}
          title="Success!"
          subtitle="Payment Successful"
        />

        <HeaderComp title="Lora Packages" toggle={toggleBar} />

        <main id="bg-clr">
          <div className="grid1">
            <div className="lora">
              <p style={{ fontSize: 32, color: "#000" }}>Lora Packages</p>
              <p>World's best website for adults and minors...</p>
              <div className="butns">
                <p style={{ color: "#000" }} id="but">
                  MONTHLY
                </p>
              </div>
            </div>
            <div className="packages">
              {packages.map((pac, index) => {
                return (
                  <div className="caard" id={pac.color} key={pac.id}>
                    <img src={pac.image} width="30px" alt="" />
                    <p>
                      Full 1 Parental
                      <br />
                      Access With{" "}
                      <span>
                        {pac.assignee}
                        <br /> Assignee
                      </span>
                    </p>
                    <h3>${pac.price}</h3>
                    {getParent?.expiryDate !== null && (
                      <p>
                        <p style={{ fontSize: "12px", fontWeight: "600" }}>
                          *Your new subscription date*
                        </p>
                        {moment(getParent?.expiryDate).format("DD-MM-YYYY") +
                          " - " +
                          moment(getParent?.expiryDate)
                            .add(1, "M")
                            .format("DD-MM-YYYY")}
                      </p>
                    )}
                    <StripeCheckout
                      stripeKey={publishableKey}
                      label="Pay Now"
                      name="Pay With Credit Card"
                      amount={pac.price * 100}
                      description={`Your total is $${pac.price}`}
                      token={(token) => {
                        {
                          moment(getParent?.expiryDate).format("MM-DD-YYYY") <
                          moment(current).format("MM-DD-YYYY")
                            ? payNow(
                                token,
                                Math.floor(pac.price * 100),
                                pac.assignee,
                                pac.id,
                                true
                              )
                            : payNow(
                                token,
                                Math.floor(pac.price * 100),
                                pac.assignee,
                                pac.id,
                                false
                              );
                        }
                      }}
                    >
                      <button id="buy">Buy Now</button>
                    </StripeCheckout>
                  </div>
                );
              })}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
