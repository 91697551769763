import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Tab,
  Tabs,
  Drawer,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../components/Banner";
import {
  RedDialogModal,
  ConfirmationDialog,
  GreenDialogModal,
} from "../../components/DialogModal";
import Header from "../../components/Header";
import HeaderComp from "../../components/HeaderParent";
import NewTas from "../../components/NewTas";
import SideDrawer from "../../components/SideDrawer";
import {
  clearSuccess,
  getFilteredTask,
  getTask,
  getTaskHistory,
  updateTaskStatus,
} from "../../redux/dispatcher/tasks";
import ImageViewer from "react-simple-image-viewer";
import { BASE_URL } from "../../constants/config";
import axios from "axios";
import UpdateTask from "../../components/UpdateTask";
import AccordionComponent from "../../components/AccordionComponent";

const SecondComp = ({ id, type, setValue }) => {
  const [filters, setFilters] = useState("");
  const [expiry, setExpiry] = useState(false);
  const [openAddNewTask, setOpenAddNewTask] = useState(false);

  const { getParent } = useSelector((state) => state.log);
  const {
    taskLoading,
    createTaskData,
    success,
    taskData,
    updateTask,
    taskHistory,
    createAssiLoading,
    updateStatusLoading,
  } = useSelector((state) => state.task);

  const [search, setsearch] = useState("");

  const [taskStatus, setTaskStatus] = useState(false);

  const dispatch = useDispatch();

  const [taskId, settaskId] = useState("");
  const [updatedStatus, setupdatedStatus] = useState("");

  useEffect(() => {
    if (updateTask) {
      dispatch(getTask());
    }
  }, [updateTask]);

  const current = new Date();

  const [startDate, setStartDate] = useState(current);
  const [endDate, setEndDate] = useState(current);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    dispatch(
      getTaskHistory(
        moment(start).format("MM-DD-YYYY"),
        moment(end).format("MM-DD-YYYY")
      )
    );
  };

  const [calendar, setCalendar] = useState(false);

  const navigate = useNavigate();

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button id="history" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const [taskImage, setTaskImage] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
    console.log(taskImage);
  };

  const [deleteDialog, setdeleteDialog] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteTaskId, setDeleteTaskId] = useState("");

  const DeleteTask = () => {
    let token = localStorage.getItem("token");
    setDeleteConfirmation(true);
    axios
      .delete(`${BASE_URL}/api/task/deleteTask?taskId=${deleteTaskId}`, {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        dispatch(
          getTaskHistory(
            moment(current).subtract(1, "M").format("MM-DD-YYYY"),
            moment(current).add(1, "M").format("MM-DD-YYYY")
          )
        );
        setDeleteConfirmation(false);
      })
      .catch((e) => {
        console.log(`delete error ${e}`);
        setDeleteConfirmation(false);
      });
  };

  const [updateTaskDrawer, setUpdateTaskDrawer] = useState(false);
  const [task, settask] = useState("");
  const [taskType, settaskType] = useState("");
  const [taskName, settaskName] = useState("");
  const [date, setdate] = useState("");
  const [time, settime] = useState("");
  const [timeAllowed, settimeAllowed] = useState("");
  const [desc, setdesc] = useState("");
  const [imag, setimag] = useState("");
  const [updatetaskId, setupdatetaskId] = useState("");
  const [updateMonth, setupdateMonth] = useState("");
  const [subStatus, setsubStatus] = useState([]);
  const [viewImage, setViewImage] = useState(false);
  const [recurringState, setrecurringState] = useState(false);
  const [recurrType, setrecurrType] = useState("");
  const [recurringDate, setrecurringDate] = useState("");
  const [weeklyDates, setweeklyDates] = useState([]);
  const [baseId, setbaseId] = useState("");

  const [taskDialog, setTaskDialog] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const Update = (
    id,
    name,
    type,
    task,
    time,
    timeAllow,
    desc,
    date,
    month,
    image,
    sub_status,
    index
  ) => {
    let token = localStorage.getItem("token");

    let status = [];
    status.push(...sub_status);
    status.splice(index, 1, "completed");

    setUpdateLoading(true);
    axios
      .put(
        `${BASE_URL}/api/task/updateTask`,
        {
          id: id,
          name: name,
          type: type,
          task: task,
          time: time,
          timeAllowed: timeAllow,
          description: desc,
          date: date,
          month: month,
          image: image,
          sub_status: status,
          isRecurring: recurringState,
          recurringType: recurrType,
          recurringDate: recurringDate,
          selectedDays: weeklyDates,
          base_id: baseId,
        },
        {
          headers: {
            Authorization: `token ${JSON.parse(token)}`,
          },
        }
      )
      .then((res) => {
        setTaskDialog(true);
        setUpdateLoading(false);
        if (status.every((e) => e === "completed")) {
          setTaskStatus(false);
          dispatch(updateTaskStatus(id, "completed"));
        }
      })
      .catch((e) => {
        console.log(`update task error ${e}`);
        setUpdateLoading(false);
        dispatch(
          getTaskHistory(
            moment(current).subtract(1, "M").format("MM-DD-YYYY"),
            moment(current).add(1, "M").format("MM-DD-YYYY")
          )
        );
      });
  };

  return (
    <>
      <div className="histry">
        {isViewerOpen && (
          <ImageViewer
            src={[taskImage]}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
        <GreenDialogModal
          value={taskDialog}
          setValue={() => setTaskDialog(false)}
          title="Confirmation"
          subtitle="Task Updated Successfully"
          onClick={() => {
            setTaskDialog(false);
            dispatch(clearSuccess());
            dispatch(getTask());
            dispatch(getFilteredTask());
            dispatch(
              getTaskHistory(
                moment(current).subtract(1, "M").format("MM-DD-YYYY"),
                moment(current).add(1, "M").format("MM-DD-YYYY")
              )
            );
          }}
        />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={deleteConfirmation}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={updateStatusLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={updateLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ConfirmationDialog
          value={deleteDialog}
          setValue={() => setdeleteDialog(false)}
          title="Confirmation"
          subtitle="Delete this task?"
          onClickYes={() => {
            setdeleteDialog(false);
            DeleteTask();
          }}
          onClickNo={() => setdeleteDialog(false)}
          deleteTask={true}
        />

        <ConfirmationDialog
          value={taskStatus}
          setValue={() => setTaskStatus(false)}
          title="Confirmation"
          subtitle="Set task as completed?"
          onClickYes={() => {
            setTaskStatus(false);
            dispatch(updateTaskStatus(taskId, updatedStatus));
            // Update(taskId, updatedStatus);
            dispatch(
              getTaskHistory(
                moment(current).subtract(1, "M").format("MM-DD-YYYY"),
                moment(current).add(1, "M").format("MM-DD-YYYY")
              )
            );
          }}
          onClickNo={() => setTaskStatus(false)}
          deleteTask={true}
        />

        {/* <Dialog open={taskStatus} onClose={() => setTaskStatus(false)}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>Set task as completed?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setTaskStatus(false);
                dispatch(updateTaskStatus(taskId, updatedStatus));
                dispatch(
                  getTaskHistory(
                    moment(current).subtract(1, "M").format("MM-DD-YYYY"),
                    moment(current).add(1, "M").format("MM-DD-YYYY")
                  )
                );
              }}
            >
              Yes
            </Button>
            <Button onClick={() => setTaskStatus(false)}>No</Button>
          </DialogActions>
        </Dialog> */}
        {/* <Dialog open={deleteDialog} onClose={() => setdeleteDialog(false)}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>Delete this task?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setdeleteDialog(false);
                DeleteTask();
              }}
            >
              Yes
            </Button>
            <Button onClick={() => setdeleteDialog(false)}>No</Button>
          </DialogActions>
        </Dialog> */}

        <div className="microcards">
          <p
            id="p4"
            style={{
              cursor: "pointer",
              backgroundColor: filters === "4" ? "#a6a6a6" : "#e5e5e5",
            }}
            onClickCapture={() => setFilters("4")}
          >
            Assigned
          </p>
          <p
            id="p1"
            style={{
              cursor: "pointer",
              backgroundColor: filters === "1" ? "#ffe0a8" : "#fcf2e0",
            }}
            onClickCapture={() => setFilters("1")}
          >
            Working
          </p>
          <p
            id="p2"
            style={{
              cursor: "pointer",
              backgroundColor: filters === "2" ? "#f8a894" : "#FDE5DF",
            }}
            onClickCapture={() => setFilters("2")}
          >
            Stopped
          </p>
          <p
            id="p3"
            style={{
              cursor: "pointer",
              backgroundColor: filters === "3" ? "#afffa8" : "#DDF5DB",
            }}
            onClickCapture={() => setFilters("3")}
          >
            Completed
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {calendar && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 10,
              }}
            >
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                customInput={<ExampleCustomInput />}
              />
            </Box>
          )}
          {id && (
            <button
              id="history"
              onClick={() => {
                {
                  moment(getParent.expiryDate).format("YYYY-MM-DD") >=
                  moment(current).format("YYYY-MM-DD")
                    ? setOpenAddNewTask(true)
                    : setExpiry(true);
                }
              }}
              style={{ marginRight: "5px" }}
            >
              + Add Task
            </button>
          )}
          <button id="history" onClick={() => setCalendar(!calendar)}>
            <i className="material-symbols-outlined">history</i> History
          </button>
          <RedDialogModal
            value={expiry}
            setValue={() => setExpiry(false)}
            title="Pacakge"
            subtitle="Your package has expired. Please renew."
            onClick={() => {
              setExpiry(false);
              navigate("/packages");
            }}
            width="20%"
            buttonText="Buy a package"
          />

          <Drawer
            anchor={"right"}
            open={openAddNewTask}
            onClose={() => setOpenAddNewTask(false)}
          >
            <NewTas
              setOpenAddNewTask={setOpenAddNewTask}
              assignedToId={id}
              setValue={setValue}
            />
          </Drawer>
          <Drawer
            anchor={"right"}
            open={updateTaskDrawer}
            onClose={() => setUpdateTaskDrawer(false)}
          >
            <UpdateTask
              setUpdateTask={setUpdateTaskDrawer}
              updateTaskType={task}
              updateTask={taskType}
              updateTaskName={taskName}
              updateDate={date}
              updateTime={time}
              updateTimeAllowed={timeAllowed}
              updateDesc={desc}
              updateImage={imag}
              updateTaskId={updatetaskId}
              updateMonth={updateMonth}
              subStatus={subStatus}
              updateRecState={recurringState}
              updateRecType={recurrType}
              updateRecDate={recurringDate}
              updateWeeDat={weeklyDates}
              baseId={baseId}
            />
          </Drawer>
        </div>
      </div>
      <div className="searcher2">
        <span className="material-symbols-outlined">search</span>
        <input
          type="search"
          placeholder="Search Task List, Schedule, Grocery List..."
          onChange={(e) => setsearch(e.target.value)}
        />
      </div>

      {taskHistory
        .filter((val) => {
          return val.type.toLowerCase().includes(search.toLowerCase());
        })
        ?.map((ctr, ind) => {
          return (
            ctr.assignedToId === id &&
            ctr.type === type && (
              <>
                {filters === "" && (
                  <div className="grid5">
                    <div className="minicards">
                      <AccordionComponent
                        ctr={ctr}
                        Update={Update}
                        setUpdateTaskDrawer={setUpdateTaskDrawer}
                        settask={settask}
                        settaskType={settaskType}
                        settaskName={settaskName}
                        settime={settime}
                        settimeAllowed={settimeAllowed}
                        setdate={setdate}
                        setdesc={setdesc}
                        setimag={setimag}
                        setupdatetaskId={setupdatetaskId}
                        setupdateMonth={setupdateMonth}
                        setdeleteDialog={setdeleteDialog}
                        setDeleteTaskId={setDeleteTaskId}
                        viewImage={viewImage}
                        setViewImage={setViewImage}
                        setIsViewerOpen={setIsViewerOpen}
                        setTaskImage={setTaskImage}
                        setTaskStatus={setTaskStatus}
                        settaskId={settaskId}
                        setupdatedStatus={setupdatedStatus}
                        detail={false}
                        setsubStatus={setsubStatus}
                        setrecurringState={setrecurringState}
                        setrecurrType={setrecurrType}
                        setrecurringDate={setrecurringDate}
                        setweeklyDates={setweeklyDates}
                        setbaseId={setbaseId}
                      />
                    </div>
                  </div>
                )}
                {filters === "1" && ctr.status === "pending" && (
                  <div className="grid5">
                    <div className="minicards">
                      <AccordionComponent
                        ctr={ctr}
                        Update={Update}
                        setUpdateTaskDrawer={setUpdateTaskDrawer}
                        settask={settask}
                        settaskType={settaskType}
                        settaskName={settaskName}
                        settime={settime}
                        settimeAllowed={settimeAllowed}
                        setdate={setdate}
                        setdesc={setdesc}
                        setimag={setimag}
                        setupdatetaskId={setupdatetaskId}
                        setupdateMonth={setupdateMonth}
                        setdeleteDialog={setdeleteDialog}
                        setDeleteTaskId={setDeleteTaskId}
                        viewImage={viewImage}
                        setViewImage={setViewImage}
                        setIsViewerOpen={setIsViewerOpen}
                        setTaskImage={setTaskImage}
                        setTaskStatus={setTaskStatus}
                        settaskId={settaskId}
                        setupdatedStatus={setupdatedStatus}
                        detail={false}
                        setsubStatus={setsubStatus}
                        setrecurringState={setrecurringState}
                        setrecurrType={setrecurrType}
                        setrecurringDate={setrecurringDate}
                        setweeklyDates={setweeklyDates}
                        setbaseId={setbaseId}
                      />
                    </div>
                  </div>
                )}
                {filters === "2" && ctr.status === "stopped" && (
                  <div className="grid5">
                    <div className="minicards">
                      <AccordionComponent
                        ctr={ctr}
                        Update={Update}
                        setUpdateTaskDrawer={setUpdateTaskDrawer}
                        settask={settask}
                        settaskType={settaskType}
                        settaskName={settaskName}
                        settime={settime}
                        settimeAllowed={settimeAllowed}
                        setdate={setdate}
                        setdesc={setdesc}
                        setimag={setimag}
                        setupdatetaskId={setupdatetaskId}
                        setupdateMonth={setupdateMonth}
                        setdeleteDialog={setdeleteDialog}
                        setDeleteTaskId={setDeleteTaskId}
                        viewImage={viewImage}
                        setViewImage={setViewImage}
                        setIsViewerOpen={setIsViewerOpen}
                        setTaskImage={setTaskImage}
                        setTaskStatus={setTaskStatus}
                        settaskId={settaskId}
                        setupdatedStatus={setupdatedStatus}
                        detail={false}
                        setsubStatus={setsubStatus}
                        setrecurringState={setrecurringState}
                        setrecurrType={setrecurrType}
                        setrecurringDate={setrecurringDate}
                        setweeklyDates={setweeklyDates}
                        setbaseId={setbaseId}
                      />
                    </div>
                  </div>
                )}
                {filters === "3" && ctr.status === "completed" && (
                  <div className="grid5">
                    <div className="minicards">
                      <AccordionComponent
                        ctr={ctr}
                        Update={Update}
                        setUpdateTaskDrawer={setUpdateTaskDrawer}
                        settask={settask}
                        settaskType={settaskType}
                        settaskName={settaskName}
                        settime={settime}
                        settimeAllowed={settimeAllowed}
                        setdate={setdate}
                        setdesc={setdesc}
                        setimag={setimag}
                        setupdatetaskId={setupdatetaskId}
                        setupdateMonth={setupdateMonth}
                        setdeleteDialog={setdeleteDialog}
                        setDeleteTaskId={setDeleteTaskId}
                        viewImage={viewImage}
                        setViewImage={setViewImage}
                        setIsViewerOpen={setIsViewerOpen}
                        setTaskImage={setTaskImage}
                        setTaskStatus={setTaskStatus}
                        settaskId={settaskId}
                        setupdatedStatus={setupdatedStatus}
                        detail={false}
                        setsubStatus={setsubStatus}
                        setrecurringState={setrecurringState}
                        setrecurrType={setrecurrType}
                        setrecurringDate={setrecurringDate}
                        setweeklyDates={setweeklyDates}
                        setbaseId={setbaseId}
                      />
                    </div>
                  </div>
                )}
                {filters === "4" && ctr.status === "assigned" && (
                  <div className="grid5">
                    <div className="minicards">
                      <AccordionComponent
                        ctr={ctr}
                        Update={Update}
                        setUpdateTaskDrawer={setUpdateTaskDrawer}
                        settask={settask}
                        settaskType={settaskType}
                        settaskName={settaskName}
                        settime={settime}
                        settimeAllowed={settimeAllowed}
                        setdate={setdate}
                        setdesc={setdesc}
                        setimag={setimag}
                        setupdatetaskId={setupdatetaskId}
                        setupdateMonth={setupdateMonth}
                        setdeleteDialog={setdeleteDialog}
                        setDeleteTaskId={setDeleteTaskId}
                        viewImage={viewImage}
                        setViewImage={setViewImage}
                        setIsViewerOpen={setIsViewerOpen}
                        setTaskImage={setTaskImage}
                        setTaskStatus={setTaskStatus}
                        settaskId={settaskId}
                        setupdatedStatus={setupdatedStatus}
                        detail={false}
                        setsubStatus={setsubStatus}
                        setrecurringState={setrecurringState}
                        setrecurrType={setrecurrType}
                        setrecurringDate={setrecurringDate}
                        setweeklyDates={setweeklyDates}
                        setbaseId={setbaseId}
                      />
                    </div>
                  </div>
                )}
              </>
            )
          );
        })}
    </>
  );
};

const TaskParent = () => {
  const { getAssigneeData, getLoading, getError, getByIdLoading, getByIdData } =
    useSelector((state) => state.assignee);
  const { taskLoading, createTaskData, success, taskData, createAssiLoading } =
    useSelector((state) => state.task);

  const pendingCount = taskData?.filter((i) => i.status === "pending").length;
  const stoppedCount = taskData?.filter((i) => i.status === "stopped").length;
  const assignedCount = taskData?.filter((i) => i.status === "assigned").length;
  const completedCount = taskData?.filter(
    (i) => i.status === "completed"
  ).length;

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [id, setid] = useState(getAssigneeData[0]?.id);

  function toggleBar() {
    document.getElementById("menuBar").classList.toggle("open-bar");
    document.getElementById("mainBar").classList.toggle("open-bar2");
  }

  return (
    <section>
      <Header />
      <div class="sidebar" id="menuBar" style={{ zIndex: "999" }}>
        <SideDrawer />
      </div>

      <div className="main" id="mainBar">
        <Banner />
        <HeaderComp title={"Task"} toggle={toggleBar} />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={createAssiLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={taskLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <main>
          <div className="cards" style={{ marginBottom: "20px" }}>
            <div className="card-one" id="four">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {assignedCount}{" "}
                  <span className="material-symbols-outlined">
                    trending_down
                  </span>
                </h2>
                <h6>TASKS ASSIGNED</h6>
              </div>
            </div>
            <div className="card-one" id="one">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {stoppedCount}{" "}
                  <span className="material-symbols-outlined">
                    trending_down
                  </span>
                </h2>

                <h6>TASKS INCOMPLETE</h6>
              </div>
            </div>
            <div className="card-one" id="two">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {pendingCount}{" "}
                  <span className="material-symbols-outlined">trending_up</span>
                </h2>

                <h6>TASKS PENDING</h6>
              </div>
            </div>
            <div className="card-one" id="three">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {completedCount}{" "}
                  <span className="material-symbols-outlined">trending_up</span>
                </h2>

                <h6>TASKS DONE</h6>
              </div>
            </div>
          </div>

          <div className="grid3">
            <div className="col1">
              <div className="allchat">
                <p style={{ color: "#000", fontSize: "20.8px" }}>
                  Assignee ({getAssigneeData.length})
                </p>
              </div>
              <hr />
              {getAssigneeData.map((c, index) => {
                return (
                  <>
                    <div
                      className="chat"
                      id="cht1"
                      style={{
                        backgroundColor: c.id === id && "#d8d8d8",
                        paddingLeft: 5,
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        setid(c.id);
                      }}
                    >
                      {c.image ? (
                        <img src={c?.image} width="16%" height="16%" alt="" />
                      ) : (
                        <img
                          src={require("../../images/user.png")}
                          width="16%"
                          height="16%"
                          alt=""
                        />
                      )}
                      <div className="cht">
                        <h6
                          style={{
                            backgroundColor:
                              c.color.toLocaleLowerCase() === "white"
                                ? "#FBFCF8"
                                : c.color.toLocaleLowerCase() === "yellow"
                                ? "#e5e500"
                                : c.color,
                            color:
                              c.color.toLocaleLowerCase() === "white"
                                ? "black"
                                : "white",
                            border:
                              c.color.toLocaleLowerCase() === "white"
                                ? "1px solid black"
                                : `0px solid ${c.color}`,
                          }}
                        >
                          ASSIGNEE #{index + 1}
                        </h6>
                        <h4 style={{ color: "#000" }}>{c.name}</h4>
                        <p style={{ color: "#262626" }}>{c.age} Years Old</p>
                      </div>
                    </div>
                    <hr />
                  </>
                );
              })}
            </div>
            <div className="col2">
              <div className="page5">
                <TabContext value={value}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    centered
                    TabIndicatorProps={{ style: { background: "black" } }}
                    style={{
                      width: "100% ",
                      borderTopWidth: 0,
                      borderLeftWidth: 0,
                      borderWidthRight: 0,
                    }}
                  >
                    <Tab
                      value="1"
                      label="Task List"
                      style={{
                        color: "#000",
                        fontSize: "15.72px",
                        fontWeight: "600",
                        fontFamily: "wrchivo",
                      }}
                    />
                    <Tab
                      value="2"
                      label="Schedule"
                      style={{
                        color: "#000",
                        fontSize: "15.72px",
                        fontWeight: "600",
                        fontFamily: "wrchivo",
                      }}
                    />
                    <Tab
                      value="3"
                      label=" Grocery List"
                      style={{
                        color: "#000",
                        fontSize: "15.72px",
                        fontWeight: "600",
                        fontFamily: "wrchivo",
                      }}
                    />
                  </Tabs>
                  <TabPanel value="1" style={{ padding: 0 }}>
                    <SecondComp id={id} type="Task List" setValue={setValue} />
                  </TabPanel>
                  <TabPanel value="2" style={{ padding: 0 }}>
                    <SecondComp id={id} type="Schedule" setValue={setValue} />
                  </TabPanel>
                  <TabPanel value="3" style={{ padding: 0 }}>
                    <SecondComp
                      id={id}
                      type="Grocery List"
                      setValue={setValue}
                    />
                  </TabPanel>
                </TabContext>
              </div>
            </div>
          </div>
        </main>
      </div>
    </section>
  );
};

export default TaskParent;
