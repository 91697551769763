export const taskTypeDropDown = [
  { id: "1", type: "Task List" },
  { id: "2", type: "Schedule" },
  { id: "3", type: "Grocery List" },
];

export const tasks = [
  {
    id: "1",
    type: "Task List",
    task: "Fix bed",
    icon: require("../images/Icons/fix-bed.png"),
  },
  {
    id: "2",
    type: "Task List",
    task: "Fold blankets",
    icon: require("../images/Icons/fold-blanket.png"),
  },
  {
    id: "3",
    type: "Task List",
    task: "Laundry",
    icon: require("../images/Icons/laundry.png"),
  },
  {
    id: "4",
    type: "Task List",
    task: "Sweep",
    icon: require("../images/Icons/sweep.png"),
  },
  {
    id: "5",
    type: "Task List",
    task: "Mop",
    icon: require("../images/Icons/mop.png"),
  },
  {
    id: "6",
    type: "Task List",
    task: "Clean Playroom",
    icon: require("../images/Icons/playroom.png"),
  },
  {
    id: "7",
    type: "Task List",
    task: "Feed dog",
    icon: require("../images/Icons/dog-food.png"),
  },
  {
    id: "8",
    type: "Task List",
    task: "Cut grass",
    icon: require("../images/Icons/lawn.png"),
  },
  {
    id: "9",
    type: "Task List",
    task: "Wash dishes",
    icon: require("../images/Icons/dishes.png"),
  },
  {
    id: "10",
    type: "Task List",
    task: "Put up groceries",
    icon: require("../images/Icons/groceries.png"),
  },
  {
    id: "11",
    type: "Task List",
    task: "Shower",
    icon: require("../images/Icons/shower.png"),
  },
  {
    id: "12",
    type: "Task List",
    task: "Brush teeth",
    icon: require("../images/Icons/brush-teeth.png"),
  },
  {
    id: "13",
    type: "Task List",
    task: "Brush hair",
    icon: require("../images/Icons/comb.png"),
  },
  {
    id: "14",
    type: "Task List",
    task: "Change clothes",
    icon: require("../images/Icons/clothing.png"),
  },
  {
    id: "15",
    type: "Task List",
    task: "Clothes in washer",
    icon: require("../images/Icons/laundry-machine.png"),
  },
  {
    id: "16",
    type: "Task List",
    task: "Clothes in dryer",
    icon: require("../images/Icons/dryer.png"),
  },
  {
    id: "17",
    type: "Task List",
    task: "Clean table",
    icon: require("../images/Icons/table.png"),
  },
  {
    id: "18",
    type: "Task List",
    task: "Put away shoes",
    icon: require("../images/Icons/furniture.png"),
  },

  {
    id: "19",
    type: "Task List",
    task: "Put away backpacks",
    icon: require("../images/Icons/backpack.png"),
  },

  {
    id: "20",
    type: "Task List",
    task: "Homework",
    icon: require("../images/Icons/homework.png"),
  },

  {
    id: "20",
    type: "Task List",
    task: "Clean mirror",
    icon: require("../images/Icons/mirror.png"),
  },

  {
    id: "21",
    type: "Task List",
    task: "Other",
    icon: require("../images/Icons/other.png"),
  },

  {
    id: "22",
    type: "Schedule",
    task: "Grocery Store",
    icon: require("../images/Icons/groceries.png"),
  },
  {
    id: "23",
    type: "Schedule",
    task: "Post Office",
    icon: require("../images/Icons/post-office.png"),
  },
  {
    id: "24",
    type: "Schedule",
    task: "Lunch",
    icon: require("../images/Icons/lunch.png"),
  },
  {
    id: "25",
    type: "Schedule",
    task: "Park",
    icon: require("../images/Icons/park.png"),
  },
  {
    id: "26",
    type: "Schedule",
    task: "Cleaners",
    icon: require("../images/Icons/mattress.png"),
  },
  {
    id: "27",
    type: "Schedule",
    task: "Movies",
    icon: require("../images/Icons/movies.png"),
  },
  {
    id: "28",
    type: "Schedule",
    task: "Other",
    icon: require("../images/Icons/other.png"),
  },
  {
    id: "29",
    type: "Grocery List",
    task: "Tomatoes",
    icon: require("../images/Icons/tomato.png"),
  },
  {
    id: "30",
    type: "Grocery List",
    task: "Bread",
    icon: require("../images/Icons/bread.png"),
  },
  {
    id: "31",
    type: "Grocery List",
    task: "Ham",
    icon: require("../images/Icons/ham.png"),
  },
  {
    id: "32",
    type: "Grocery List",
    task: "Toilet paper",
    icon: require("../images/Icons/toilet-paper.png"),
  },
  {
    id: "33",
    type: "Grocery List",
    task: "Dog food",
    icon: require("../images/Icons/dog-food.png"),
  },
  {
    id: "34",
    type: "Grocery List",
    task: "Other",
    icon: require("../images/Icons/other.png"),
  },
];

export const slides = [
  {
    id: 1,
    image: require("../images/hero1.png"),
    name: "William A. Jordan Software Engineer",
    text: "Parental love is characterized by warmth, affection, care, comfort, concern, nurture, support, acceptance or simply love that a child can feel from their parents.",
  },

  // {
  //   id: 2,
  //   image: require("../images/user.png"),
  //   name: "Jordan Software Engineer",
  //   text: "Parental love is characterized by warmth, affection, care, comfort, concern, nurture, support, acceptance or simply love that a child can feel from their parents.",
  // },
];

export const packages = [
  {
    id: 1,
    assignee: 1,
    price: 7.99,
    currency: "USD",
    image: require("../images/single.png"),
    color: "olive",
  },
  {
    id: 2,
    assignee: 2,
    price: 11.99,
    currency: "USD",
    image: require("../images/two.png"),
    color: "purple",
  },
  {
    id: 3,
    assignee: 3,
    price: 15.99,
    currency: "USD",
    image: require("../images/three.png"),
    color: "orange",
  },
  {
    id: 4,
    assignee: 4,
    price: 19.99,
    currency: "USD",
    image: require("../images/four.png"),
    color: "green",
  },
];

export const week = [
  { id: 1, title: "Monday" },
  { id: 2, title: "Tuesday" },
  { id: 3, title: "Wednesday" },
  { id: 4, title: "Thursday" },
  { id: 5, title: "Friday" },
  { id: 6, title: "Saturday" },
  { id: 7, title: "Sunday" },
];

export const repeatType = [
  { id: 1, title: "None" },
  { id: 2, title: "Daily" },
  { id: 3, title: "Weekly" },
  { id: 4, title: "Monthly" },
];
