import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { childLogin } from "../../redux/dispatcher/login";

const ChildSignIn = () => {
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { loginloading, childLoginData, isAuthenticated, check, childError } =
    useSelector((state) => state.log);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/task");
    }
  }, [isAuthenticated, loginloading]);

  const onSubmit = (e) => {
    dispatch(childLogin(username, password));
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [passwordToggle, setPasswordToggle] = useState(false);

  return (
    <section>
      <Header />
      <body>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loginloading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="main">
          <div className="grid">
            <div className="child1">
              <img
                src={require("../../images/logo.png")}
                width="150px"
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              />

              <div>
                {/* <img
                  id="unsplash"
                  src={require("../..//images/unsplash2.png")}
                  width="50%"
                  alt=""
                /> */}
                <h1>
                  Ladder of
                  <br /> Remote Access
                </h1>
                <p>
                  A sophisticated, yet easy way to communicate within
                  <br />a family unit where parents get the support they need
                  <br />
                  to convey messages to assignee in the household for
                  <br />
                  task purpose, etc.
                </p>
                <Link to={"/sign-in"}>
                  <button id="signup">Sign In as an Assignor</button>
                </Link>
              </div>
            </div>
            <div className="child2">
              <h1>Sign In</h1>
              <p>
                Hey, enter your details to get
                <br />
                sign in to your account
              </p>

              <p
                style={{
                  color: "red",
                  alignSelf: "flex-start",
                }}
              >
                {`${
                  childError?.data.message === undefined
                    ? ""
                    : childError?.data.message
                }`}
              </p>

              {errors.username && (
                <p
                  style={{
                    color: "red",
                    alignSelf: "flex-start",
                    paddingBottom: 0,
                  }}
                >
                  *Username is required
                </p>
              )}
              <input
                id="email"
                name="username"
                {...register("username", { required: true })}
                aria-invalid={errors.username ? "true" : "false"}
                placeholder="Enter username"
                required
                onChange={(e) => setusername(e.target.value)}
                style={{ marginTop: errors.username ? 5 : 16 }}
              />

              {errors.password && (
                <p
                  style={{
                    color: "red",
                    alignSelf: "flex-start",
                    paddingBottom: 0,
                  }}
                >
                  *Password is required
                </p>
              )}
              <div
                className="psswrd"
                style={{ marginTop: errors.password ? 0 : 16 }}
              >
                <input
                  type={!passwordToggle ? "password" : "text"}
                  id="password"
                  name="password"
                  {...register("password", { required: true })}
                  aria-invalid={errors.password ? "true" : "false"}
                  placeholder="Enter password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span onClick={() => setPasswordToggle(!passwordToggle)}>
                  {passwordToggle ? (
                    <i className="material-symbols-outlined" id="eye">
                      visibility
                    </i>
                  ) : (
                    <i className="material-symbols-outlined" id="eye">
                      visibility_off
                    </i>
                  )}
                </span>
              </div>

              {/* <button id="trouble">Having trouble in sign in?</button> */}

              {/* <Link to={"/dashboard"} style={{ width: "100%" }}> */}
              <button id="signin" onClick={handleSubmit(onSubmit)}>
                Sign In
              </button>
              {/* </Link> */}

              <img id="img5" src="./images/Vector.png" width="100px" alt="" />
              <img id="img2" src="./images/Vector2.png" width="100px" alt="" />
            </div>
          </div>
        </div>
      </body>
    </section>
  );
};

export default ChildSignIn;
