import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Select,
  MenuItem,
  useMediaQuery,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import {
  clearSuccUpdateAss,
  getAssignee,
  getAssigneeById,
  updateAssignee,
} from "../redux/dispatcher/assignee";
import axios from "axios";
import { BASE_URL } from "../constants/config";
import {
  getTask,
  getTaskHistory,
  updateTaskStatus,
} from "../redux/dispatcher/tasks";
import {
  ConfirmationDialog,
  GreenDialogModal,
  RedDialogModal,
} from "./DialogModal";
import ImageViewer from "react-simple-image-viewer";
import { tasks } from "../assets/data";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateTask from "./UpdateTask";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ChangePasswordAssignee from "./ChangePasswordAssignee";
import PasswordIcon from "@mui/icons-material/Password";
import ImageIcon from "@mui/icons-material/Image";
import HideImageIcon from "@mui/icons-material/HideImage";
import DisplayTasks from "./DisplayTasks";

const SecondComp = ({ child }) => {
  const [filters, setFilters] = useState("");
  const { taskLoading, createTaskData, success, taskData, updateTask } =
    useSelector((state) => state.task);

  const [taskStatus, setTaskStatus] = useState(false);

  const dispatch = useDispatch();

  const [taskId, settaskId] = useState("");
  const [updatedStatus, setupdatedStatus] = useState("");

  useEffect(() => {
    if (updateTask) {
      dispatch(getTask());
    }
  }, [updateTask]);

  const [taskImage, setTaskImage] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const [updateTaskDrawer, setUpdateTaskDrawer] = useState(false);
  const [task, settask] = useState("");
  const [taskType, settaskType] = useState("");
  const [taskName, settaskName] = useState("");
  const [date, setdate] = useState("");
  const [time, settime] = useState("");
  const [timeAllowed, settimeAllowed] = useState("");
  const [desc, setdesc] = useState("");
  const [imag, setimag] = useState("");
  const [updatetaskId, setupdatetaskId] = useState("");
  const [updateMonth, setupdateMonth] = useState("");
  const [subStatus, setsubStatus] = useState([]);
  const [deleteDialog, setdeleteDialog] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteTaskId, setDeleteTaskId] = useState("");

  const current = new Date();

  const DeleteTask = () => {
    let token = localStorage.getItem("token");
    setDeleteConfirmation(true);
    axios
      .delete(`${BASE_URL}/api/task/deleteTask?taskId=${deleteTaskId}`, {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        dispatch(getTask());
        setDeleteConfirmation(false);
        dispatch(
          getTaskHistory(
            moment(current).subtract(1, "M").format("MM-DD-YYYY"),
            moment(current).add(1, "M").format("MM-DD-YYYY")
          )
        );
      })
      .catch((e) => {
        console.log(`delete error ${e}`);
        setDeleteConfirmation(false);
      });
  };

  const [viewImage, setViewImage] = useState(false);

  return (
    <>
      <div className="microcards">
        <p
          id="p4"
          style={{
            cursor: "pointer",
            backgroundColor: filters === "4" ? "#a6a6a6" : "#e5e5e5",
          }}
          onClickCapture={() => setFilters("4")}
        >
          Assigned
        </p>
        <p
          id="p1"
          style={{
            cursor: "pointer",
            backgroundColor: filters === "1" ? "#ffe0a8" : "#fcf2e0",
          }}
          onClickCapture={() => setFilters("1")}
        >
          Working
        </p>
        <p
          id="p2"
          style={{
            cursor: "pointer",
            backgroundColor: filters === "2" ? "#f8a894" : "#FDE5DF",
          }}
          onClickCapture={() => setFilters("2")}
        >
          Stopped
        </p>
        <p
          id="p3"
          style={{
            cursor: "pointer",
            backgroundColor: filters === "3" ? "#afffa8" : "#DDF5DB",
          }}
          onClickCapture={() => setFilters("3")}
        >
          Completed
        </p>
      </div>
      {/* <Dialog open={deleteDialog} onClose={() => setdeleteDialog(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Delete this task?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setdeleteDialog(false);
              DeleteTask();
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setdeleteDialog(false)}>No</Button>
        </DialogActions>
      </Dialog> */}
      <ConfirmationDialog
        value={deleteDialog}
        setValue={() => setdeleteDialog(false)}
        title="Confirmation"
        subtitle="Delete this task?"
        onClickYes={() => {
          setdeleteDialog(false);
          DeleteTask();
        }}
        onClickNo={() => setdeleteDialog(false)}
        deleteTask={true}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={deleteConfirmation}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isViewerOpen && (
        <ImageViewer
          src={[taskImage]}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
      <Drawer
        anchor={"right"}
        open={updateTaskDrawer}
        onClose={() => setUpdateTaskDrawer(false)}
      >
        <UpdateTask
          setUpdateTask={setUpdateTaskDrawer}
          updateTaskType={task}
          updateTask={taskType}
          updateTaskName={taskName}
          updateDate={date}
          updateTime={time}
          updateTimeAllowed={timeAllowed}
          updateDesc={desc}
          updateImage={imag}
          updateTaskId={updatetaskId}
          updateMonth={updateMonth}
          setsubStatus={setsubStatus}
          subStatus={subStatus}
        />
      </Drawer>

      {/* <ConfirmationDialog
        title="Confirmation"
        subtitle="Set task as completed?"
        value={taskStatus}
        setValue={() => setTaskStatus(false)}
        onClickYes={() => {
          setTaskStatus(false);
          dispatch(updateTaskStatus(taskId, updatedStatus));
        }}
        onClickNo={() => setTaskStatus(false)}
      /> */}
      {/* <Dialog open={taskStatus} onClose={() => setTaskStatus(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Set task as completed?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setTaskStatus(false);
              dispatch(updateTaskStatus(taskId, updatedStatus));
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setTaskStatus(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog> */}
      <ConfirmationDialog
        value={taskStatus}
        setValue={() => setTaskStatus(false)}
        title="Confirmation"
        subtitle="Set task as completed?"
        onClickYes={() => {
          setTaskStatus(false);
          dispatch(updateTaskStatus(taskId, updatedStatus));
        }}
        onClickNo={() => setTaskStatus(false)}
        deleteTask={true}
      />
      <div className="minicards">
        {taskData?.map((ctr, ind) => {
          return (
            ctr?.assignedToId === child?.id && (
              <>
                {filters === "" && (
                  <Accordion
                    className="player"
                    style={{
                      backgroundColor:
                        ctr.status === "pending"
                          ? "#ec9a11"
                          : ctr.status === "stopped"
                          ? "#ef481e"
                          : ctr.status === "completed"
                          ? "#0fb600"
                          : ctr.status === "assigned" && "#808080",
                    }}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      style={{ minHeight: "5px", height: "10px" }}
                    ></AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      {ctr?.task && (
                        <>
                          {ctr?.task?.map((title, index) => (
                            <Accordion
                              id={
                                ctr.status === "pending"
                                  ? "c3"
                                  : ctr.status === "stopped"
                                  ? "c2"
                                  : ctr.status === "completed"
                                  ? "c1"
                                  : ctr.status === "assigned" && "c4"
                              }
                            >
                              <AccordionSummary>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {tasks?.map((i) => {
                                    return (
                                      i.task === title &&
                                      i.type === ctr.type && (
                                        <img
                                          src={i.icon}
                                          height={"22px"}
                                          width={"22px"}
                                          alt=""
                                        />
                                      )
                                    );
                                  })}{" "}
                                  <h4 style={{ marginLeft: "10px" }}>
                                    {title === "Other" ? ctr.name : title}
                                  </h4>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                {ctr?.sub_status && (
                                  <div
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <p>Status: </p>
                                      <span
                                        className="time"
                                        style={{ color: "#fff" }}
                                      >
                                        {ctr?.sub_status[index]}
                                      </span>
                                    </div>
                                    <span
                                      className="material-symbols-outlined"
                                      onClick={() => {}}
                                      onMouseOver={({ target }) =>
                                        (target.style.color =
                                          ctr.status === "pending"
                                            ? "#8d5c0a"
                                            : ctr.status === "stopped"
                                            ? "#a73215"
                                            : ctr.status === "completed"
                                            ? "#0a7f00"
                                            : "#a6a6a6")
                                      }
                                      onMouseOut={({ target }) =>
                                        (target.style.color = "white")
                                      }
                                      style={{
                                        marginRight: "8px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      task_alt
                                    </span>
                                  </div>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </>
                      )}
                      <p>{ctr.description}</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="span"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Link to="/chat" style={{ color: "white" }}>
                            <span
                              className="material-symbols-outlined"
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              chat
                            </span>
                          </Link>
                        </div>
                        <div
                          style={{
                            marginTop: "6px",
                          }}
                        >
                          {ctr?.time && (
                            <span className="time" style={{ color: "white" }}>
                              {ctr?.timeAllowed}
                              {" mins"}
                            </span>
                          )}
                        </div>
                        <div
                          className="span"
                          style={{
                            padding: "3.2px 16px 8px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ModeEditIcon
                            onClick={() => {
                              setUpdateTaskDrawer(true);
                              settask(ctr.type);
                              settaskType(ctr.task);
                              settaskName(ctr.name);
                              settime(ctr.time);
                              settimeAllowed(ctr.timeAllowed);
                              setdate(ctr.date);
                              setdesc(ctr.description);
                              setimag(ctr.image);
                              setupdatetaskId(ctr.id);
                              setupdateMonth(ctr.month);
                              setsubStatus(ctr.sub_status);
                            }}
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr.status === "pending"
                                  ? "#8d5c0a"
                                  : ctr.status === "stopped"
                                  ? "#a73215"
                                  : ctr.status === "completed"
                                  ? "#0a7f00"
                                  : "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              marginRight: "8px",
                              cursor: "pointer",
                            }}
                          />
                          <DeleteIcon
                            // onClick={() => DeleteTask(ctr.id)}
                            onClick={() => {
                              setdeleteDialog(true);
                              setDeleteTaskId(ctr.id);
                            }}
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr.status === "pending"
                                  ? "#8d5c0a"
                                  : ctr.status === "stopped"
                                  ? "#a73215"
                                  : ctr.status === "completed"
                                  ? "#0a7f00"
                                  : "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              color: "white",
                              cursor: "pointer",
                            }}
                          />
                          {viewImage ? (
                            <HideImageIcon
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                              style={{
                                color: "white",
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => setViewImage(!viewImage)}
                            />
                          ) : (
                            <ImageIcon
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                              style={{
                                color: "white",
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => setViewImage(!viewImage)}
                            />
                          )}
                        </div>
                      </div>

                      {ctr.image && viewImage && (
                        <div
                          style={{ padding: "8px", cursor: "pointer" }}
                          onClick={() => {
                            setIsViewerOpen(true);
                            setTaskImage(ctr.image);
                          }}
                        >
                          <img
                            src={ctr.image}
                            height={"50%"}
                            width={"100%"}
                            style={{ borderRadius: "8px" }}
                          />
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
                {filters === "1" && ctr.status === "pending" && (
                  <Accordion
                    className="player"
                    id="c3"
                    defaultExpanded={ind === 0 ? true : false}
                  >
                    <AccordionSummary>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {ctr?.task && (
                          <div>
                            {ctr?.task?.map((title) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginBottom: "15px",
                                }}
                              >
                                {tasks?.map((i) => {
                                  return (
                                    i.task === title &&
                                    i.type === ctr.type && (
                                      <img
                                        src={i.icon}
                                        height={"22px"}
                                        width={"22px"}
                                        alt=""
                                      />
                                    )
                                  );
                                })}{" "}
                                <h4 style={{ marginLeft: "10px" }}>{title}</h4>
                              </div>
                            ))}
                          </div>
                        )}
                        <div
                          style={{
                            float: "right",
                          }}
                        >
                          {ctr?.time && (
                            <span className="time" style={{ color: "white" }}>
                              {ctr?.timeAllowed}
                              {" mins"}
                            </span>
                          )}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <p>{ctr.description}</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="span">
                          <Link to="/chat" style={{ color: "white" }}>
                            <span
                              className="material-symbols-outlined"
                              onMouseOver={({ target }) =>
                                (target.style.color = "#8d5c0a")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              chat
                            </span>
                          </Link>
                          {/* <span
                          className="material-symbols-outlined"
                          onMouseOver={({ target }) =>
                            (target.style.color = "#8d5c0a")
                          }
                          onMouseOut={({ target }) =>
                            (target.style.color = "white")
                          }
                          onClick={() => {
                            setIsViewerOpen(true);
                            setTaskImage(ctr?.image);
                          }}
                        >
                          attachment
                        </span> */}
                          {/* <span
                            className="material-symbols-outlined"
                            onClick={() => {
                              setTaskStatus(true);
                              settaskId(ctr.id);
                              setupdatedStatus("completed");
                            }}
                            onMouseOver={({ target }) =>
                              (target.style.color = "#8d5c0a")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                          >
                            task_alt
                          </span> */}
                        </div>
                        <div
                          className="span"
                          style={{ padding: "3.2px 16px 8px" }}
                        >
                          <ModeEditIcon
                            onClick={() => {
                              setUpdateTaskDrawer(true);
                              settask(ctr.type);
                              settaskType(ctr.task);
                              settaskName(ctr.name);
                              settime(ctr.time);
                              settimeAllowed(ctr.timeAllowed);
                              setdate(ctr.date);
                              setdesc(ctr.description);
                              setimag(ctr.image);
                              setupdatetaskId(ctr.id);
                              setupdateMonth(ctr.month);
                              setsubStatus(ctr.sub_status);
                            }}
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr.status === "pending"
                                  ? "#8d5c0a"
                                  : ctr.status === "stopped"
                                  ? "#a73215"
                                  : ctr.status === "completed"
                                  ? "#0a7f00"
                                  : "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              marginRight: "8px",
                              cursor: "pointer",
                            }}
                          />
                          <DeleteIcon
                            // onClick={() => DeleteTask(ctr.id)}
                            onClick={() => {
                              setdeleteDialog(true);
                              setDeleteTaskId(ctr.id);
                            }}
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr.status === "pending"
                                  ? "#8d5c0a"
                                  : ctr.status === "stopped"
                                  ? "#a73215"
                                  : ctr.status === "completed"
                                  ? "#0a7f00"
                                  : "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              color: "white",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                      {ctr.image && (
                        <div
                          style={{ padding: "8px", cursor: "pointer" }}
                          onClick={() => {
                            setIsViewerOpen(true);
                            setTaskImage(ctr.image);
                          }}
                        >
                          <img
                            src={ctr.image}
                            height={"50%"}
                            width={"100%"}
                            style={{ borderRadius: "8px" }}
                          />
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
                {filters === "2" && ctr.status === "stopped" && (
                  <Accordion
                    className="player"
                    id="c2"
                    defaultExpanded={ind === 0 ? true : false}
                  >
                    <AccordionSummary>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {ctr?.task && (
                          <div>
                            {ctr?.task?.map((title) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginBottom: "15px",
                                }}
                              >
                                {tasks?.map((i) => {
                                  return (
                                    i.task === title &&
                                    i.type === ctr.type && (
                                      <img
                                        src={i.icon}
                                        height={"22px"}
                                        width={"22px"}
                                        alt=""
                                      />
                                    )
                                  );
                                })}{" "}
                                <h4 style={{ marginLeft: "10px" }}>{title}</h4>
                              </div>
                            ))}
                          </div>
                        )}
                        <div
                          style={{
                            float: "right",
                          }}
                        >
                          {ctr?.time && (
                            <span className="time" style={{ color: "white" }}>
                              {ctr?.timeAllowed}
                              {" mins"}
                            </span>
                          )}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <p>{ctr.description}</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="span">
                          <span
                            className="material-symbols-outlined"
                            onMouseOver={({ target }) =>
                              (target.style.color = "#a73215")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                          >
                            chat
                          </span>
                          {/* <span
                          className="material-symbols-outlined"
                          onMouseOver={({ target }) =>
                            (target.style.color = "#a73215")
                          }
                          onMouseOut={({ target }) =>
                            (target.style.color = "white")
                          }
                          onClick={() => {
                            setIsViewerOpen(true);
                            setTaskImage(ctr?.image);
                          }}
                        >
                          attachment
                        </span> */}
                        </div>
                        <div
                          className="span"
                          style={{ padding: "3.2px 16px 8px" }}
                        >
                          <ModeEditIcon
                            onClick={() => {
                              setUpdateTaskDrawer(true);
                              settask(ctr.type);
                              settaskType(ctr.task);
                              settaskName(ctr.name);
                              settime(ctr.time);
                              settimeAllowed(ctr.timeAllowed);
                              setdate(ctr.date);
                              setdesc(ctr.description);
                              setimag(ctr.image);
                              setupdatetaskId(ctr.id);
                              setupdateMonth(ctr.month);
                              setsubStatus(ctr.sub_status);
                            }}
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr.status === "pending"
                                  ? "#8d5c0a"
                                  : ctr.status === "stopped"
                                  ? "#a73215"
                                  : ctr.status === "completed"
                                  ? "#0a7f00"
                                  : "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              marginRight: "8px",
                              cursor: "pointer",
                            }}
                          />
                          <DeleteIcon
                            // onClick={() => DeleteTask(ctr.id)}
                            onClick={() => {
                              setdeleteDialog(true);
                              setDeleteTaskId(ctr.id);
                            }}
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr.status === "pending"
                                  ? "#8d5c0a"
                                  : ctr.status === "stopped"
                                  ? "#a73215"
                                  : ctr.status === "completed"
                                  ? "#0a7f00"
                                  : "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              color: "white",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                      {ctr.image && (
                        <div
                          style={{ padding: "8px", cursor: "pointer" }}
                          onClick={() => {
                            setIsViewerOpen(true);
                            setTaskImage(ctr.image);
                          }}
                        >
                          <img
                            src={ctr.image}
                            height={"50%"}
                            width={"100%"}
                            style={{ borderRadius: "8px" }}
                          />
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
                {filters === "3" && ctr.status === "completed" && (
                  <Accordion
                    className="player"
                    id="c1"
                    defaultExpanded={ind === 0 ? true : false}
                  >
                    <AccordionSummary>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {ctr?.task && (
                          <div>
                            {ctr?.task?.map((title) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginBottom: "15px",
                                }}
                              >
                                {tasks?.map((i) => {
                                  return (
                                    i.task === title &&
                                    i.type === ctr.type && (
                                      <img
                                        src={i.icon}
                                        height={"22px"}
                                        width={"22px"}
                                        alt=""
                                      />
                                    )
                                  );
                                })}{" "}
                                <h4 style={{ marginLeft: "10px" }}>{title}</h4>
                              </div>
                            ))}
                          </div>
                        )}
                        <div
                          style={{
                            float: "right",
                          }}
                        >
                          {ctr?.time && (
                            <span className="time" style={{ color: "white" }}>
                              {ctr?.timeAllowed}
                              {" mins"}
                            </span>
                          )}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <p>{ctr.description}</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="span">
                          <span
                            className="material-symbols-outlined"
                            onMouseOver={({ target }) =>
                              (target.style.color = "#0a7f00")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                          >
                            chat
                          </span>
                          {/* <span
                          className="material-symbols-outlined"
                          onMouseOver={({ target }) =>
                            (target.style.color = "#0a7f00")
                          }
                          onMouseOut={({ target }) =>
                            (target.style.color = "white")
                          }
                          onClick={() => {
                            setIsViewerOpen(true);
                            setTaskImage(ctr?.image);
                          }}
                        >
                          attachment
                        </span> */}
                        </div>
                        <div
                          className="span"
                          style={{ padding: "3.2px 16px 8px" }}
                        >
                          <ModeEditIcon
                            onClick={() => {
                              setUpdateTaskDrawer(true);
                              settask(ctr.type);
                              settaskType(ctr.task);
                              settaskName(ctr.name);
                              settime(ctr.time);
                              settimeAllowed(ctr.timeAllowed);
                              setdate(ctr.date);
                              setdesc(ctr.description);
                              setimag(ctr.image);
                              setupdatetaskId(ctr.id);
                              setupdateMonth(ctr.month);
                              setsubStatus(ctr.sub_status);
                            }}
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr.status === "pending"
                                  ? "#8d5c0a"
                                  : ctr.status === "stopped"
                                  ? "#a73215"
                                  : ctr.status === "completed"
                                  ? "#0a7f00"
                                  : "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              marginRight: "8px",
                              cursor: "pointer",
                            }}
                          />
                          <DeleteIcon
                            // onClick={() => DeleteTask(ctr.id)}
                            onClick={() => {
                              setdeleteDialog(true);
                              setDeleteTaskId(ctr.id);
                            }}
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr.status === "pending"
                                  ? "#8d5c0a"
                                  : ctr.status === "stopped"
                                  ? "#a73215"
                                  : ctr.status === "completed"
                                  ? "#0a7f00"
                                  : "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              color: "white",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                      {ctr?.image && (
                        <div
                          style={{ padding: "8px", cursor: "pointer" }}
                          onClick={() => {
                            setIsViewerOpen(true);
                            setTaskImage(ctr.image);
                          }}
                        >
                          <img
                            src={ctr.image}
                            height={"50%"}
                            width={"100%"}
                            style={{ borderRadius: "8px" }}
                          />
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
                {filters === "4" && ctr.status === "assigned" && (
                  <Accordion
                    className="player"
                    id="c4"
                    defaultExpanded={ind === 0 ? true : false}
                  >
                    <AccordionSummary>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {ctr?.task && (
                          <div>
                            {ctr?.task?.map((title) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginBottom: "15px",
                                }}
                              >
                                {tasks?.map((i) => {
                                  return (
                                    i.task === title &&
                                    i.type === ctr.type && (
                                      <img
                                        src={i.icon}
                                        height={"22px"}
                                        width={"22px"}
                                        alt=""
                                      />
                                    )
                                  );
                                })}{" "}
                                <h4 style={{ marginLeft: "10px" }}>{title}</h4>
                              </div>
                            ))}
                          </div>
                        )}
                        <div
                          style={{
                            float: "right",
                          }}
                        >
                          {ctr?.time && (
                            <span className="time" style={{ color: "white" }}>
                              {ctr?.timeAllowed}
                              {" mins"}
                            </span>
                          )}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <p>{ctr.description}</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="span">
                          <span
                            className="material-symbols-outlined"
                            onMouseOver={({ target }) =>
                              (target.style.color = "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                          >
                            chat
                          </span>
                          {/* <span
                                            className="material-symbols-outlined"
                                            onClick={() => {
                                              setTaskStatus(true);
                                              settaskId(ctr?.id);
                                              setupdatedStatus("pending");
                                            }}
                                            onMouseOver={({ target }) =>
                                              (target.style.color =
                                                ctr.status === "pending"
                                                  ? "#8d5c0a"
                                                  : ctr.status === "stopped"
                                                  ? "#a73215"
                                                  : ctr.status === "completed"
                                                  ? "#0a7f00"
                                                  : "#a6a6a6")
                                            }
                                            onMouseOut={({ target }) =>
                                              (target.style.color = "white")
                                            }
                                          >
                                            task_alt
                                          </span> */}
                        </div>
                        <div
                          className="span"
                          style={{ padding: "3.2px 16px 8px" }}
                        >
                          <ModeEditIcon
                            onClick={() => {
                              setUpdateTaskDrawer(true);
                              settask(ctr.type);
                              settaskType(ctr.task);
                              settaskName(ctr.name);
                              settime(ctr.time);
                              settimeAllowed(ctr.timeAllowed);
                              setdate(ctr.date);
                              setdesc(ctr.description);
                              setimag(ctr.image);
                              setupdatetaskId(ctr.id);
                              setupdateMonth(ctr.month);
                              setsubStatus(ctr.sub_status);
                            }}
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr.status === "pending"
                                  ? "#8d5c0a"
                                  : ctr.status === "stopped"
                                  ? "#a73215"
                                  : ctr.status === "completed"
                                  ? "#0a7f00"
                                  : "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              marginRight: "8px",
                              cursor: "pointer",
                            }}
                          />
                          <DeleteIcon
                            // onClick={() => DeleteTask(ctr.id)}
                            onClick={() => {
                              setdeleteDialog(true);
                              setDeleteTaskId(ctr.id);
                            }}
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr.status === "pending"
                                  ? "#8d5c0a"
                                  : ctr.status === "stopped"
                                  ? "#a73215"
                                  : ctr.status === "completed"
                                  ? "#0a7f00"
                                  : "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              color: "white",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                      {ctr?.image && (
                        <div
                          style={{ padding: "8px", cursor: "pointer" }}
                          onClick={() => {
                            setIsViewerOpen(true);
                            setTaskImage(ctr.image);
                          }}
                        >
                          <img
                            src={ctr.image}
                            height={"50%"}
                            width={"100%"}
                            style={{ borderRadius: "8px" }}
                            alt=""
                          />
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
              </>
            )
          );
        })}
      </div>
    </>
  );
};

const TaskComponent = ({
  setOpenViewChildren,
  setOpenAddNewTask,
  setchild,
  setgetById,
}) => {
  const {
    getAssigneeData,
    getLoading,
    getError,
    getByIdLoading,
    getByIdData,
    updateLoading,
    updateSucc,
  } = useSelector((state) => state.assignee);

  const { taskLoading, createTaskData, success, taskData } = useSelector(
    (state) => state.task
  );

  const isNonMobile = useMediaQuery("(min-width: 760px)");
  const { getParent } = useSelector((state) => state.log);

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  const [editAssignee, setEditAssignee] = useState(false);

  const [id, setid] = useState("");
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [accomodation, setAccomodation] = useState("");
  const [color, setColor] = useState("");
  const [desc, setDesc] = useState("");

  const [passwordToggle, setPasswordToggle] = useState(false);

  const [dataUri, setDataUri] = useState(null);
  const [URL, setURL] = useState("");

  const onImageChange = (file) => {
    if (!file) {
      setDataUri("");
      return;
    }
    if (file.type === "image/png" || file.type === "image/jpeg") {
      fileToDataUri(file).then((dataUri) => {
        setDataUri(dataUri);
        dataURItoBlob(file);
      });
    } else {
      console.log("Please select only png/jpeg format of image.");
    }
  };
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);

    axios
      .post(`${BASE_URL}/api/aws/file?email=${getParent.email}`, formData)
      .then((res) => {
        setURL(res.data.url);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("image", err);
      });
  }

  const [updateAss, setupdateAss] = useState(false);
  const [changePasswordDrawer, setChangePasswordDrawer] = useState(false);

  useEffect(() => {
    if (updateSucc) {
      setupdateAss(true);
    }
  }, [updateSucc]);

  const current = new Date();
  const navigate = useNavigate();

  const [expiry, setExpiry] = useState(false);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={changePasswordDrawer}
        onClose={() => setChangePasswordDrawer(false)}
      >
        <ChangePasswordAssignee
          setChangePasswordDrawer={setChangePasswordDrawer}
          id={id}
        />
      </Drawer>

      {getAssigneeData?.map((child, index) => {
        return (
          <div className="crd" id="crd1" key={index}>
            <div className="info">
              {child.image ? (
                <img
                  src={child?.image}
                  width="150"
                  height="150"
                  style={{ borderRadius: "360px" }}
                  alt=""
                />
              ) : (
                <img
                  src={require("../images/user.png")}
                  width="150"
                  height="150"
                  style={{ borderRadius: "360px" }}
                  alt=""
                />
              )}
              <p
                style={{
                  backgroundColor:
                    child.color.toLocaleLowerCase() === "white"
                      ? "#FBFCF8"
                      : child.color.toLocaleLowerCase() === "yellow"
                      ? "#e5e500"
                      : child.color,
                  color:
                    child.color.toLocaleLowerCase() === "white"
                      ? "black"
                      : "white",
                  border:
                    child.color.toLocaleLowerCase() === "white"
                      ? "1px solid black"
                      : `0px solid ${child.color}`,
                }}
              >
                ASSINGEE #{index + 1}
              </p>
              <div className="text">
                <h5 style={{ alignItems: "center", justifyContent: "center" }}>
                  {child.name}
                  {"  "}
                  <Button
                    style={{
                      maxWidth: "10px",
                      maxHeight: "10px",
                      minWidth: "10px",
                      minHeight: "10px",
                      color: "#000",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      setEditAssignee(true);
                      setid(child.id);
                      setName(child.name);
                      setUserName(child.userName);
                      setAge(child.age);
                      setGender(child.gender);
                      setAccomodation(child.accomodation);
                      setColor(child.color);
                      setDesc(child.description);
                      setDataUri(child.image);
                    }}
                  >
                    <a className="material-symbols-outlined" id="edit">
                      edit
                    </a>
                  </Button>
                  <Button
                    style={{
                      maxWidth: "10px",
                      maxHeight: "10px",
                      minWidth: "10px",
                      minHeight: "10px",
                      color: "#000",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      setChangePasswordDrawer(true);
                      setid(child.id);
                    }}
                  >
                    <PasswordIcon />
                  </Button>
                </h5>
                <h6>{child.age} years old</h6>
              </div>
              <div className="button">
                <Link to={"/chat"} style={{ color: "#000", width: "100%" }}>
                  <button className="bton" id="btn1" style={{}}>
                    <span className="material-symbols-outlined">chat</span> Chat
                  </button>
                </Link>
                <button
                  className="bton"
                  id="btn"
                  onClick={() => {
                    setOpenViewChildren(true);
                    dispatch(getAssigneeById(child.id));
                    setgetById(index + 1);
                  }}
                >
                  <span className="material-symbols-outlined">person</span>{" "}
                  Child Detail
                </button>
              </div>
            </div>
            <Drawer
              anchor={"right"}
              open={editAssignee}
              onClose={() => setEditAssignee(false)}
            >
              <Box
                width={isNonMobile ? "700px" : "350px"}
                style={{
                  padding: 0,
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <GreenDialogModal
                  value={updateAss}
                  setValue={() => setupdateAss(false)}
                  title="Confirmation"
                  subtitle="Assignee updated successfully"
                  onClick={() => {
                    setupdateAss(false);
                    dispatch(clearSuccUpdateAss());
                    dispatch(getAssignee());
                    setEditAssignee(false);
                  }}
                />
                {/* <Dialog open={updateAss} onClose={() => setupdateAss(false)}>
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Assignee updated successfully
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setupdateAss(false);
                        dispatch(clearSuccUpdateAss());
                        dispatch(getAssignee());
                      }}
                    >
                      Okay
                    </Button>
                  </DialogActions>
                </Dialog> */}
                <div className="popup" id="myForm">
                  <div className="children">
                    <div className="heading">
                      <h5>Assignee</h5>
                      <h3>Edit Assignee</h3>
                    </div>
                    <button
                      className="close-btn"
                      id="close-modal"
                      onClick={() => setEditAssignee(false)}
                    >
                      X
                    </button>
                  </div>
                  <hr />
                  <div className="form-element">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        {dataUri ? (
                          <img
                            src={dataUri}
                            width="150px"
                            height="150px"
                            style={{ borderRadius: "75px" }}
                          />
                        ) : (
                          <img
                            src={require("../images/user.png")}
                            width="150px"
                            height="150px"
                            style={{ borderRadius: "75px" }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 10,
                        marginBottom: 0,
                      }}
                    >
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(event) =>
                          onImageChange(event.target.files[0] || null)
                        }
                      />
                      <label htmlFor="select-image">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Update Avatar
                        </Button>
                      </label>
                    </div>
                    <div className="element">
                      <label for="name">
                        Full Name<i>*</i>
                        <br />
                        <span id="mins">e.g. William Marshal</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        placeholder="Enter name"
                        required
                        defaultValue={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="element">
                      <label for="name">
                        Username<i>*</i>
                        <br />
                        <span id="mins">e.g. Marshal_killian</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        placeholder="Enter username"
                        required
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                    <div className="element">
                      <label for="task">
                        Age<i>*</i>
                        <br />
                        <span id="mins">e.g. 14 years old</span>
                      </label>
                      <input
                        type="number"
                        list="age"
                        id="choose"
                        placeholder="Enter or choose age"
                        required
                        onChange={(e) => setAge(e.target.value)}
                        value={age}
                      />
                      <datalist id="age">
                        <option value="10" />
                        <option value="12" />
                        <option value="13" />
                        <option value="14" />
                        <option value="15" />
                      </datalist>
                      {/* <Select
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        label="Age"
                        sx={{
                          width: "60%",
                          padding: "0px",
                          backgroundColor: "#edf1f4",
                          borderRadius: "6px",
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                      </Select> */}
                    </div>
                    <div className="element">
                      <label for="task">
                        Gender<i>*</i>
                        <br />
                        <span id="mins">e.g. Male/Female/others</span>
                      </label>
                      {/* <input
                        list="gender"
                        name="gender"
                        id="choose"
                        placeholder="Enter or choose gender"
                        required
                        defaultValue={gender}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <datalist id="gender">
                        <option value="Male" />
                        <option value="Female" />
                        <option value="Others" />
                      </datalist> */}
                      <Select
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        label="Gender"
                        sx={{
                          width: "60%",
                          padding: "0px",
                          backgroundColor: "#edf1f4",
                          borderRadius: "6px",
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                        <MenuItem value={"Others"}>Others</MenuItem>
                      </Select>
                    </div>
                    {/* <div className="element">
                      <label for="task">
                        Need accommodation(s)?
                        <br />
                        <span id="mins">e.g. Physical/Intellectual/Mental</span>
                      </label>
                      
                      <Select
                        value={accomodation}
                        onChange={(e) => setAccomodation(e.target.value)}
                        label="Accomodation"
                        sx={{
                          width: "60%",
                          padding: "0px",
                          backgroundColor: "#edf1f4",
                          borderRadius: "6px",
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value={"Physical"}>Physical</MenuItem>
                        <MenuItem value={"Intellectual"}>Intellectual</MenuItem>
                        <MenuItem value={"Mental"}>Mental</MenuItem>
                      </Select>
                    </div> */}
                    <div className="element">
                      <label for="task">
                        Color tab<i>*</i>
                        <br />
                        <span id="mins">e.g. Red, Green, Orange</span>
                      </label>
                      {/* <input
                        list="color"
                        name="color"
                        id="choose"
                        placeholder="Choose your color"
                        required
                        defaultValue={color}
                        onChange={(e) => setColor(e.target.value)}
                      />
                      <datalist id="color">
                        <option value="Red" />
                        <option value="Green" />
                        <option value="Orange" />
                        <option value="Yellow" />
                        <option value="Brown" />
                        <option value="Purple" />
                        <option value="Black" />
                      </datalist> */}
                      <Select
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        label="Gender"
                        sx={{
                          width: "60%",
                          padding: "0px",
                          backgroundColor: "#edf1f4",
                          borderRadius: "6px",
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value={"Red"}>Red</MenuItem>
                        <MenuItem value={"Yellow"}>Yellow</MenuItem>
                        <MenuItem value={"Brown"}>Brown</MenuItem>
                        <MenuItem value={"White"}>White</MenuItem>
                        <MenuItem value={"Black"}>Black</MenuItem>
                      </Select>
                    </div>
                    {/* <div className="element">
                      <label for="describe">
                        Description
                        <br />
                        <span id="mins">e.g. about assignee</span>
                      </label>
                      <textarea
                        id="describe"
                        placeholder="Write Here..."
                        rows="4"
                        maxlength="150"
                        defaultValue={desc}
                        onChange={(e) => setDesc(e.target.value)}
                      ></textarea>
                    </div> */}
                  </div>
                  <hr id="hr" />
                  <div className="bttns">
                    <button
                      className="bttn"
                      onClick={() => setEditAssignee(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bttn"
                      onClick={() =>
                        dispatch(
                          updateAssignee(
                            name,
                            age,
                            gender,
                            color,
                            desc,
                            URL,
                            id,
                            userName
                          )
                        )
                      }
                    >
                      Update
                    </button>
                  </div>
                </div>
              </Box>
            </Drawer>

            <br />
            <hr />
            <div className="sec2">
              <div className="task">
                <h3>Task Inprogress</h3>
                <button
                  id="addNewTask"
                  onClick={() => {
                    {
                      moment(getParent.expiryDate).format("YYYY-MM-DD") >=
                      moment(current).format("YYYY-MM-DD")
                        ? setOpenAddNewTask(true)
                        : setExpiry(true);
                    }
                    setchild(child.id);
                  }}
                >
                  + Add Task
                </button>
              </div>
              <RedDialogModal
                value={expiry}
                setValue={() => setExpiry(false)}
                title="Package"
                subtitle="Your package has expired. Please renew."
                onClick={() => {
                  setExpiry(false);
                  navigate("/packages");
                }}
                width="20%"
                buttonText="Buy a package"
              />
              {/* <Dialog open={expiry} onClose={() => setExpiry(false)}>
                <DialogTitle>Package</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Your package has expired. Please renew.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setExpiry(false);
                      navigate("/packages");
                    }}
                  >
                    Okay
                  </Button>
                </DialogActions>
              </Dialog> */}
              <DisplayTasks child={child} />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TaskComponent;
