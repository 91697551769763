import axios from "axios";
import { BASE_URL } from "../../constants/config";

// Parent

export const setLogin =
  (email = "", password = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "loginRequest",
      });
      const { data } = await axios.post(`${BASE_URL}/api/parent/login`, {
        email: email.toLowerCase(),
        password: password,
      });
      dispatch({
        type: "loginSuccess",
        payload: data,
      });
      localStorage.setItem("token", JSON.stringify(data.token));
    } catch (error) {
      dispatch({
        type: "loginFailure",
        payload: error,
      });
    }
  };

export const setRegister =
  (email = "", password = "", name = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "registerRequest",
      });
      const { data } = await axios.post(`${BASE_URL}/api/parent/register`, {
        email: email.toLowerCase(),
        password: password,
        name: name,
      });
      dispatch({
        type: "registerSuccess",
        payload: data,
      });
      localStorage.setItem("token", JSON.stringify(data.token));
    } catch (error) {
      dispatch({
        type: "registerFailure",
        payload: error,
      });
    }
  };

export const clear = () => async (dispatch) => {
  try {
    dispatch({
      type: "clearAuth",
    });
  } catch (error) {}
};

export const setLogout = () => async (dispatch) => {
  localStorage.removeItem("token");
  try {
    dispatch({
      type: "logoutRequest",
    });
    const { data } = await axios.get(`${BASE_URL}/api/parent/logout`, {
      headers: { "Content-type": "application/json" },
    });
    dispatch({
      type: "logoutSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "logoutFailure",
      payload: error,
    });
  }
};

export const getParentbyId = (id) => async (dispatch) => {
  let token = localStorage.getItem("token");
  try {
    dispatch({
      type: "getProfileRequest",
    });
    const { data } = await axios.get(
      `${BASE_URL}/api/parent/getParent?id=${id}`,
      {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      }
    );
    dispatch({
      type: "getProfileSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "getProfileFailure",
      payload: error,
    });
  }
};
export const updateParent = (id, name, email, image) => async (dispatch) => {
  let token = localStorage.getItem("token");
  try {
    dispatch({
      type: "updateParentRequest",
    });
    const { data } = await axios.post(
      `${BASE_URL}/api/parent/updateParent`,
      {
        id: id,
        name: name,
        email: email,
        image: image,
      },
      {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      }
    );
    dispatch({
      type: "updateParentSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "updateParentFailure",
      payload: error,
    });
  }
};
export const clearUpdate = () => async (dispatch) => {
  try {
    dispatch({
      type: "clearUpdateSucess",
    });
  } catch (error) {}
};

// Child
export const getChildbyId = (id) => async (dispatch) => {
  try {
    let token = localStorage.getItem("token");
    dispatch({
      type: "getProfileCRequest",
    });
    const { data } = await axios.get(
      `${BASE_URL}/api/children/getChildProfile?id=${id}`,
      {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      }
    );
    dispatch({
      type: "getProfileCSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "getProfileCFailure",
      payload: error,
    });
  }
};

export const childLogin =
  (username = "", password = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "loginChildRequest",
      });
      const { data } = await axios.post(`${BASE_URL}/api/children/login`, {
        userName: username.toLowerCase(),
        password: password,
      });
      dispatch({
        type: "loginChildSuccess",
        payload: data,
      });
      localStorage.setItem("token", JSON.stringify(data.token));
    } catch (error) {
      dispatch({
        type: "loginChildFailure",
        payload: error.response,
      });
    }
  };

// Image Upload

export const imageUpload =
  (uri = "", email = "") =>
  async (dispatch) => {
    let formData = new FormData();
    formData.append("file", uri);
    try {
      dispatch({
        type: "imageRequest",
      });
      const { data } = await axios.post(
        `${BASE_URL}/api/aws/file?email=${email}`,
        formData
      );
      dispatch({
        type: "imageSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "imageFailure",
        payload: error.response,
      });
    }
  };
