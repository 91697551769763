import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Drawer, CircularProgress, Backdrop } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SideDrawer from "../../components/SideDrawer";
import HeaderComp from "../../components/HeaderParent";
import { useDispatch, useSelector } from "react-redux";
import { getParentbyId } from "../../redux/dispatcher/login";
import {
  getFilteredTask,
  getTask,
  getTaskByStatus,
  getTaskHistory,
} from "../../redux/dispatcher/tasks";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { getAssignee } from "../../redux/dispatcher/assignee";
import moment from "moment";
import NewTas from "../../components/NewTas";
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import Banner from "../../components/Banner";
import { RedDialogModal } from "../../components/DialogModal";

export default function Dashboard() {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const [openAddNewTask, setOpenAddNewTask] = React.useState(false);

  const [taskName, setTaskName] = useState("");
  const [taskType, setTaskType] = useState("");
  const [task, setTask] = useState("");
  const [timerState, setTimerState] = useState("");
  const [time, setTime] = useState("");
  const [taskDesc, settaskDesc] = useState("");
  const [assignedToId, setassignedToId] = useState("");

  const {
    loginData,
    token,
    isAuthenticated,
    getParent,
    updateLoading,
    update,
    childLoginData,
  } = useSelector((state) => state.log);

  const {
    taskLoading,
    createTaskData,
    success,
    taskData,
    getTaskError,
    pendingTaskData,
    completedTaskData,
    stoppedTaskData,
    createAssiLoading,
    assignedTaskData,
  } = useSelector((state) => state.task);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("first");
    dispatch(getTask());
    dispatch(getFilteredTask());
    dispatch(getTaskByStatus());
  }, []);

  const current = new Date();

  const [startDate, setStartDate] = useState(
    moment(current).subtract(1, "M").format("MM-DD-YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(current).add(1, "M").format("MM-DD-YYYY")
  );

  useEffect(() => {
    dispatch(getTaskHistory(startDate, endDate));
  }, []);

  const lineData = {
    labels:
      assignedTaskData?.length === 0
        ? pendingTaskData?.length === 0
          ? completedTaskData?.length === 0
            ? stoppedTaskData?.map((i) => i.month)
            : completedTaskData?.map((i) => i.month)
          : pendingTaskData?.map((i) => i.month)
        : assignedTaskData?.map((i) => i.month),
    datasets: [
      {
        label: "ASSIGNED",
        data: assignedTaskData?.map((i) => i.tasks),
        backgroundColor: ["#a6a6a6"],
        borderColor: "#A6A6A6",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "PENDING",
        data: pendingTaskData?.map((i) => i.tasks),
        backgroundColor: ["#EDA225"],
        borderColor: "#EDA225",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "STOPPED",
        data: stoppedTaskData?.map((i) => i.tasks),
        backgroundColor: ["#EF481E"],
        borderColor: "#EF481E",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "COMPLETED",
        data: completedTaskData?.map((i) => i.tasks),
        backgroundColor: ["#9ED732"],
        borderColor: "#9ED732",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const legend = {
    display: true,
    position: "bottom",
    labels: {
      fontColor: "#323130",
      fontSize: 14,
    },
  };

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 30,
        right: 30,
      },
    },
  };

  const { getAssigneeData, getLoading, getError, getByIdLoading, getByIdData } =
    useSelector((state) => state.assignee);

  useEffect(() => {
    dispatch(getAssignee());
  }, []);

  const [editProfile, seteditProfile] = useState(false);

  const dat = new Date();

  const [dataUri, setDataUri] = useState(null);
  const [URL, setURL] = useState("");

  const suspendCount = taskData?.filter((i) => i.status === "suspend").length;
  const pendingCount = taskData?.filter((i) => i.status === "pending").length;
  const stoppedCount = taskData?.filter((i) => i.status === "stopped").length;
  const assignedCount = taskData?.filter((i) => i.status === "assigned").length;
  const completedCount = taskData?.filter(
    (i) => i.status === "completed"
  ).length;

  const onImageChange = (file) => {
    if (!file) {
      setDataUri("");
      return;
    }
    if (file.type === "image/png" || file.type === "image/jpeg") {
      fileToDataUri(file).then((dataUri) => {
        setDataUri(dataUri);
        dataURItoBlob(file);
      });
    } else {
      console.log("Please select only png/jpeg format of image.");
    }
  };
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    axios
      .post(`${BASE_URL}/api/aws/file?email=${loginData.email}`, formData)
      .then((res) => {
        setURL(res.data.url);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (!update) {
      setupdated(true);
    }
  }, [update]);

  const [username, setUsername] = useState(getParent?.name);
  const [email, setEmail] = useState(getParent?.email);

  const [updated, setupdated] = useState(false);

  useEffect(() => {
    dispatch(getParentbyId(loginData?.id));
  }, []);

  const navigate = useNavigate();

  const [expiry, setExpiry] = useState(false);

  let menuBar = document.getElementById("menuBar");
  let mainBar = document.getElementById("mainBar");

  function toggleBar() {
    menuBar.classList.toggle("open-bar");
    mainBar.classList.toggle("open-bar2");
  }

  // const { convoid } = useSelector((state) => state.chat);

  // useEffect(() => {
  //   console.log(convoid);
  //   if (convoid === undefined) {
  //     dispatch(createConversation(loginData?.id));
  //   } else dispatch(getConvoId);
  // }, [convoid]);

  return (
    <section>
      <Header />
      <div class="sidebar" id="menuBar" style={{ zIndex: "999" }}>
        <SideDrawer />
      </div>
      <div className="main" id="mainBar">
        <Banner />
        <HeaderComp
          title={"Dashboard"}
          seteditProfile={seteditProfile}
          toggle={toggleBar}
        />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={taskLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={createAssiLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <main>
          {/* <!--CARDS--> */}

          <div className="cards">
            <div className="card-one" id="four">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {assignedCount}{" "}
                  <span className="material-symbols-outlined">
                    trending_down
                  </span>
                </h2>
                <h6>TASKS ASSIGNED</h6>
              </div>
            </div>
            <div className="card-one" id="one">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {stoppedCount}{" "}
                  <span className="material-symbols-outlined">
                    trending_down
                  </span>
                </h2>

                <h6>TASKS INCOMPLETE</h6>
              </div>
            </div>
            <div className="card-one" id="two">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {pendingCount}{" "}
                  <span className="material-symbols-outlined">trending_up</span>
                </h2>

                <h6>TASKS PENDING</h6>
              </div>
            </div>
            <div className="card-one" id="three">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {completedCount}{" "}
                  <span className="material-symbols-outlined">trending_up</span>
                </h2>

                <h6>TASKS DONE</h6>
              </div>
            </div>
          </div>

          {/* <!--LINE CHART--> */}

          <div className="chartBox">
            <h3>Tasks tracking</h3>
            <Line
              style={{ paddingHorizontal: 10 }}
              data={lineData}
              legend={legend}
              options={options}
            />
          </div>

          {/* <!--CARDS-2--> */}

          <div className="cards2">
            {getAssigneeData?.map((child, index) => {
              return (
                <div className="crd" id="crd1" key={index}>
                  <div className="info">
                    {child.image ? (
                      <img
                        src={child?.image}
                        width="150"
                        height="150"
                        style={{ borderRadius: "360px" }}
                      />
                    ) : (
                      <img
                        src={require("../../images/user.png")}
                        width="150"
                        height="150"
                        style={{ borderRadius: "360px" }}
                      />
                    )}
                    <p
                      style={{
                        backgroundColor:
                          child.color.toLocaleLowerCase() === "white"
                            ? "#FBFCF8"
                            : child.color.toLocaleLowerCase() === "yellow"
                            ? "#e5e500"
                            : child.color,
                        color:
                          child.color.toLocaleLowerCase() === "white"
                            ? "black"
                            : "white",
                        border:
                          child.color.toLocaleLowerCase() === "white"
                            ? "1px solid black"
                            : `0px solid ${child.color}`,
                      }}
                    >
                      ASSINGEE #{index + 1}
                    </p>
                    <div className="text">
                      <h5
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {child.name}
                        {"  "}
                      </h5>
                      <h6>{child.age} years old</h6>
                      <button
                        style={{ marginTop: "7.5px" }}
                        id="addNewTask"
                        onClick={() => {
                          {
                            moment(getParent.expiryDate).format("YYYY-MM-DD") >=
                            moment(current).format("YYYY-MM-DD")
                              ? setOpenAddNewTask(true)
                              : setExpiry(true);
                          }
                          setassignedToId(child.id);
                        }}
                      >
                        + Add Task
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <RedDialogModal
            value={expiry}
            setValue={() => setExpiry(false)}
            title="Package"
            subtitle="Your package has expired. Please renew."
            onClick={() => {
              setExpiry(false);
              navigate("/packages");
            }}
            width={"20%"}
            buttonText="Buy a package"
          />

          {/* <Dialog open={expiry} onClose={() => setExpiry(false)}>
            <DialogTitle>Package</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Your package has expired. Please renew.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setExpiry(false);
                  navigate("/packages");
                }}
              >
                Okay
              </Button>
            </DialogActions>
          </Dialog> */}
          <Drawer
            anchor={"right"}
            open={openAddNewTask}
            onClose={() => setOpenAddNewTask(false)}
          >
            <NewTas
              setOpenAddNewTask={setOpenAddNewTask}
              assignedToId={assignedToId}
            />
          </Drawer>
        </main>
      </div>
    </section>
  );
}
