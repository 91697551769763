import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogin, setRegister } from "../../redux/dispatcher/login";
import { Backdrop, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";

const SignUp2 = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const passwordRegex = /^(?=.*\d).{6,}$/;

  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { loginData, loading, loginError, isAuthenticated, regLoading } =
    useSelector((state) => state.log);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/package");
    }
  }, [isAuthenticated, loading]);

  const onSubmit = (e) => {
    dispatch(setRegister(email, password, name));
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <section>
      <Header />
      <body>
        {/* <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={regLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
        <div className="main">
          <div className="grid">
            <div className="child1">
              <img
                src={require("../../images/logo.png")}
                width="150px"
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              />
              <div>
                <h1>
                  Easy way to
                  <br /> Communicate
                  <br /> within a family.
                </h1>
                <p>
                  LORA is a communication system of productive
                  <br />
                  interaction between an assigner (adult) and
                  <br /> assignee(s) (minor).
                </p>
              </div>
              <Link to={"/sign-in"}>
                <button id="signup">Sign In</button>
              </Link>
            </div>
            <div className="child2">
              <h3 style={{ fontWeight: "bold", color: "#04031c" }}>
                Start your
                <br /> Journey with us
              </h3>
              <p>
                Discover the world's best web
                <br /> app for parents & childrens
              </p>
              {errors?.user && (
                <p
                  style={{
                    color: "red",
                    fontSize: "10px",
                    alignSelf: "flex-start",
                    paddingBottom: 0,
                  }}
                >
                  *Username is required
                </p>
              )}
              <input
                type="email"
                id="email"
                name="user"
                {...register("user", { required: true })}
                placeholder="Enter username"
                required
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
                style={{ marginTop: errors.user ? 0 : 16 }}
              />
              {errors?.email && (
                <p
                  style={{
                    color: "red",
                    fontSize: "10px",
                    alignSelf: "flex-start",
                    paddingBottom: "4px",
                  }}
                >
                  *Email is required
                </p>
              )}
              <input
                type="email"
                id="email"
                name="email"
                {...register("email", { required: true })}
                placeholder="Enter email address"
                required
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginTop: errors.email ? 0 : 16 }}
              />
              {errors?.password?.type === "required" && (
                <p
                  style={{
                    color: "red",
                    fontSize: "10px",
                    alignSelf: "flex-start",
                    paddingBottom: "4px",
                  }}
                >
                  *Password is required
                </p>
              )}
              {errors?.password?.type === "minLength" && (
                <p
                  style={{
                    maxWidth: "95%",
                    color: "red",
                    fontSize: "10px",
                    alignSelf: "flex-start",
                    paddingBottom: "4px",
                    textAlign: "left",
                  }}
                >
                  *Password must contain at least 6 characters and 1 number
                </p>
              )}
              {errors?.password?.type === "pattern" && (
                <p
                  style={{
                    color: "red",
                    fontSize: "10px",
                    alignSelf: "flex-start",
                    paddingBottom: "4px",
                    textAlign: "left",
                  }}
                >
                  *Password must contain at least 6 characters
                  <br /> and 1 number
                </p>
              )}
              <div
                className="psswrd"
                style={{ marginTop: errors.password ? 0 : 16 }}
              >
                <input
                  type={!passwordToggle ? "password" : "text"}
                  id="password"
                  name="password"
                  {...register("password", {
                    required: true,
                    pattern: passwordRegex,
                  })}
                  placeholder="Enter password"
                  required
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span onClick={() => setPasswordToggle(!passwordToggle)}>
                  {passwordToggle ? (
                    <i className="material-symbols-outlined" id="eye">
                      visibility
                    </i>
                  ) : (
                    <i className="material-symbols-outlined" id="eye">
                      visibility_off
                    </i>
                  )}
                </span>
              </div>

              <button
                id="signin"
                onClick={handleSubmit(onSubmit)}
                style={{ cursor: "pointer" }}
              >
                Sign Up
              </button>

              <img id="img5" src="./images/Vector.png" width="100px" alt="" />
              <img id="img2" src="./images/Vector2.png" width="100px" alt="" />
            </div>
          </div>
        </div>
      </body>
    </section>
  );
};

export default SignUp2;
