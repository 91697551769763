import axios from "axios";
import { BASE_URL } from "../../constants/config";
import { useDispatch } from "react-redux";
import { clear } from "./login";

// Parent Flow
const api = axios.create({
  baseURL: BASE_URL,
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // window.location.href = "/dashboard";
    }
    return Promise.reject(error);
  }
);
export const createTask =
  (
    name = "",
    type = "",
    task = [],
    time = false,
    timeAllowed = "",
    assignedToId = "",
    desc = "",
    date = "",
    month = "",
    image = "",
    isRecurring = false,
    selectedDays = [],
    selectedDate = "",
    recurringType = ""
  ) =>
  async (dispatch) => {
    // let tasks = [];

    // for (let index = 0; index < task.length; index++) {
    //   tasks.push({
    //     name: name,
    //     type: type,
    //     task: task[index],
    //     time: time,
    //     timeAllowed: timeAllowed,
    //     assignedToId: assignedToId,
    //     description: desc,
    //     date: date,
    //     month: month,
    //     image: image,
    //   });
    // }

    let subStatus = [];

    for (let index = 0; index < task.length; index++) {
      subStatus.push("assigned");
    }

    let token = localStorage.getItem("token");
    console.log(date, month);
    try {
      dispatch({
        type: "createTaskRequest",
      });
      const { data } = await api.post(
        `/api/task/createTask`,
        // {
        //   tasks,
        // },
        {
          name: name,
          type: type,
          task: task,
          time: time,
          timeAllowed: timeAllowed,
          assignedToId: assignedToId,
          description: desc,
          date: date,
          month: month,
          image: image,
          is_group: task.length > 1 ? true : false,
          sub_status: subStatus,
          isRecurring: isRecurring,
          selectedDays: selectedDays,
          selectedDate: selectedDate,
          recurringType: recurringType,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      dispatch({
        type: "createTaskSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "createTaskFailure",
        payload: error,
      });
    }
  };

export const updateTaskStatus =
  (id, status = "") =>
  async (dispatch) => {
    let token = localStorage.getItem("token");
    try {
      dispatch({
        type: "updateTaskRequest",
      });
      const { data } = await api.post(
        `/api/task/updateTaskStatus`,
        {
          id: id,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      dispatch({
        type: "updateTaskSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "updateTaskFailure",
        payload: error.response,
      });
    }
  };

export const clearSuccess = () => async (dispatch) => {
  try {
    dispatch({
      type: "clearSuc",
    });
  } catch (error) {}
};

export const getTaskByStatus = () => async (dispatch) => {
  let token = localStorage.getItem("token");
  try {
    dispatch({
      type: "getTaskByStatusRequest",
    });
    const { data } = await api.get(`/api/task/getTaskByStatusTypeParent`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    dispatch({
      type: "getTaskByStatusSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "getTaskByStatusFailure",
      payload: error,
    });
  }
};

export const getFilteredTask = () => async (dispatch) => {
  let token = localStorage.getItem("token");
  try {
    dispatch({
      type: "getFilteredTaskRequest",
    });
    const { data } = await api.get(`/api/task/getfilteredTask`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    dispatch({
      type: "getFilteredTaskSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "getFilteredTaskFailure",
      payload: error.response,
    });
  }
};

export const getTask = () => async (dispatch) => {
  let token = localStorage.getItem("token");

  try {
    dispatch({
      type: "getTaskRequest",
    });
    const { data } = await api.get(`/api/task/getTask`, {
      headers: {
        Authorization: `token ${JSON.parse(token)}`,
      },
    });

    dispatch({
      type: "getTaskSuccess",
      payload: data,
    });
  } catch (error) {
    console.log(error, "geterror");
    dispatch({
      type: "getTaskFailure",
      payload: error,
    });
  }
};

export const getTaskHistory =
  (start = "", end = "") =>
  async (dispatch) => {
    let token = localStorage.getItem("token");

    try {
      dispatch({
        type: "getTaskHistoryRequest",
      });
      const { data } = await api.get(
        `/api/task/getTaskHistory?start_Date=${start}&end_Date=${end}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );

      dispatch({
        type: "getTaskHistorySuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "getTaskHistoryFailure",
        payload: error.response,
      });
    }
  };

export const createCommentOnTask =
  (comment = "", taskID = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "createCommentOnTaskRequest",
      });
      const { data } = await api.post(`/api/taskComments/addCommentParent`, {
        taskId: taskID,
        comment: comment,
      });
      dispatch({
        type: "createCommentOnTaskSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "createCommentOnTaskFailure",
        payload: error,
      });
    }
  };

export const getCommentOnTasks =
  (id = 1) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "getCommentOnTaskRequest",
      });
      const { data } = await api.get(`/apigetTaskcomments?taskId=${id}`, {
        headers: { "Content-type": "application/json" },
      });
      dispatch({
        type: "getCommentOnTaskSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "getCommentOnTaskFailure",
        payload: error,
      });
    }
  };

// Child Flow

export const getFilteredTaskChild = () => async (dispatch) => {
  try {
    let token = localStorage.getItem("token");
    dispatch({
      type: "getFilteredTaskChildRequest",
    });
    const { data } = await api.get(`/api/task/getfilteredTaskByChild`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    dispatch({
      type: "getFilteredTaskChildSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "getFilteredTaskChildFailure",
      payload: error.response,
    });
  }
};
export const getTaskChild = () => async (dispatch) => {
  try {
    let token = localStorage.getItem("token");
    dispatch({
      type: "getTaskChildRequest",
    });
    const { data } = await api.get(`/api/task/getTaskChild`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    dispatch({
      type: "getTaskChildSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "getTaskChildFailure",
      payload: error.response,
    });
  }
};

export const getChildTaskHistory =
  (start = "", end = "") =>
  async (dispatch) => {
    let token = localStorage.getItem("token");
    try {
      dispatch({
        type: "getChildHistoryRequest",
      });
      const { data } = await api.get(
        `/api/task/getChildTaskHistory?start_Date=${start}&end_Date=${end}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );

      dispatch({
        type: "getChildHistorySuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "getChildHistoryFailure",
        payload: error.response,
      });
    }
  };
