import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import HeaderComp from "../../components/HeaderParent";
import {
  Drawer,
  Box,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  useMediaQuery,
  IconButton,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  clearSuccAss,
  createAssignee,
  getAssignee,
} from "../../redux/dispatcher/assignee";
import {
  clearSuccess,
  getFilteredTask,
  getTask,
  getTaskByStatus,
  getTaskHistory,
  updateTaskStatus,
} from "../../redux/dispatcher/tasks";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { tasks } from "../../assets/data";
import NewTas from "../../components/NewTas";
import moment from "moment";
import TaskComponent from "../../components/TaskComponent";
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import { useForm } from "react-hook-form";
import Banner from "../../components/Banner";
import SideDrawer from "../../components/SideDrawer";
import { GreenDialogModal, RedDialogModal } from "../../components/DialogModal";
import ImageViewer from "react-simple-image-viewer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccordionComponent from "../../components/AccordionComponent";
import { getParentbyId } from "../../redux/dispatcher/login";

export default function Assignee() {
  const current = new Date();

  const [open, setOpen] = React.useState(false);
  const [openViewChildren, setOpenViewChildren] = React.useState(false);
  const [openAddNewTask, setOpenAddNewTask] = React.useState(false);

  const { loginData, getParent, childLoginData } = useSelector(
    (state) => state.log
  );

  const [name, setName] = useState("");
  const [userName, setUserName] = useState(
    getParent?.name.includes(" ")
      ? getParent?.name.substring(0, getParent?.name.indexOf(" "))
      : getParent?.name + "_"
  );
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [accomodation, setAccomodation] = useState("");
  const [color, setColor] = useState("");
  const [desc, setDesc] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const clearFields = () => {
    console.log("called");
    setName("");
    setUserName("");
    setAge("");
    setGender("");
    setAccomodation("");
    setColor("");
    setDesc("");
  };

  const [taskDialog, setTaskDialog] = useState(false);

  const dispatch = useDispatch();

  const { taskLoading, taskData, updateTask } = useSelector(
    (state) => state.task
  );

  const { getAssigneeData, getByIdData, createAssiLoading, succ } = useSelector(
    (state) => state.assignee
  );

  useEffect(() => {
    if (succ) {
      setTaskDialog(true);
    }
  }, [succ]);

  useEffect(() => {
    dispatch(getAssignee());
    dispatch(getParentbyId(loginData?.id));
  }, [createAssiLoading]);

  const [t, sett] = useState([]);

  const handleTask = (id) => {
    const dt = tasks.filter((x) => x.type === id);
    sett(dt);
    settaskType(id);
  };

  const [filters, setFilters] = useState("");

  const [child, setchild] = useState("");

  const [dataUri, setDataUri] = useState(null);
  const [URL, setURL] = useState("");

  const onImageChange = (file) => {
    if (!file) {
      setDataUri("");
      return;
    }
    if (file.type === "image/png" || file.type === "image/jpeg") {
      fileToDataUri(file).then((dataUri) => {
        setDataUri(dataUri);
        dataURItoBlob(file);
      });
    } else {
      console.log("Please select only png/jpeg format of image.");
    }
  };
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    axios
      .post(`${BASE_URL}/api/aws/file?email=${getParent.email}`, formData)
      .then((res) => {
        setURL(res.data.url);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("image", err);
      });
  }

  const [taskStatus, setTaskStatus] = useState(false);
  const [taskId, settaskId] = useState("");
  const [updatedStatus, setupdatedStatus] = useState("");

  const [startDate, setStartDate] = useState(
    moment(current).subtract(1, "M").format("MM-DD-YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(current).add(1, "M").format("MM-DD-YYYY")
  );

  useEffect(() => {
    dispatch(getTaskHistory(startDate, endDate));
  }, []);

  useEffect(() => {
    dispatch(getTask());
    dispatch(getFilteredTask());
    dispatch(getTaskByStatus());
  }, [updateTask]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data, e) => {
    dispatch(
      createAssignee(
        name.toLowerCase(),
        age,
        loginData?.id,
        gender,
        color,
        desc,
        URL,
        userName,
        password
      )
    );
  };

  const navigate = useNavigate();

  const [expiry, setExpiry] = useState(false);

  const [getById, setgetById] = useState("");

  let menuBar = document.getElementById("menuBar");
  let mainBar = document.getElementById("mainBar");

  function toggleBar() {
    menuBar.classList.toggle("open-bar");
    mainBar.classList.toggle("open-bar2");
  }

  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const [taskImage, setTaskImage] = useState("");

  const isNonMobile = useMediaQuery("(min-width: 960px)");
  const isMdMobile = useMediaQuery("(min-width: 760px)");

  const passwordRegex = /^(?=.*\d).{6,}$/;

  const [updateTaskDrawer, setUpdateTaskDrawer] = useState(false);
  const [task, settask] = useState("");
  const [taskType, settaskType] = useState("");
  const [taskName, settaskName] = useState("");
  const [date, setdate] = useState("");
  const [time, settime] = useState("");
  const [timeAllowed, settimeAllowed] = useState("");
  const [taskDesc, settaskDesc] = useState("");
  const [imag, setimag] = useState("");
  const [updatetaskId, setupdatetaskId] = useState("");
  const [updateMonth, setupdateMonth] = useState("");

  const [deleteDialog, setdeleteDialog] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteTaskId, setDeleteTaskId] = useState("");

  const [taskStatusDialog, setTaskStatusDialog] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const Update = (
    id,
    name,
    type,
    task,
    time,
    timeAllow,
    desc,
    date,
    month,
    image,
    sub_status,
    index
  ) => {
    let token = localStorage.getItem("token");

    let status = [];
    status.push(...sub_status);
    status.splice(index, 1, "completed");

    setUpdateLoading(true);
    axios
      .put(
        `${BASE_URL}/api/task/updateTask`,
        {
          id: id,
          name: name,
          type: type,
          task: task,
          time: time,
          timeAllowed: timeAllow,
          description: desc,
          date: date,
          month: month,
          image: image,
          sub_status: status,
        },
        {
          headers: {
            Authorization: `token ${JSON.parse(token)}`,
          },
        }
      )
      .then((res) => {
        setTaskStatusDialog(true);
        setUpdateLoading(false);
        if (status.every((e) => e === "completed")) {
          setTaskStatus(false);
          dispatch(updateTaskStatus(id, "completed"));
        }
      })
      .catch((e) => {
        console.log(`update task error ${e}`);
        setUpdateLoading(false);
      });
  };

  return (
    <html lang="en">
      <Header />
      <div class="sidebar" id="menuBar" style={{ zIndex: "999" }}>
        <SideDrawer />
      </div>
      <GreenDialogModal
        value={taskStatusDialog}
        setValue={() => setTaskStatusDialog(false)}
        title="Confirmation"
        subtitle="Task Updated Successfully"
        onClick={() => {
          setTaskStatusDialog(false);
          dispatch(clearSuccess());
          dispatch(getTask());
          dispatch(getFilteredTask());
          dispatch(
            getTaskHistory(
              moment(current).subtract(1, "M").format("MM-DD-YYYY"),
              moment(current).add(1, "M").format("MM-DD-YYYY")
            )
          );
        }}
      />
      <div className="main" id="mainBar">
        <Banner />
        <HeaderComp title={"Assignee"} toggle={toggleBar} />
        {isViewerOpen && (
          <ImageViewer
            src={[taskImage]}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
        <main>
          <div className="page2">
            <TaskComponent
              setOpenViewChildren={setOpenViewChildren}
              setOpenAddNewTask={setOpenAddNewTask}
              setchild={setchild}
              setgetById={setgetById}
            />

            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={taskLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={updateLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Drawer
              anchor={"right"}
              open={openAddNewTask}
              onClose={() => setOpenAddNewTask(false)}
            >
              <NewTas
                setOpenAddNewTask={setOpenAddNewTask}
                assignedToId={child}
              />
            </Drawer>

            <div
              className="blank-crd"
              id="crd3"
              onClick={() => {
                {
                  getParent?.maxAssignee === null
                    ? setExpiry(true)
                    : moment(getParent?.expiryDate).format("YYYY-MM-DD") <=
                      moment(current).format("YYYY-MM-DD")
                    ? setExpiry(true)
                    : getParent?.maxAssignee > getAssigneeData?.length
                    ? setOpen(true)
                    : setExpiry(true);
                }
              }}
            >
              <span id="addNew" className="material-symbols-outlined">
                person_add
              </span>
              <h5 id="addNew">Add Assignee</h5>
            </div>
          </div>
          <RedDialogModal
            value={expiry}
            setValue={() => setExpiry(false)}
            title="Package"
            subtitle={
              getParent?.maxAssignee === null
                ? "Please subscribe to a package to create an assignee"
                : getParent?.maxAssignee === getAssigneeData?.length
                ? "You have created maximum number of assignees. Please upgrade your package."
                : "Your package has been expired. Please renew to continue."
            }
            onClick={() => {
              setExpiry(false);
              navigate("/packages");
            }}
            width="30%"
            buttonText="Buy a package"
          />
          {/* <Dialog open={expiry} onClose={() => setExpiry(false)}>
            <DialogTitle>Package</DialogTitle>
            <div>
              <DialogContent>
                <DialogContentText>
                  {getParent.maxAssignee === null
                    ? "Please subscribe to a package to create an assignee"
                    : getParent.maxAssignee === getAssigneeData.length
                    ? "You have created maximum number of assignees. Please upgrade your package."
                    : "Your package has been expired. Please renew to continue."}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setExpiry(false);
                    navigate("/packages");
                  }}
                >
                  Okay
                </Button>
              </DialogActions>
            </div>
          </Dialog> */}
          <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
            <Box
              width="700px"
              style={{
                padding: 0,
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={createAssiLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <GreenDialogModal
                value={taskDialog}
                setValue={() => setTaskDialog(false)}
                title="Confirmation"
                subtitle="Assignee Created Successfully"
                onClick={() => {
                  setTaskDialog(false);
                  dispatch(clearSuccAss());
                  setOpen(false);

                  setName("");
                  setUserName("");
                  setAge("");
                  setGender("");
                  setAccomodation("");
                  setColor("");
                  setDesc("");

                  navigate(0);
                }}
              />

              <div className="popup" id="myForm">
                <div className="children">
                  <div className="heading">
                    <h5>Assignee</h5>
                    <h3>Create New Assignee</h3>
                  </div>
                  <button
                    className="close-btn"
                    id="close-modal"
                    onClick={() => setOpen(false)}
                  >
                    X
                  </button>
                </div>
                <hr />
                <div className="form-element">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      {dataUri ? (
                        <img
                          src={dataUri}
                          width="150px"
                          height="150px"
                          style={{ borderRadius: "75px" }}
                        />
                      ) : (
                        <img
                          src={require("../../images/user.png")}
                          width="150px"
                          height="150px"
                          style={{ borderRadius: "75px" }}
                        />
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 10,
                      marginBottom: 0,
                    }}
                  >
                    <input
                      accept="image/*"
                      type="file"
                      id="select-image"
                      style={{ display: "none" }}
                      onChange={(event) =>
                        onImageChange(event.target.files[0] || null)
                      }
                    />
                    <label htmlFor="select-image">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Add Avatar
                      </Button>
                    </label>
                  </div>
                  <div className="element">
                    <label for="name">
                      Full Name<i>*</i>
                      <br />
                      <span id="mins">e.g. William Marshal</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      {...register("name", { required: true })}
                      placeholder="Enter name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div className="element">
                    <label for="name">
                      Username<i>*</i>
                      <br />
                      <span id="mins">e.g. Marshal_killian</span>
                    </label>

                    <input
                      type="text"
                      id="name"
                      name="username"
                      {...register("username", { required: true })}
                      placeholder="Enter username"
                      required
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>

                  <div className="element">
                    <label for="name">
                      Password<i>*</i>
                      <br />
                    </label>

                    <div style={{ width: "60%" }}>
                      <div>
                        {errors?.password?.type === "required" && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "10px",
                              alignSelf: "flex-start",
                              paddingBottom: "8px",
                            }}
                          >
                            *Password is required
                          </p>
                        )}
                        {errors?.password?.type === "minLength" && (
                          <p
                            style={{
                              maxWidth: "95%",
                              color: "red",
                              fontSize: "10px",
                              alignSelf: "flex-start",
                              paddingBottom: "8px",
                              textAlign: "left",
                            }}
                          >
                            *Password must contain at least 6 characters and 1
                            number
                          </p>
                        )}
                        {errors?.password?.type === "pattern" && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "10px",
                              alignSelf: "flex-start",
                              paddingBottom: "8px",
                              textAlign: "left",
                            }}
                          >
                            *Password must contain at least 6 characters and 1
                            number
                          </p>
                        )}
                      </div>
                      <TextField
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        {...register("password", {
                          required: true,
                          pattern: passwordRegex,
                        })}
                        sx={{
                          padding: "0px",
                          backgroundColor: "#edf1f4",
                          borderRadius: "6px",
                          "& fieldset": { border: "none" },
                          fontFamily: "inherit",
                          fontWeight: "inherit",
                        }}
                        value={password}
                        onChange={(e) => setpassword(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                style={{ marginLeft: "50px" }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                  <div className="element">
                    <label for="task">
                      Age<i>*</i>
                      <br />
                      <span id="mins">e.g. 14 years old</span>
                    </label>
                    <input
                      type="number"
                      list="age"
                      name="age"
                      id="choose"
                      {...register("age", { required: true })}
                      placeholder="Enter or choose age"
                      required
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                    />
                    <datalist id="age">
                      <option value="10" />
                      <option value="12" />
                      <option value="13" />
                      <option value="14" />
                      <option value="15" />
                    </datalist>
                  </div>
                  <div className="element">
                    <label for="task">
                      Gender<i>*</i>
                      <br />
                      <span id="mins">e.g. Male/Female/others</span>
                    </label>
                    {/* <input
                      list="gender"
                      name="gender"
                      id="choose"
                      {...register("gender", { required: true })}
                      placeholder="Enter or choose gender"
                      required
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <datalist id="gender">
                      <option value="Male" />
                      <option value="Female" />
                      <option value="Others" />
                    </datalist> */}

                    <Select
                      value={gender}
                      defaultValue="none"
                      onChange={(e) => setGender(e.target.value)}
                      label="Gender"
                      // name="gender"
                      // {...register("gender", { required: true })}
                      sx={{
                        width: "60%",
                        padding: "0px",
                        backgroundColor: "#edf1f4",
                        borderRadius: "6px",
                        "& fieldset": { border: "none" },
                        fontWeight: "inherit",
                        fontSize: "inherit",
                        fontFamily: "inherit",
                        textTransform: "none",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled style={{ color: "#757575" }}>
                        <>Select gender</>
                      </MenuItem>
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                      <MenuItem value={"Others"}>Others</MenuItem>
                    </Select>
                  </div>
                  {/* <div className="element">
                    <label for="task">
                      Need accommodation(s)?
                      <br />
                      <span id="mins">e.g. Physical/Intellectual/Mental</span>
                    </label>
                    
                    <Select
                      value={accomodation}
                      onChange={(e) => setAccomodation(e.target.value)}
                      label="Accomodation"
                      sx={{
                        width: "60%",
                        padding: "0px",
                        backgroundColor: "#edf1f4",
                        borderRadius: "6px",
                        "& fieldset": { border: "none" },
                        fontWeight: "inherit",
                        fontSize: "inherit",
                        fontFamily: "inherit",
                        textTransform: "none",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled style={{ color: "#757575" }}>
                        <>Choose Need accommodation(s) if any</>
                      </MenuItem>
                      <MenuItem value={"Physical"}>Physical</MenuItem>
                      <MenuItem value={"Intellectual"}>Intellectual</MenuItem>
                      <MenuItem value={"Mental"}>Mental</MenuItem>
                    </Select>
                  </div> */}
                  <div className="element">
                    <label for="task">
                      Color tab<i>*</i>
                      <br />
                      <span id="mins">e.g. Red, Green, Orange</span>
                    </label>
                    {/* <input
                      list="color"
                      name="color"
                      id="choose"
                      {...register("color", { required: true })}
                      placeholder="Choose your color"
                      required
                      onChange={(e) => setColor(e.target.value)}
                    />
                    <datalist id="color">
                      <option value="Red" />
                      <option value="Yellow" />
                      <option value="Brown" />
                      <option value="White" />
                      <option value="Black" />
                    </datalist> */}
                    <Select
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                      label="Color"
                      sx={{
                        width: "60%",
                        padding: "0px",
                        backgroundColor: "#edf1f4",
                        borderRadius: "6px",
                        "& fieldset": { border: "none" },
                        fontWeight: "inherit",
                        fontSize: "inherit",
                        fontFamily: "inherit",
                        textTransform: "none",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled style={{ color: "#757575" }}>
                        <>Choose assignee color</>
                      </MenuItem>
                      <MenuItem value={"Red"}>Red</MenuItem>
                      <MenuItem value={"Yellow"}>Yellow</MenuItem>
                      <MenuItem value={"Brown"}>Brown</MenuItem>
                      <MenuItem value={"Black"}>Black</MenuItem>
                      <MenuItem value={"White"}>White</MenuItem>
                    </Select>
                  </div>
                  {/* <div className="element">
                    <label for="describe">
                      Description
                      <br />
                      <span id="mins">e.g. about assignee</span>
                    </label>
                    <textarea
                      id="describe"
                      placeholder="Write Here..."
                      name="desc"
                      rows="4"
                      maxlength="150"
                      onChange={(e) => setDesc(e.target.value)}
                    ></textarea>
                  </div> */}
                </div>
                <hr id="hr" />
                <div className="bttns">
                  <button className="bttn" onClick={() => setOpen(false)}>
                    Cancel
                  </button>
                  <button className="bttn" onClick={onSubmit}>
                    Create
                  </button>
                </div>
              </div>
            </Box>
          </Drawer>

          <Drawer
            anchor={"right"}
            open={openViewChildren}
            onClose={() => setOpenViewChildren(false)}
          >
            <Box
              width={isNonMobile ? "900px" : isMdMobile ? "600px" : "350px"}
              style={{
                padding: 0,
                height: "100%",
                overflow: "hidden",
              }}
            >
              <div className="popup" id="myForm">
                <Dialog open={taskStatus} onClose={() => setTaskStatus(false)}>
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Set task as completed?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setTaskStatus(false);
                        dispatch(updateTaskStatus(taskId, updatedStatus));
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() => {
                        setTaskStatus(false);
                      }}
                    >
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
                <div className="children">
                  <div className="heading">
                    <h5>Assignee</h5>
                    <h3>View assignee detail</h3>
                  </div>
                  <button
                    className="close-btn"
                    id="close-modal2"
                    onClick={() => {
                      setOpenViewChildren(false);
                    }}
                  >
                    X
                  </button>
                </div>
                <hr />
                <div
                  className="view-assign"
                  style={{ display: !isNonMobile && "inherit" }}
                >
                  <div className="image-col">
                    {getByIdData?.image ? (
                      <img
                        src={getByIdData?.image}
                        alt=""
                        width={isNonMobile ? "150px" : "100px"}
                        height={isNonMobile ? "150px" : "100px"}
                        style={{
                          borderRadius: "360px",
                        }}
                      />
                    ) : (
                      <img
                        src={require("../../images/user.png")}
                        alt=""
                        width="150"
                        height="150"
                        style={{ borderRadius: "360px" }}
                      />
                    )}
                  </div>
                  <div className="content-col">
                    <div className="name-butons">
                      <div className="name">
                        <h3
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "inherit",
                          }}
                        >
                          {getByIdData?.name}
                          <span
                            style={{
                              backgroundColor:
                                getByIdData?.color.toLocaleLowerCase() ===
                                "white"
                                  ? "#FBFCF8"
                                  : getByIdData?.color.toLocaleLowerCase() ===
                                    "yellow"
                                  ? "#e5e500"
                                  : getByIdData?.color,
                              color:
                                getByIdData?.color.toLocaleLowerCase() ===
                                "white"
                                  ? "black"
                                  : "white",
                              border:
                                getByIdData?.color.toLocaleLowerCase() ===
                                "white"
                                  ? "1px solid black"
                                  : `0px solid ${getByIdData?.color}`,
                              fontSize: "0.6rem",
                            }}
                          >
                            ASSIGNEE #{getById}
                          </span>
                        </h3>
                        <h4
                          style={{
                            fontWeight: "100",
                            fontFamily: "inherit",
                            fontSize: "18px",
                          }}
                        >
                          Age: {getByIdData?.age} years old
                        </h4>
                        <h5 style={{ marginTop: "5px", color: "#000" }}>
                          Username: {getByIdData?.userName}
                        </h5>
                      </div>
                      <div className="butons">
                        <button className="bton" id="buton1">
                          <Link
                            to={"/chat"}
                            style={{
                              color: "#000",
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <i className="material-symbols-outlined">chat</i>{" "}
                            Chat
                          </Link>
                        </button>
                        {/* <button id="buton1">
                            <i className="material-symbols-outlined">chat</i>{" "}
                            Chat
                          </button> */}
                      </div>
                    </div>
                    {/* <p id="para">{getByIdData?.description}</p> */}
                    <hr />
                    <div className="microcards1">
                      <p
                        id="p4"
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            filters === "4" ? "#a6a6a6" : "#e5e5e5",
                        }}
                        onClickCapture={() => setFilters("4")}
                      >
                        Assigned
                      </p>
                      <p
                        id="p1"
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            filters === "1" ? "#ffe0a8" : "#fcf2e0",
                        }}
                        onClickCapture={() => setFilters("1")}
                      >
                        Working
                      </p>
                      <p
                        id="p2"
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            filters === "2" ? "#f8a894" : "#FDE5DF",
                        }}
                        onClickCapture={() => setFilters("2")}
                      >
                        Stopped
                      </p>
                      <p
                        id="p3"
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            filters === "3" ? "#afffa8" : "#DDF5DB",
                        }}
                        onClickCapture={() => setFilters("3")}
                      >
                        Completed
                      </p>
                    </div>
                    <div
                      className="grid2"
                      style={{
                        display: "flex",
                        gridTemplateColumns: "repeat(2, 1fr)",
                      }}
                    >
                      <div className="minicards" style={{ width: "100%" }}>
                        {taskData?.map((ctr, ind) => {
                          return (
                            ctr.assignedToId === getByIdData?.id && (
                              <>
                                {filters === "" && (
                                  <AccordionComponent
                                    ctr={ctr}
                                    Update={Update}
                                    setUpdateTaskDrawer={setUpdateTaskDrawer}
                                    settask={settask}
                                    settaskType={settaskType}
                                    settaskName={settaskName}
                                    settime={settime}
                                    settimeAllowed={settimeAllowed}
                                    setdate={setdate}
                                    taskDesc={taskDesc}
                                    setimag={setimag}
                                    setupdatetaskId={setupdatetaskId}
                                    setupdateMonth={setupdateMonth}
                                    setdeleteDialog={setdeleteDialog}
                                    setDeleteTaskId={setDeleteTaskId}
                                    detail={true}
                                    setIsViewerOpen={setIsViewerOpen}
                                    setTaskImage={setTaskImage}
                                    setTaskStatus={setTaskStatus}
                                    settaskId={settaskId}
                                    setupdatedStatus={setupdatedStatus}
                                  />
                                  // <Accordion
                                  //   className="player"
                                  //   id={
                                  //     ctr.status === "pending"
                                  //       ? "c3"
                                  //       : ctr.status === "stopped"
                                  //       ? "c2"
                                  //       : ctr.status === "completed"
                                  //       ? "c1"
                                  //       : ctr.status === "assigned" && "c4"
                                  //   }
                                  //   defaultExpanded={ind === 0 ? true : false}
                                  // >
                                  //   <AccordionSummary>
                                  //     <div
                                  //       style={{
                                  //         width: "100%",
                                  //         display: "flex",
                                  //         flexDirection: "row",
                                  //         justifyContent: "space-between",
                                  //       }}
                                  //     >
                                  //       {ctr.task && (
                                  //         <div>
                                  //           {ctr.task.map((title) => (
                                  //             <div
                                  //               style={{
                                  //                 display: "flex",
                                  //                 flexDirection: "row",
                                  //                 alignItems: "center",
                                  //                 marginBottom: "15px",
                                  //               }}
                                  //             >
                                  //               {tasks?.map((i) => {
                                  //                 return (
                                  //                   i.task === title &&
                                  //                   i.type === ctr.type && (
                                  //                     <img
                                  //                       src={i.icon}
                                  //                       height={"22px"}
                                  //                       width={"22px"}
                                  //                       alt=""
                                  //                     />
                                  //                   )
                                  //                 );
                                  //               })}{" "}
                                  //               <h4
                                  //                 style={{ marginLeft: "10px" }}
                                  //               >
                                  //                 {title}
                                  //               </h4>
                                  //             </div>
                                  //           ))}
                                  //         </div>
                                  //       )}
                                  //       <div
                                  //         style={{
                                  //           float: "right",
                                  //         }}
                                  //       >
                                  //         {ctr?.time && (
                                  //           <span
                                  //             className="time"
                                  //             style={{ color: "white" }}
                                  //           >
                                  //             {ctr?.timeAllowed}
                                  //             {" mins"}
                                  //           </span>
                                  //         )}
                                  //       </div>
                                  //     </div>
                                  //   </AccordionSummary>
                                  //   <AccordionDetails>
                                  //     <p>{ctr.description}</p>
                                  //     <div className="span">
                                  //       <Link
                                  //         to="/chat"
                                  //         style={{ color: "white" }}
                                  //         onMouseOver={({ target }) =>
                                  //           (target.style.color =
                                  //             ctr.status === "pending"
                                  //               ? "#8d5c0a"
                                  //               : ctr.status === "stopped"
                                  //               ? "#a73215"
                                  //               : ctr.status === "completed"
                                  //               ? "#0a7f00"
                                  //               : "#a6a6a6")
                                  //         }
                                  //         onMouseOut={({ target }) =>
                                  //           (target.style.color = "white")
                                  //         }
                                  //       >
                                  //         <span className="material-symbols-outlined">
                                  //           chat
                                  //         </span>
                                  //       </Link>
                                  //       {/* <span
                                  //         className="material-symbols-outlined"
                                  //         onMouseOver={({ target }) =>
                                  //           (target.style.color =
                                  //             ctr.status === "pending"
                                  //               ? "#8d5c0a"
                                  //               : ctr.status === "stopped"
                                  //               ? "#a73215"
                                  //               : ctr.status === "completed"
                                  //               ? "#0a7f00"
                                  //               : "#a6a6a6")
                                  //         }
                                  //         onMouseOut={({ target }) =>
                                  //           (target.style.color = "white")
                                  //         }
                                  //         onClick={() => {
                                  //           setIsViewerOpen(true);
                                  //           setOpenViewChildren(false);
                                  //           setTaskImage(ctr?.image);
                                  //         }}
                                  //       >
                                  //         attachment
                                  //       </span> */}
                                  //       {/* <span
                                  //         className="material-symbols-outlined"
                                  //         onClick={() => {
                                  //           setTaskStatus(true);
                                  //           settaskId(ctr?.id);
                                  //           setupdatedStatus("completed");
                                  //         }}
                                  //         onMouseOver={({ target }) =>
                                  //           (target.style.color =
                                  //             ctr.status === "pending"
                                  //               ? "#8d5c0a"
                                  //               : ctr.status === "stopped"
                                  //               ? "#a73215"
                                  //               : ctr.status === "completed"
                                  //               ? "#0a7f00"
                                  //               : "#a6a6a6")
                                  //         }
                                  //         onMouseOut={({ target }) =>
                                  //           (target.style.color = "white")
                                  //         }
                                  //       >
                                  //         task_alt
                                  //       </span> */}
                                  //     </div>
                                  //     {ctr.image && (
                                  //       <div
                                  //         style={{
                                  //           padding: "8px",
                                  //           cursor: "pointer",
                                  //         }}
                                  //         onClick={() => {
                                  //           setIsViewerOpen(true);
                                  //           setTaskImage(ctr.image);
                                  //         }}
                                  //       >
                                  //         <img
                                  //           src={ctr.image}
                                  //           height={"50%"}
                                  //           width={"100%"}
                                  //           style={{ borderRadius: "8px" }}
                                  //           alt=""
                                  //         />
                                  //       </div>
                                  //     )}
                                  //   </AccordionDetails>
                                  // </Accordion>
                                )}
                                {filters === "1" &&
                                  ctr.status === "pending" && (
                                    <Accordion
                                      className="player"
                                      id="c3"
                                      defaultExpanded={ind === 0 ? true : false}
                                    >
                                      <AccordionSummary>
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {ctr.task && (
                                            <div>
                                              {ctr?.task?.map((title) => (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    marginBottom: "15px",
                                                  }}
                                                >
                                                  {tasks?.map((i) => {
                                                    return (
                                                      i.task === title &&
                                                      i.type === ctr.type && (
                                                        <img
                                                          src={i.icon}
                                                          height={"22px"}
                                                          width={"22px"}
                                                          alt=""
                                                        />
                                                      )
                                                    );
                                                  })}{" "}
                                                  <h4
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    {title}
                                                  </h4>
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                          <div
                                            style={{
                                              float: "right",
                                            }}
                                          >
                                            {ctr?.time && (
                                              <span
                                                className="time"
                                                style={{ color: "white" }}
                                              >
                                                {ctr?.timeAllowed}
                                                {" mins"}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <p>{ctr.description}</p>
                                        <div className="span">
                                          <Link
                                            to="/chat"
                                            style={{ color: "white" }}
                                            onMouseOver={({ target }) =>
                                              (target.style.color = "#8d5c0a")
                                            }
                                            onMouseOut={({ target }) =>
                                              (target.style.color = "white")
                                            }
                                          >
                                            <span className="material-symbols-outlined">
                                              chat
                                            </span>
                                          </Link>
                                          {/* <span
                                            className="material-symbols-outlined"
                                            onMouseOver={({ target }) =>
                                              (target.style.color = "#8d5c0a")
                                            }
                                            onMouseOut={({ target }) =>
                                              (target.style.color = "white")
                                            }
                                            onClick={() => {
                                              setIsViewerOpen(true);
                                              setOpenViewChildren(false);
                                              setTaskImage(ctr?.image);
                                            }}
                                          >
                                            attachment
                                          </span> */}
                                          {/* <span
                                            className="material-symbols-outlined"
                                            onClick={() => {
                                              setTaskStatus(true);
                                              settaskId(ctr?.id);
                                              setupdatedStatus("completed");
                                            }}
                                            onMouseOver={({ target }) =>
                                              (target.style.color = "#8d5c0a")
                                            }
                                            onMouseOut={({ target }) =>
                                              (target.style.color = "white")
                                            }
                                          >
                                            task_alt
                                          </span> */}
                                        </div>
                                        {ctr.image && (
                                          <div
                                            style={{
                                              padding: "8px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setIsViewerOpen(true);
                                              setTaskImage(ctr.image);
                                            }}
                                          >
                                            <img
                                              src={ctr.image}
                                              height={"50%"}
                                              width={"100%"}
                                              style={{ borderRadius: "8px" }}
                                            />
                                          </div>
                                        )}
                                      </AccordionDetails>
                                    </Accordion>
                                  )}

                                {filters === "2" &&
                                  ctr.status === "stopped" && (
                                    <Accordion
                                      className="player"
                                      id="c2"
                                      defaultExpanded={ind === 0 ? true : false}
                                    >
                                      <AccordionSummary>
                                        <h4>
                                          {tasks?.map((i) => {
                                            return (
                                              i.task === ctr.task &&
                                              i.type === ctr.type && (
                                                <img
                                                  src={i.icon}
                                                  height={"22px"}
                                                  width={"22px"}
                                                />
                                              )
                                            );
                                          })}{" "}
                                          {ctr.task === "Other"
                                            ? ctr.name
                                            : ctr.task}{" "}
                                          {ctr.time && (
                                            <span className="time">
                                              {ctr.timeAllowed}
                                            </span>
                                          )}
                                        </h4>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <p>{ctr.description}</p>
                                        <div className="span">
                                          <span
                                            className="material-symbols-outlined"
                                            onMouseOver={({ target }) =>
                                              (target.style.color = "#a73215")
                                            }
                                            onMouseOut={({ target }) =>
                                              (target.style.color = "white")
                                            }
                                          >
                                            chat
                                          </span>
                                          {/* <span
                                            className="material-symbols-outlined"
                                            onMouseOver={({ target }) =>
                                              (target.style.color = "#a73215")
                                            }
                                            onMouseOut={({ target }) =>
                                              (target.style.color = "white")
                                            }
                                            onClick={() => {
                                              setIsViewerOpen(true);
                                              setOpenViewChildren(false);
                                              setTaskImage(ctr?.image);
                                            }}
                                          >
                                            attachment
                                          </span> */}
                                        </div>
                                        {ctr.image && (
                                          <div
                                            style={{
                                              padding: "8px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setIsViewerOpen(true);
                                              setTaskImage(ctr.image);
                                            }}
                                          >
                                            <img
                                              src={ctr.image}
                                              height={"50%"}
                                              width={"100%"}
                                              style={{ borderRadius: "8px" }}
                                            />
                                          </div>
                                        )}
                                      </AccordionDetails>
                                    </Accordion>
                                  )}
                                {filters === "3" &&
                                  ctr.status === "completed" && (
                                    <Accordion
                                      className="player"
                                      id="c1"
                                      defaultExpanded={ind === 0 ? true : false}
                                    >
                                      <AccordionSummary>
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {ctr?.task && (
                                            <div>
                                              {ctr?.task?.map((title) => (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    marginBottom: "15px",
                                                  }}
                                                >
                                                  {tasks?.map((i) => {
                                                    return (
                                                      i.task === title &&
                                                      i.type === ctr.type && (
                                                        <img
                                                          src={i.icon}
                                                          height={"22px"}
                                                          width={"22px"}
                                                          alt=""
                                                        />
                                                      )
                                                    );
                                                  })}{" "}
                                                  <h4
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    {title}
                                                  </h4>
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                          <div
                                            style={{
                                              float: "right",
                                            }}
                                          >
                                            {ctr?.time && (
                                              <span
                                                className="time"
                                                style={{ color: "white" }}
                                              >
                                                {ctr?.timeAllowed}
                                                {" mins"}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <p>{ctr.description}</p>
                                        <div className="span">
                                          <span
                                            className="material-symbols-outlined"
                                            onMouseOver={({ target }) =>
                                              (target.style.color = "#0a7f00")
                                            }
                                            onMouseOut={({ target }) =>
                                              (target.style.color = "white")
                                            }
                                          >
                                            chat
                                          </span>
                                          {/* <span
                                            className="material-symbols-outlined"
                                            onMouseOver={({ target }) =>
                                              (target.style.color = "#0a7f00")
                                            }
                                            onMouseOut={({ target }) =>
                                              (target.style.color = "white")
                                            }
                                            onClick={() => {
                                              setIsViewerOpen(true);
                                              setOpenViewChildren(false);
                                              setTaskImage(ctr?.image);
                                            }}
                                          >
                                            attachment
                                          </span> */}
                                        </div>
                                        {ctr.image && (
                                          <div
                                            style={{
                                              padding: "8px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setIsViewerOpen(true);
                                              setTaskImage(ctr.image);
                                            }}
                                          >
                                            <img
                                              src={ctr.image}
                                              height={"50%"}
                                              width={"100%"}
                                              style={{ borderRadius: "8px" }}
                                            />
                                          </div>
                                        )}
                                      </AccordionDetails>
                                    </Accordion>
                                  )}
                                {filters === "4" &&
                                  ctr.status === "assigned" && (
                                    <Accordion
                                      className="player"
                                      id="c4"
                                      defaultExpanded={ind === 0 ? true : false}
                                    >
                                      <AccordionSummary>
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {ctr?.task && (
                                            <div>
                                              {ctr?.task?.map((title) => (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    marginBottom: "15px",
                                                  }}
                                                >
                                                  {tasks?.map((i) => {
                                                    return (
                                                      i.task === title &&
                                                      i.type === ctr.type && (
                                                        <img
                                                          src={i.icon}
                                                          height={"22px"}
                                                          width={"22px"}
                                                          alt=""
                                                        />
                                                      )
                                                    );
                                                  })}{" "}
                                                  <h4
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    {title}
                                                  </h4>
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                          <div
                                            style={{
                                              float: "right",
                                            }}
                                          >
                                            {ctr?.time && (
                                              <span
                                                className="time"
                                                style={{ color: "white" }}
                                              >
                                                {ctr?.timeAllowed}
                                                {" mins"}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <p>{ctr.description}</p>
                                        <div className="span">
                                          <span
                                            className="material-symbols-outlined"
                                            onMouseOver={({ target }) =>
                                              (target.style.color = "#a6a6a6")
                                            }
                                            onMouseOut={({ target }) =>
                                              (target.style.color = "white")
                                            }
                                          >
                                            chat
                                          </span>
                                          {/* <span
                                            className="material-symbols-outlined"
                                            onClick={() => {
                                              setTaskStatus(true);
                                              settaskId(ctr?.id);
                                              setupdatedStatus("pending");
                                            }}
                                            onMouseOver={({ target }) =>
                                              (target.style.color =
                                                ctr.status === "pending"
                                                  ? "#8d5c0a"
                                                  : ctr.status === "stopped"
                                                  ? "#a73215"
                                                  : ctr.status === "completed"
                                                  ? "#0a7f00"
                                                  : "#a6a6a6")
                                            }
                                            onMouseOut={({ target }) =>
                                              (target.style.color = "white")
                                            }
                                          >
                                            task_alt
                                          </span> */}
                                        </div>
                                        {ctr.image && (
                                          <div
                                            style={{
                                              padding: "8px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setIsViewerOpen(true);
                                              setTaskImage(ctr.image);
                                            }}
                                          >
                                            <img
                                              src={ctr.image}
                                              height={"50%"}
                                              width={"100%"}
                                              style={{ borderRadius: "8px" }}
                                            />
                                          </div>
                                        )}
                                      </AccordionDetails>
                                    </Accordion>
                                  )}
                              </>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Drawer>
        </main>
      </div>
    </html>
  );
}
