import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MenuItem, Menu, Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../constants/config";
import { GreenDialogModal } from "../components/DialogModal";
import moment from "moment";

export default function LoraWebPage() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., send data to a server)
    ContactUs();
  };

  const [loading, setloading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const ContactUs = async () => {
    setloading(true);
    setConfirm(false);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/contactus/createContactUs`,
        {
          message: formData.message,
          email: formData.email,
          phone: formData.phone,
          name: formData.name,
          subject: formData.subject,
        }
      );
      if (response.status === 200) {
        setConfirm(true);
        setloading(false);
      }
    } catch (error) {
      setConfirm(false);
      setloading(false);
    }
  };

  return (
    <div>
      <div className="mobile-nav">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <GreenDialogModal
          value={confirm}
          setValue={setConfirm}
          onClick={() => {
            setConfirm(false);
            setFormData({
              name: "",
              email: "",
              phone: "",
              message: "",
              subject: "",
            });
          }}
          title="Success!"
          subtitle="You will be contacted soon."
        />
        <a href="/" className="logo-main">
          <img src="images/logo.png" alt="*" />
        </a>
        <nav>
          <ul className="unstyled mainnav pbpx-15">
            <li>
              <a href="#">Mission</a>
            </li>
            <li>
              <a href="#">How To Use</a>
            </li>
            <li>
              <a href="#">Share</a>
            </li>
            {/* <li>
              <a href="#">Contact Us</a>
            </li> */}
          </ul>
        </nav>
      </div>
      {/* <button
          onClick={() => {
            dispatch(setLogout());
            dispatch(clear());
          }}
        >
          log
        </button> */}
      <main className="app-container" style={{ padding: "0px" }}>
        {/* <!-- Mobile Navigation Button Start--> */}
        {/* <div className="mobile-nav-btn">
          <span className="lines"></span>
        </div> */}
        {/* <!-- Mobile Navigation Button End--> */}

        <section className="title-des">
          <div className="container">
            <div className="title-describtion">
              <p>
                A fun and easy way to communicate within a family unit or any
                kind of group setting and feel productive at the same time!
              </p>
            </div>
          </div>
        </section>
        <header className="header-main">
          <div className="nav-area-full">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 logo-area">
                  <div
                    className="logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <img
                      className="img-fluid"
                      src={require("../images/logo.png")}
                      alt="*"
                      width="140px"
                    />
                  </div>
                </div>
                <div className="col-lg-5 d-flex">
                  <div className="main-menu align-self-center d-none d-lg-block">
                    <ul>
                      <li>
                        <a href="#ab">Mission</a>
                      </li>
                      <li>
                        <a href="#vid">How To Use</a>
                      </li>
                      <li>
                        <a href="#pkg">Packages</a>
                      </li>
                      {/* <li>
                        <a href="#conn">Contact Us</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="ct-login-btn">
                    <Link onClick={handleClick}>Login</Link>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          navigate("/sign-in");
                        }}
                      >
                        Login as Assignor
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate("/sign-in-child");
                        }}
                      >
                        Login as Assignee
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="-ct-main-banner" id="lo">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="ct-main-txt">
                  <h2>ABOUT Ladder Of Remote Acces</h2>
                  <p>
                    A fun and easy-to-use platform by adults to communicate task
                    driven directives to minors in any setting. It can be used
                    for home, school, daycare, etc. We are here to help parents,
                    mentors, educators, tutors, grandparents, caretakers,
                    babysitters, etc. because this platform is not limited to a
                    specific group of people. If you work with or deal with a
                    child, teen, minor, we can help you help them build skills
                    they will need as they get older
                  </p>
                  <Link to={"/sign-in"}>Get Started</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="banner-img">
                  <img
                    src="images/banner-img.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ct-about-banner" id="ab">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="ct-about-txt">
                  {/* <h2>About</h2>
                  <p>
                    LORA is a communication system of demands between an
                    assigner (adult) and assignee(s) (minor). We want to service
                    not only family units but mentors, educators, caretakers,
                    and anyone who works with children or minors. We would also
                    most definitely love to support caretakers of an individual
                    with special needs.
                  </p> */}
                  <h2>MISSION</h2>
                  <p>
                    Our mission is to take some of the burden of being “in
                    charge” all the time. Parenting, mentoring, or educating a
                    minor can be very exhausting! We are here to help decrease
                    stressors of being a caretaker on any level and in any
                    setting. Let us join your team because it still takes a
                    village!
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ct-about-img">
                  <img
                    src="images/about_img.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <img src="images/cloud-img.png" className="cloud-img1" alt="" />
          <img src="images/cloud-img.png" className="cloud-img2" alt="" />
          <img src="images/star.png" className="star-img" alt="" />
        </section>

        <section className="video-play-sec" id="vid">
          <div className="container">
            <h2 className="title-heading">
              A Message for the Assignors (Adults)
            </h2>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="ct-play-video">
                <video
                  ref={videoRef}
                  width="100%"
                  height="100%"
                  controls
                  style={{ height: "30vw" }}
                >
                                  
                  <source
                    src="https://lorastorage.s3.us-east-2.amazonaws.com/-RenderMe+2.mp4"
                    type="video/mp4"
                  />
                              
                </video>
                {/* <img src="images/video-sec.png" className="img-fluid" alt="" /> */}
              </div>
            </div>
          </div>
          <img src="images/cloud-img.png" className="cloud-img1" alt="" />
          <img src="images/cloud-img.png" className="cloud-img2" alt="" />
        </section>

        <section className="pkgs" id="pkg">
          <div className="container">
            <h2 className="title-heading">LORA Packages Starts From</h2>
            <div className="pkgs-main">
              <div className="row">
                <div className="col-lg-6">
                  <div className="pgs-txt">
                    <span>
                      <i className="fa fa-users"></i>
                    </span>
                    <h3>
                      Full Assigner Access
                      <br />
                      With 1 Assignee
                    </h3>
                    <h4>$7.99</h4>
                    <p>(up to 1 assignees)</p>
                    <Link to={"/packages"}>Buy Now</Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="pkgs-img">
                    <img
                      src="images/pkgs-img.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="images/cloud-img.png" className="cloud-img1" alt="" />
          <img src="images/cloud-img.png" className="cloud-img2" alt="" />
        </section>

        <section className="get-connect" id="conn">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="ct-main-txt get-connet-txt">
                  <h2>Contact Us</h2>
                  <p>
                    Get everything you need to keep your kids safe online by
                    getting LORA now
                  </p>
                  {/* <Link to={"/sign-up"}>Sign Up</Link>
                  <br />
                  <br />
                  <span className="twiter-icon">
                    <i className="fa fa-twitter"></i>
                  </span>
                  <span className="insta-icon">
                    <i className="fa fa-instagram"></i>
                  </span>
                  <span className="face-icon">
                    <i className="fa fa-facebook"></i>
                  </span>
                  <span className="yout-icon">
                    <i className="fa fa-youtube-play"></i>
                  </span> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="banner-img get-connect-img"
                  style={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      placeholder="Name"
                      style={{
                        width: "100%",
                        height: "50px",
                        padding: "10px",
                        borderRadius: 8,
                        marginTop: "10px",
                        marginBottom: "10px",
                        border: "none",
                        borderBottom: "2px solid #ddd",
                      }}
                    />
                    <br />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      placeholder="Email"
                      style={{
                        width: "100%",
                        height: "50px",
                        padding: "10px",

                        borderRadius: 8,
                        marginTop: "10px",
                        marginBottom: "10px",
                        border: "none",
                        borderBottom: "2px solid #ddd",
                      }}
                    />
                    <br />
                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Phone"
                      style={{
                        width: "100%",
                        height: "50px",
                        padding: "10px",

                        borderRadius: 8,
                        marginTop: "10px",
                        marginBottom: "10px",
                        border: "none",
                        borderBottom: "2px solid #ddd",
                      }}
                    />
                    <br />
                    <input
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="Subject"
                      style={{
                        width: "100%",
                        height: "50px",
                        padding: "10px",

                        borderRadius: 8,
                        marginTop: "10px",
                        marginBottom: "10px",
                        border: "none",
                        borderBottom: "2px solid #ddd",
                      }}
                    />
                    <br />
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      placeholder="Message"
                      style={{
                        width: "100%",
                        height: "100px",
                        textAlignVertical: "top",
                        padding: "10px",
                        borderRadius: 8,
                        marginTop: "10px",
                        marginBottom: "10px",
                        border: "none",
                        borderBottom: "2px solid #ddd",
                      }}
                    />
                    <br />
                    <button
                      style={{
                        width: "100%",
                        padding: "15px",
                        borderRadius: 8,
                        backgroundColor: "#F1B24B",
                        border: "none",
                      }}
                      type="submit"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <img src="images/cloud-img.png" className="cloud-img1" />
          {/* <img src="images/cloud-img.png" className="cloud-img2" /> */}
        </section>

        <section
          className="ct-about-banner"
          style={{ backgroundColor: "#9ed732" }}
          id="founder"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 100px",
            }}
          >
            <div
              style={{
                flex: 1,
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={require("../images/founder.jpeg")}
                alt=""
                style={{ height: "400px", marginTop: "5px" }}
              />
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
              className="ct-about-txt"
            >
              <h2>Founder</h2>
              <p
                style={{
                  marginTop: "50px",
                }}
              >
                I'm a single mom of 3 children and 3 fur babies. I'm a certified
                Special Education Teacher, previous Behavior Interventionist,
                previous Behavior Technician and previous Mental Health
                Assistant.
              </p>
            </div>
          </div>
          <img src="images/cloud-img.png" className="cloud-img1" alt="" />
          <img src="images/cloud-img.png" className="cloud-img2" alt="" />
        </section>
        <footer>
          <div className="nav-area-full foort-nav">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 logo-area">
                  <div
                    className="logo"
                    onClick={() => {
                      navigate("");
                    }}
                  >
                    <img className="img-fluid" src="images/logo.png" alt="*" />
                  </div>
                </div>
                <div className="col-lg-6 d-flex">
                  <div className="main-menu align-self-center d-none d-lg-block">
                    <ul>
                      <li>
                        <a href="#lo">About</a>
                      </li>
                      <li>
                        <a href="#ab">Mission</a>
                      </li>
                      <li>
                        <a href="#vid">How To Use</a>
                      </li>
                      <li>
                        <a href="#pkg">Packages</a>
                      </li>
                      {/* <li>
                        <a href="#conn">Contact Us</a>
                      </li>
                      <li>
                        <a href="#founder">Founder</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="ct-login-btn">
                    <Link onClick={handleClick}>Login</Link>
                  </div>
                </div>
              </div>
              <div className="copy-rights">
                <p>© {moment().format("YYYY")} All Rights Reserved. Lora</p>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
}
