import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../constants/config";
import {
  clearSuccess,
  getFilteredTask,
  getTask,
  getTaskHistory,
  updateTaskStatus,
} from "../redux/dispatcher/tasks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
} from "@mui/material";
import ImageViewer from "react-simple-image-viewer";
import UpdateTask from "./UpdateTask";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";
import HideImageIcon from "@mui/icons-material/HideImage";
import { Link, useNavigate } from "react-router-dom";
import { tasks } from "../assets/data";
import { ConfirmationDialog, GreenDialogModal } from "./DialogModal";
import moment from "moment";
import { theme } from "../constants/theme";
import AccordionComponent from "./AccordionComponent";

// const AccordionComponent = ({
//   ctr,
//   setstateImage,
//   Update,
//   setUpdateTaskDrawer,
//   settask,
//   settaskType,
//   settaskName,
//   settime,
//   settimeAllowed,
//   setdate,
//   setdesc,
//   setimag,
//   setupdatetaskId,
//   setupdateMonth,
//   setdeleteDialog,
//   setDeleteTaskId,
//   viewImage,
//   setViewImage,
//   setIsViewerOpen,
//   setTaskImage,
//   setTaskStatus,
//   settaskId,
//   setupdatedStatus,
// }) => {
//   return (
//     <Accordion
//       className="player"
//       style={{
//         backgroundColor:
//           ctr.status === "pending"
//             ? theme.default.pending
//             : ctr.status === "stopped"
//             ? theme.default.stopped
//             : ctr.status === "completed"
//             ? theme.default.completed
//             : ctr.status === "assigned" && theme.default.assigned,
//       }}
//       defaultExpanded={true}
//     >
//       <AccordionSummary
//         style={{ minHeight: "5px", height: "10px" }}
//       ></AccordionSummary>
//       <AccordionDetails style={{ padding: 0 }}>
//         {ctr.task && (
//           <>
//             {ctr.task.map((title, index) => (
//               <Accordion
//                 id={
//                   ctr.sub_status[index] === "pending"
//                     ? "c3"
//                     : ctr.sub_status[index] === "stopped"
//                     ? "c2"
//                     : ctr.sub_status[index] === "completed"
//                     ? "c1"
//                     : ctr.sub_status[index] === "assigned" && "c4"
//                 }
//                 onClick={() => setstateImage(title.id)}
//               >
//                 <AccordionSummary>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "row",
//                       alignItems: "center",
//                       marginBottom: "15px",
//                     }}
//                   >
//                     {tasks?.map((i) => {
//                       return (
//                         i.task === title &&
//                         i.type === ctr.type && (
//                           <img
//                             src={i.icon}
//                             height={"22px"}
//                             width={"22px"}
//                             alt=""
//                           />
//                         )
//                       );
//                     })}{" "}
//                     <h4 style={{ marginLeft: "10px" }}>{title}</h4>
//                   </div>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                   {ctr?.sub_status && (
//                     <div
//                       style={{
//                         flexDirection: "row",
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div
//                         style={{
//                           display: "flex",
//                           flexDirection: "row",
//                         }}
//                       >
//                         <p>Status: </p>
//                         <span className="time" style={{ color: "#fff" }}>
//                           {ctr?.sub_status[index]}
//                         </span>
//                       </div>

//                       <span
//                         className="material-symbols-outlined"
//                         onClick={() =>
//                           Update(
//                             ctr.id,
//                             ctr.name,
//                             ctr.type,
//                             ctr.task,
//                             ctr.time,
//                             ctr.timeAllowed,
//                             ctr.description,
//                             ctr.data,
//                             ctr.month,
//                             ctr.image,
//                             ctr.sub_status,
//                             index
//                           )
//                         }
//                         onMouseOver={({ target }) =>
//                           (target.style.color =
//                             ctr.status === "pending"
//                               ? "#8d5c0a"
//                               : ctr.status === "stopped"
//                               ? "#a73215"
//                               : ctr.status === "completed"
//                               ? "#0a7f00"
//                               : "#a6a6a6")
//                         }
//                         onMouseOut={({ target }) =>
//                           (target.style.color = "white")
//                         }
//                         style={{
//                           marginRight: "8px",
//                           cursor: "pointer",
//                           color: "white",
//                         }}
//                       >
//                         task_alt
//                       </span>
//                     </div>
//                   )}
//                 </AccordionDetails>
//               </Accordion>
//             ))}
//           </>
//         )}
//         <p>{ctr.description}</p>
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             justifyContent: "space-between",
//           }}
//         >
//           <div
//             className="span"
//             style={{ display: "flex", alignItems: "center" }}
//           >
//             <Link to="/chat" style={{ color: "white" }}>
//               <span
//                 className="material-symbols-outlined"
//                 onMouseOver={({ target }) =>
//                   (target.style.color =
//                     ctr.status === "pending"
//                       ? "#8d5c0a"
//                       : ctr.status === "stopped"
//                       ? "#a73215"
//                       : ctr.status === "completed"
//                       ? "#0a7f00"
//                       : "#a6a6a6")
//                 }
//                 onMouseOut={({ target }) => (target.style.color = "white")}
//               >
//                 chat
//               </span>
//             </Link>
//           </div>
//           <div
//             style={{
//               marginTop: "6px",
//             }}
//           >
//             {ctr?.time && (
//               <span className="time" style={{ color: "white" }}>
//                 {ctr?.timeAllowed}
//                 {" mins"}
//               </span>
//             )}
//           </div>
//           <div
//             className="span"
//             style={{
//               padding: "3.2px 16px 8px",
//               display: "flex",
//               alignItems: "center",
//             }}
//           >
//             <ModeEditIcon
//               onClick={() => {
//                 setUpdateTaskDrawer(true);
//                 settask(ctr.type);
//                 settaskType(ctr.task);
//                 settaskName(ctr.name);
//                 settime(ctr.time);
//                 settimeAllowed(ctr.timeAllowed);
//                 setdate(ctr.date);
//                 setdesc(ctr.description);
//                 setimag(ctr.image);
//                 setupdatetaskId(ctr.id);
//                 setupdateMonth(ctr.month);
//               }}
//               onMouseOver={({ target }) =>
//                 (target.style.color =
//                   ctr.status === "pending"
//                     ? "#8d5c0a"
//                     : ctr.status === "stopped"
//                     ? "#a73215"
//                     : ctr.status === "completed"
//                     ? "#0a7f00"
//                     : "#a6a6a6")
//               }
//               onMouseOut={({ target }) => (target.style.color = "white")}
//               style={{
//                 marginRight: "8px",
//                 cursor: "pointer",
//               }}
//             />
//             <DeleteIcon
//               // onClick={() => DeleteTask(ctr.id)}
//               onClick={() => {
//                 setdeleteDialog(true);
//                 setDeleteTaskId(ctr.id);
//               }}
//               onMouseOver={({ target }) =>
//                 (target.style.color =
//                   ctr.status === "pending"
//                     ? "#8d5c0a"
//                     : ctr.status === "stopped"
//                     ? "#a73215"
//                     : ctr.status === "completed"
//                     ? "#0a7f00"
//                     : "#a6a6a6")
//               }
//               onMouseOut={({ target }) => (target.style.color = "white")}
//               style={{
//                 color: "white",
//                 cursor: "pointer",
//               }}
//             />
//             {viewImage ? (
//               <HideImageIcon
//                 onMouseOver={({ target }) =>
//                   (target.style.color =
//                     ctr.status === "pending"
//                       ? "#8d5c0a"
//                       : ctr.status === "stopped"
//                       ? "#a73215"
//                       : ctr.status === "completed"
//                       ? "#0a7f00"
//                       : "#a6a6a6")
//                 }
//                 onMouseOut={({ target }) => (target.style.color = "white")}
//                 style={{
//                   color: "white",
//                   cursor: "pointer",
//                   marginLeft: "10px",
//                 }}
//                 onClick={() => setViewImage(!viewImage)}
//               />
//             ) : (
//               <ImageIcon
//                 onMouseOver={({ target }) =>
//                   (target.style.color =
//                     ctr.status === "pending"
//                       ? "#8d5c0a"
//                       : ctr.status === "stopped"
//                       ? "#a73215"
//                       : ctr.status === "completed"
//                       ? "#0a7f00"
//                       : "#a6a6a6")
//                 }
//                 onMouseOut={({ target }) => (target.style.color = "white")}
//                 style={{
//                   color: "white",
//                   cursor: "pointer",
//                   marginLeft: "10px",
//                 }}
//                 onClick={() => setViewImage(!viewImage)}
//               />
//             )}
//             <span
//               className="material-symbols-outlined"
//               onClick={() => {
//                 setTaskStatus(true);
//                 settaskId(ctr.id);
//                 setupdatedStatus("completed");
//               }}
//               onMouseOver={({ target }) =>
//                 (target.style.color =
//                   ctr.status === "pending"
//                     ? "#8d5c0a"
//                     : ctr.status === "stopped"
//                     ? "#a73215"
//                     : ctr.status === "completed"
//                     ? "#0a7f00"
//                     : "#a6a6a6")
//               }
//               onMouseOut={({ target }) => (target.style.color = "white")}
//               style={{ marginLeft: "8px" }}
//             >
//               task_alt
//             </span>
//           </div>
//         </div>

//         {ctr.image && viewImage && (
//           <div
//             style={{ padding: "8px", cursor: "pointer" }}
//             onClick={() => {
//               setIsViewerOpen(true);
//               setTaskImage(ctr.image);
//             }}
//           >
//             <img
//               src={ctr.image}
//               height={"50%"}
//               width={"100%"}
//               style={{ borderRadius: "8px" }}
//             />
//           </div>
//         )}
//       </AccordionDetails>
//     </Accordion>
//   );
// };

const DisplayTasks = ({ child }) => {
  const [filters, setFilters] = useState("");
  const {
    taskLoading,
    createTaskData,
    success,
    taskData,
    updateTask,
    updateStatusLoading,
  } = useSelector((state) => state.task);

  const current = new Date();

  const [taskStatus, setTaskStatus] = useState(false);

  const dispatch = useDispatch();

  const [taskId, settaskId] = useState("");
  const [updatedStatus, setupdatedStatus] = useState("");

  useEffect(() => {
    if (updateTask) {
      dispatch(getTask());
    }
  }, [updateTask]);

  const [taskImage, setTaskImage] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const [updateTaskDrawer, setUpdateTaskDrawer] = useState(false);
  const [task, settask] = useState("");
  const [taskType, settaskType] = useState("");
  const [taskName, settaskName] = useState("");
  const [date, setdate] = useState("");
  const [time, settime] = useState("");
  const [timeAllowed, settimeAllowed] = useState("");
  const [desc, setdesc] = useState("");
  const [imag, setimag] = useState("");
  const [updatetaskId, setupdatetaskId] = useState("");
  const [updateMonth, setupdateMonth] = useState("");
  const [subStatus, setsubStatus] = useState([]);
  const [recurringState, setrecurringState] = useState(false);
  const [recurrType, setrecurrType] = useState("");
  const [recurringDate, setrecurringDate] = useState("");
  const [weeklyDates, setweeklyDates] = useState([]);
  const [baseId, setbaseId] = useState("");

  const [deleteDialog, setdeleteDialog] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteTaskId, setDeleteTaskId] = useState("");

  const DeleteTask = () => {
    let token = localStorage.getItem("token");
    setDeleteConfirmation(true);
    axios
      .delete(`${BASE_URL}/api/task/deleteTask?taskId=${deleteTaskId}`, {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        dispatch(getTask());
        setDeleteConfirmation(false);
        dispatch(
          getTaskHistory(
            moment(current).subtract(1, "M").format("MM-DD-YYYY"),
            moment(current).add(1, "M").format("MM-DD-YYYY")
          )
        );
      })
      .catch((e) => {
        console.log(`delete error ${e}`);
        setDeleteConfirmation(false);
      });
  };

  const [taskDialog, setTaskDialog] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const Update = (
    id,
    name,
    type,
    task,
    time,
    timeAllow,
    desc,
    date,
    month,
    image,
    sub_status,
    index,
    isRecurring,
    recurringType,
    recurringDate,
    selectedDays,
    base
  ) => {
    let token = localStorage.getItem("token");

    let status = [];
    status.push(...sub_status);
    status.splice(index, 1, "completed");

    // console.log(index, "yes");
    setUpdateLoading(true);
    axios
      .put(
        `${BASE_URL}/api/task/updateTask`,
        {
          id: id,
          name: name,
          type: type,
          task: task,
          time: time,
          timeAllowed: timeAllow,
          description: desc,
          date: date,
          month: month,
          image: image,
          sub_status: status,
          isRecurring: isRecurring,
          recurringType: recurringType,
          recurringDate: recurringDate,
          selectedDays: selectedDays,
          base_id: base,
        },
        {
          headers: {
            Authorization: `token ${JSON.parse(token)}`,
          },
        }
      )
      .then((res) => {
        setTaskDialog(true);
        setUpdateLoading(false);
        if (status.every((e) => e === "completed")) {
          setTaskStatus(false);
          dispatch(updateTaskStatus(id, "completed"));
        }
      })
      .catch((e) => {
        console.log(`update task error ${e}`);
        setUpdateLoading(false);
      });
  };

  const [viewImage, setViewImage] = useState(false);
  const [stateImage, setstateImage] = useState("");

  const [sub, setsub] = useState([]);

  return (
    <>
      <div className="microcards">
        <p
          id="p4"
          style={{
            cursor: "pointer",
            backgroundColor: filters === "4" ? "#a6a6a6" : "#e5e5e5",
          }}
          onClickCapture={() => setFilters("4")}
        >
          Assigned
        </p>
        <p
          id="p1"
          style={{
            cursor: "pointer",
            backgroundColor: filters === "1" ? "#ffe0a8" : "#fcf2e0",
          }}
          onClickCapture={() => setFilters("1")}
        >
          Working
        </p>
        <p
          id="p2"
          style={{
            cursor: "pointer",
            backgroundColor: filters === "2" ? "#f8a894" : "#FDE5DF",
          }}
          onClickCapture={() => setFilters("2")}
        >
          Stopped
        </p>
        <p
          id="p3"
          style={{
            cursor: "pointer",
            backgroundColor: filters === "3" ? "#afffa8" : "#DDF5DB",
          }}
          onClickCapture={() => setFilters("3")}
        >
          Completed
        </p>
      </div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={updateLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={updateStatusLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <GreenDialogModal
        value={taskDialog}
        setValue={() => setTaskDialog(false)}
        title="Confirmation"
        subtitle="Task Updated Successfully"
        onClick={() => {
          setTaskDialog(false);
          dispatch(clearSuccess());
          dispatch(getTask());
          dispatch(getFilteredTask());
          dispatch(
            getTaskHistory(
              moment(current).subtract(1, "M").format("MM-DD-YYYY"),
              moment(current).add(1, "M").format("MM-DD-YYYY")
            )
          );
        }}
      />

      <ConfirmationDialog
        value={deleteDialog}
        setValue={() => setdeleteDialog(false)}
        title="Confirmation"
        subtitle="Delete this task?"
        onClickYes={() => {
          setdeleteDialog(false);
          DeleteTask();
        }}
        onClickNo={() => {
          setdeleteDialog(false);
        }}
        deleteTask={true}
      />

      {/* <Dialog open={deleteDialog} onClose={() => setdeleteDialog(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Delete this task?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setdeleteDialog(false);
              DeleteTask();
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setdeleteDialog(false)}>No</Button>
        </DialogActions>
      </Dialog> */}

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={deleteConfirmation}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {isViewerOpen && (
        <ImageViewer
          src={[taskImage]}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}

      <Drawer
        anchor={"right"}
        open={updateTaskDrawer}
        onClose={() => setUpdateTaskDrawer(false)}
      >
        <UpdateTask
          setUpdateTask={setUpdateTaskDrawer}
          updateTaskType={task}
          updateTask={taskType}
          updateTaskName={taskName}
          updateDate={date}
          updateTime={time}
          updateTimeAllowed={timeAllowed}
          updateDesc={desc}
          updateImage={imag}
          updateTaskId={updatetaskId}
          updateMonth={updateMonth}
          setsubStatus={setsubStatus}
          subStatus={subStatus}
          updateRecState={recurringState}
          updateRecType={recurrType}
          updateRecDate={recurringDate}
          updateWeeDat={weeklyDates}
          baseId={baseId}
        />
      </Drawer>

      <ConfirmationDialog
        title="Confirmation"
        subtitle="Set task as completed?"
        value={taskStatus}
        setValue={() => setTaskStatus(false)}
        onClickYes={() => {
          setTaskStatus(false);
          dispatch(updateTaskStatus(taskId, updatedStatus));
        }}
        onClickNo={() => {
          setTaskStatus(false);
        }}
        deleteTask={false}
      />

      {/* <Dialog open={taskStatus} onClose={() => setTaskStatus(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Set task as completed?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setTaskStatus(false);
              dispatch(updateTaskStatus(taskId, updatedStatus));
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setTaskStatus(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog> */}

      <div className="minicards">
        {taskData?.map((ctr, ind) => {
          return (
            ctr?.assignedToId === child?.id && (
              <>
                {filters === "" && (
                  <AccordionComponent
                    ctr={ctr}
                    Update={Update}
                    setUpdateTaskDrawer={setUpdateTaskDrawer}
                    settask={settask}
                    settaskType={settaskType}
                    settaskName={settaskName}
                    settime={settime}
                    settimeAllowed={settimeAllowed}
                    setdate={setdate}
                    setdesc={setdesc}
                    setimag={setimag}
                    setupdatetaskId={setupdatetaskId}
                    setupdateMonth={setupdateMonth}
                    setdeleteDialog={setdeleteDialog}
                    setDeleteTaskId={setDeleteTaskId}
                    viewImage={viewImage}
                    setViewImage={setViewImage}
                    setIsViewerOpen={setIsViewerOpen}
                    setTaskImage={setTaskImage}
                    setTaskStatus={setTaskStatus}
                    settaskId={settaskId}
                    setupdatedStatus={setupdatedStatus}
                    detail={false}
                    setsubStatus={setsubStatus}
                    setrecurringState={setrecurringState}
                    setrecurrType={setrecurrType}
                    setrecurringDate={setrecurringDate}
                    setweeklyDates={setweeklyDates}
                    recurringState={recurringState}
                    recurrType={recurrType}
                    recurringDate={recurringDate}
                    weeklyDates={weeklyDates}
                    setbaseId={setbaseId}
                  />
                )}
                {filters === "1" && ctr.status === "pending" && (
                  //   <Accordion
                  //     className="player"
                  //     style={{
                  //       backgroundColor: theme.default.pending,
                  //     }}
                  //     defaultExpanded={ind === 0 ? true : false}
                  //   >
                  //     <AccordionSummary>
                  //       <div
                  //         style={{
                  //           width: "100%",
                  //           display: "flex",
                  //           flexDirection: "row",
                  //           justifyContent: "space-between",
                  //         }}
                  //       >
                  //         {ctr.task && (
                  //           <div>
                  //             {ctr.task.map((title) => (
                  //               <div
                  //                 style={{
                  //                   display: "flex",
                  //                   flexDirection: "row",
                  //                   alignItems: "center",
                  //                   marginBottom: "15px",
                  //                 }}
                  //               >
                  //                 {tasks?.map((i) => {
                  //                   return (
                  //                     i.task === title &&
                  //                     i.type === ctr.type && (
                  //                       <img
                  //                         src={i.icon}
                  //                         height={"22px"}
                  //                         width={"22px"}
                  //                         alt=""
                  //                       />
                  //                     )
                  //                   );
                  //                 })}{" "}
                  //                 <h4 style={{ marginLeft: "10px" }}>{title}</h4>
                  //               </div>
                  //             ))}
                  //           </div>
                  //         )}
                  //         <div
                  //           style={{
                  //             float: "right",
                  //           }}
                  //         >
                  //           {ctr?.time && (
                  //             <span className="time" style={{ color: "white" }}>
                  //               {ctr?.timeAllowed}
                  //               {" mins"}
                  //             </span>
                  //           )}
                  //         </div>
                  //       </div>
                  //     </AccordionSummary>
                  //     <AccordionDetails style={{ padding: 0 }}>
                  //       <p>{ctr.description}</p>
                  //       <div
                  //         style={{
                  //           display: "flex",
                  //           flexDirection: "row",
                  //           justifyContent: "space-between",
                  //         }}
                  //       >
                  //         <div className="span">
                  //           <Link to="/chat" style={{ color: "white" }}>
                  //             <span
                  //               className="material-symbols-outlined"
                  //               onMouseOver={({ target }) =>
                  //                 (target.style.color = "#8d5c0a")
                  //               }
                  //               onMouseOut={({ target }) =>
                  //                 (target.style.color = "white")
                  //               }
                  //             >
                  //               chat
                  //             </span>
                  //           </Link>
                  //           {/* <span
                  //                 className="material-symbols-outlined"
                  //                 onMouseOver={({ target }) =>
                  //                   (target.style.color = "#8d5c0a")
                  //                 }
                  //                 onMouseOut={({ target }) =>
                  //                   (target.style.color = "white")
                  //                 }
                  //                 onClick={() => {
                  //                   setIsViewerOpen(true);
                  //                   setTaskImage(ctr?.image);
                  //                 }}
                  //               >
                  //                 attachment
                  //               </span> */}
                  //           {/* <span
                  //                   className="material-symbols-outlined"
                  //                   onClick={() => {
                  //                     setTaskStatus(true);
                  //                     settaskId(ctr.id);
                  //                     setupdatedStatus("completed");
                  //                   }}
                  //                   onMouseOver={({ target }) =>
                  //                     (target.style.color = "#8d5c0a")
                  //                   }
                  //                   onMouseOut={({ target }) =>
                  //                     (target.style.color = "white")
                  //                   }
                  //                 >
                  //                   task_alt
                  //                 </span> */}
                  //         </div>
                  //         <div
                  //           className="span"
                  //           style={{ padding: "3.2px 16px 8px" }}
                  //         >
                  //           <ModeEditIcon
                  //             onClick={() => {
                  //               setUpdateTaskDrawer(true);
                  //               settask(ctr.type);
                  //               settaskType(ctr.task);
                  //               settaskName(ctr.name);
                  //               settime(ctr.time);
                  //               settimeAllowed(ctr.timeAllowed);
                  //               setdate(ctr.date);
                  //               setdesc(ctr.description);
                  //               setimag(ctr.image);
                  //               setupdatetaskId(ctr.id);
                  //               setupdateMonth(ctr.month);
                  //             }}
                  //             onMouseOver={({ target }) =>
                  //               (target.style.color =
                  //                 ctr.status === "pending"
                  //                   ? "#8d5c0a"
                  //                   : ctr.status === "stopped"
                  //                   ? "#a73215"
                  //                   : ctr.status === "completed"
                  //                   ? "#0a7f00"
                  //                   : "#a6a6a6")
                  //             }
                  //             onMouseOut={({ target }) =>
                  //               (target.style.color = "white")
                  //             }
                  //             style={{
                  //               marginRight: "8px",
                  //               cursor: "pointer",
                  //             }}
                  //           />
                  //           <DeleteIcon
                  //             // onClick={() => DeleteTask(ctr.id)}
                  //             onClick={() => {
                  //               setdeleteDialog(true);
                  //               setDeleteTaskId(ctr.id);
                  //             }}
                  //             onMouseOver={({ target }) =>
                  //               (target.style.color =
                  //                 ctr.status === "pending"
                  //                   ? "#8d5c0a"
                  //                   : ctr.status === "stopped"
                  //                   ? "#a73215"
                  //                   : ctr.status === "completed"
                  //                   ? "#0a7f00"
                  //                   : "#a6a6a6")
                  //             }
                  //             onMouseOut={({ target }) =>
                  //               (target.style.color = "white")
                  //             }
                  //             style={{
                  //               color: "white",
                  //               cursor: "pointer",
                  //             }}
                  //           />
                  //         </div>
                  //       </div>
                  //       {ctr.image && (
                  //         <div
                  //           style={{ padding: "8px", cursor: "pointer" }}
                  //           onClick={() => {
                  //             setIsViewerOpen(true);
                  //             setTaskImage(ctr.image);
                  //           }}
                  //         >
                  //           <img
                  //             src={ctr.image}
                  //             height={"50%"}
                  //             width={"100%"}
                  //             style={{ borderRadius: "8px" }}
                  //           />
                  //         </div>
                  //       )}
                  //     </AccordionDetails>
                  //   </Accordion>
                  <AccordionComponent
                    ctr={ctr}
                    Update={Update}
                    setUpdateTaskDrawer={setUpdateTaskDrawer}
                    settask={settask}
                    settaskType={settaskType}
                    settaskName={settaskName}
                    settime={settime}
                    settimeAllowed={settimeAllowed}
                    setdate={setdate}
                    setdesc={setdesc}
                    setimag={setimag}
                    setupdatetaskId={setupdatetaskId}
                    setupdateMonth={setupdateMonth}
                    setdeleteDialog={setdeleteDialog}
                    setDeleteTaskId={setDeleteTaskId}
                    viewImage={viewImage}
                    setViewImage={setViewImage}
                    setIsViewerOpen={setIsViewerOpen}
                    setTaskImage={setTaskImage}
                    setTaskStatus={setTaskStatus}
                    settaskId={settaskId}
                    setupdatedStatus={setupdatedStatus}
                    detail={false}
                    setsubStatus={setsubStatus}
                    setrecurringState={setrecurringState}
                    setrecurrType={setrecurrType}
                    setrecurringDate={setrecurringDate}
                    setweeklyDates={setweeklyDates}
                    recurringState={recurringState}
                    recurrType={recurrType}
                    recurringDate={recurringDate}
                    weeklyDates={weeklyDates}
                    setbaseId={setbaseId}
                  />
                )}
                {filters === "2" && ctr.status === "stopped" && (
                  //   <Accordion
                  //     className="player"
                  //     id="c2"
                  //     defaultExpanded={ind === 0 ? true : false}
                  //   >
                  //     <AccordionSummary>
                  //       <div
                  //         style={{
                  //           width: "100%",
                  //           display: "flex",
                  //           flexDirection: "row",
                  //           justifyContent: "space-between",
                  //         }}
                  //       >
                  //         {ctr.task && (
                  //           <div>
                  //             {ctr.task.map((title) => (
                  //               <div
                  //                 style={{
                  //                   display: "flex",
                  //                   flexDirection: "row",
                  //                   alignItems: "center",
                  //                   marginBottom: "15px",
                  //                 }}
                  //               >
                  //                 {tasks?.map((i) => {
                  //                   return (
                  //                     i.task === title &&
                  //                     i.type === ctr.type && (
                  //                       <img
                  //                         src={i.icon}
                  //                         height={"22px"}
                  //                         width={"22px"}
                  //                         alt=""
                  //                       />
                  //                     )
                  //                   );
                  //                 })}{" "}
                  //                 <h4 style={{ marginLeft: "10px" }}>{title}</h4>
                  //               </div>
                  //             ))}
                  //           </div>
                  //         )}
                  //         <div
                  //           style={{
                  //             float: "right",
                  //           }}
                  //         >
                  //           {ctr?.time && (
                  //             <span className="time" style={{ color: "white" }}>
                  //               {ctr?.timeAllowed}
                  //               {" mins"}
                  //             </span>
                  //           )}
                  //         </div>
                  //       </div>
                  //     </AccordionSummary>
                  //     <AccordionDetails style={{ padding: 0 }}>
                  //       <p>{ctr.description}</p>
                  //       <div
                  //         style={{
                  //           display: "flex",
                  //           flexDirection: "row",
                  //           justifyContent: "space-between",
                  //         }}
                  //       >
                  //         <div className="span">
                  //           <span
                  //             className="material-symbols-outlined"
                  //             onMouseOver={({ target }) =>
                  //               (target.style.color = "#a73215")
                  //             }
                  //             onMouseOut={({ target }) =>
                  //               (target.style.color = "white")
                  //             }
                  //           >
                  //             chat
                  //           </span>
                  //           {/* <span
                  //                 className="material-symbols-outlined"
                  //                 onMouseOver={({ target }) =>
                  //                   (target.style.color = "#a73215")
                  //                 }
                  //                 onMouseOut={({ target }) =>
                  //                   (target.style.color = "white")
                  //                 }
                  //                 onClick={() => {
                  //                   setIsViewerOpen(true);
                  //                   setTaskImage(ctr?.image);
                  //                 }}
                  //               >
                  //                 attachment
                  //               </span> */}
                  //         </div>
                  //         <div
                  //           className="span"
                  //           style={{ padding: "3.2px 16px 8px" }}
                  //         >
                  //           <ModeEditIcon
                  //             onClick={() => {
                  //               setUpdateTaskDrawer(true);
                  //               settask(ctr.type);
                  //               settaskType(ctr.task);
                  //               settaskName(ctr.name);
                  //               settime(ctr.time);
                  //               settimeAllowed(ctr.timeAllowed);
                  //               setdate(ctr.date);
                  //               setdesc(ctr.description);
                  //               setimag(ctr.image);
                  //               setupdatetaskId(ctr.id);
                  //               setupdateMonth(ctr.month);
                  //             }}
                  //             onMouseOver={({ target }) =>
                  //               (target.style.color =
                  //                 ctr.status === "pending"
                  //                   ? "#8d5c0a"
                  //                   : ctr.status === "stopped"
                  //                   ? "#a73215"
                  //                   : ctr.status === "completed"
                  //                   ? "#0a7f00"
                  //                   : "#a6a6a6")
                  //             }
                  //             onMouseOut={({ target }) =>
                  //               (target.style.color = "white")
                  //             }
                  //             style={{
                  //               marginRight: "8px",
                  //               cursor: "pointer",
                  //             }}
                  //           />
                  //           <DeleteIcon
                  //             // onClick={() => DeleteTask(ctr.id)}
                  //             onClick={() => {
                  //               setdeleteDialog(true);
                  //               setDeleteTaskId(ctr.id);
                  //             }}
                  //             onMouseOver={({ target }) =>
                  //               (target.style.color =
                  //                 ctr.status === "pending"
                  //                   ? "#8d5c0a"
                  //                   : ctr.status === "stopped"
                  //                   ? "#a73215"
                  //                   : ctr.status === "completed"
                  //                   ? "#0a7f00"
                  //                   : "#a6a6a6")
                  //             }
                  //             onMouseOut={({ target }) =>
                  //               (target.style.color = "white")
                  //             }
                  //             style={{
                  //               color: "white",
                  //               cursor: "pointer",
                  //             }}
                  //           />
                  //         </div>
                  //       </div>
                  //       {ctr.image && (
                  //         <div
                  //           style={{ padding: "8px", cursor: "pointer" }}
                  //           onClick={() => {
                  //             setIsViewerOpen(true);
                  //             setTaskImage(ctr.image);
                  //           }}
                  //         >
                  //           <img
                  //             src={ctr.image}
                  //             height={"50%"}
                  //             width={"100%"}
                  //             style={{ borderRadius: "8px" }}
                  //           />
                  //         </div>
                  //       )}
                  //     </AccordionDetails>
                  //   </Accordion>
                  <AccordionComponent
                    ctr={ctr}
                    Update={Update}
                    setUpdateTaskDrawer={setUpdateTaskDrawer}
                    settask={settask}
                    settaskType={settaskType}
                    settaskName={settaskName}
                    settime={settime}
                    settimeAllowed={settimeAllowed}
                    setdate={setdate}
                    setdesc={setdesc}
                    setimag={setimag}
                    setupdatetaskId={setupdatetaskId}
                    setupdateMonth={setupdateMonth}
                    setdeleteDialog={setdeleteDialog}
                    setDeleteTaskId={setDeleteTaskId}
                    viewImage={viewImage}
                    setViewImage={setViewImage}
                    setIsViewerOpen={setIsViewerOpen}
                    setTaskImage={setTaskImage}
                    setTaskStatus={setTaskStatus}
                    settaskId={settaskId}
                    setupdatedStatus={setupdatedStatus}
                    detail={false}
                    setsubStatus={setsubStatus}
                    setrecurringState={setrecurringState}
                    setrecurrType={setrecurrType}
                    setrecurringDate={setrecurringDate}
                    setweeklyDates={setweeklyDates}
                    recurringState={recurringState}
                    recurrType={recurrType}
                    recurringDate={recurringDate}
                    weeklyDates={weeklyDates}
                    setbaseId={setbaseId}
                  />
                )}
                {filters === "3" && ctr.status === "completed" && (
                  //   <Accordion
                  //     className="player"
                  //     id="c1"
                  //     defaultExpanded={ind === 0 ? true : false}
                  //   >
                  //     <AccordionSummary>
                  //       <div
                  //         style={{
                  //           width: "100%",
                  //           display: "flex",
                  //           flexDirection: "row",
                  //           justifyContent: "space-between",
                  //         }}
                  //       >
                  //         {ctr.task && (
                  //           <div>
                  //             {ctr.task.map((title) => (
                  //               <div
                  //                 style={{
                  //                   display: "flex",
                  //                   flexDirection: "row",
                  //                   alignItems: "center",
                  //                   marginBottom: "15px",
                  //                 }}
                  //               >
                  //                 {tasks?.map((i) => {
                  //                   return (
                  //                     i.task === title &&
                  //                     i.type === ctr.type && (
                  //                       <img
                  //                         src={i.icon}
                  //                         height={"22px"}
                  //                         width={"22px"}
                  //                         alt=""
                  //                       />
                  //                     )
                  //                   );
                  //                 })}{" "}
                  //                 <h4 style={{ marginLeft: "10px" }}>{title}</h4>
                  //               </div>
                  //             ))}
                  //           </div>
                  //         )}
                  //         <div
                  //           style={{
                  //             float: "right",
                  //           }}
                  //         >
                  //           {ctr?.time && (
                  //             <span className="time" style={{ color: "white" }}>
                  //               {ctr?.timeAllowed}
                  //               {" mins"}
                  //             </span>
                  //           )}
                  //         </div>
                  //       </div>
                  //     </AccordionSummary>
                  //     <AccordionDetails style={{ padding: 0 }}>
                  //       <p>{ctr.description}</p>
                  //       <div
                  //         style={{
                  //           display: "flex",
                  //           flexDirection: "row",
                  //           justifyContent: "space-between",
                  //         }}
                  //       >
                  //         <div className="span">
                  //           <span
                  //             className="material-symbols-outlined"
                  //             onMouseOver={({ target }) =>
                  //               (target.style.color = "#0a7f00")
                  //             }
                  //             onMouseOut={({ target }) =>
                  //               (target.style.color = "white")
                  //             }
                  //           >
                  //             chat
                  //           </span>
                  //           {/* <span
                  //                 className="material-symbols-outlined"
                  //                 onMouseOver={({ target }) =>
                  //                   (target.style.color = "#0a7f00")
                  //                 }
                  //                 onMouseOut={({ target }) =>
                  //                   (target.style.color = "white")
                  //                 }
                  //                 onClick={() => {
                  //                   setIsViewerOpen(true);
                  //                   setTaskImage(ctr?.image);
                  //                 }}
                  //               >
                  //                 attachment
                  //               </span> */}
                  //         </div>
                  //         <div
                  //           className="span"
                  //           style={{ padding: "3.2px 16px 8px" }}
                  //         >
                  //           <ModeEditIcon
                  //             onClick={() => {
                  //               setUpdateTaskDrawer(true);
                  //               settask(ctr.type);
                  //               settaskType(ctr.task);
                  //               settaskName(ctr.name);
                  //               settime(ctr.time);
                  //               settimeAllowed(ctr.timeAllowed);
                  //               setdate(ctr.date);
                  //               setdesc(ctr.description);
                  //               setimag(ctr.image);
                  //               setupdatetaskId(ctr.id);
                  //               setupdateMonth(ctr.month);
                  //             }}
                  //             onMouseOver={({ target }) =>
                  //               (target.style.color =
                  //                 ctr.status === "pending"
                  //                   ? "#8d5c0a"
                  //                   : ctr.status === "stopped"
                  //                   ? "#a73215"
                  //                   : ctr.status === "completed"
                  //                   ? "#0a7f00"
                  //                   : "#a6a6a6")
                  //             }
                  //             onMouseOut={({ target }) =>
                  //               (target.style.color = "white")
                  //             }
                  //             style={{
                  //               marginRight: "8px",
                  //               cursor: "pointer",
                  //             }}
                  //           />
                  //           <DeleteIcon
                  //             // onClick={() => DeleteTask(ctr.id)}
                  //             onClick={() => {
                  //               setdeleteDialog(true);
                  //               setDeleteTaskId(ctr.id);
                  //             }}
                  //             onMouseOver={({ target }) =>
                  //               (target.style.color =
                  //                 ctr.status === "pending"
                  //                   ? "#8d5c0a"
                  //                   : ctr.status === "stopped"
                  //                   ? "#a73215"
                  //                   : ctr.status === "completed"
                  //                   ? "#0a7f00"
                  //                   : "#a6a6a6")
                  //             }
                  //             onMouseOut={({ target }) =>
                  //               (target.style.color = "white")
                  //             }
                  //             style={{
                  //               color: "white",
                  //               cursor: "pointer",
                  //             }}
                  //           />
                  //         </div>
                  //       </div>
                  //       {ctr?.image && (
                  //         <div
                  //           style={{ padding: "8px", cursor: "pointer" }}
                  //           onClick={() => {
                  //             setIsViewerOpen(true);
                  //             setTaskImage(ctr.image);
                  //           }}
                  //         >
                  //           <img
                  //             src={ctr.image}
                  //             height={"50%"}
                  //             width={"100%"}
                  //             style={{ borderRadius: "8px" }}
                  //           />
                  //         </div>
                  //       )}
                  //     </AccordionDetails>
                  //   </Accordion>
                  <AccordionComponent
                    ctr={ctr}
                    Update={Update}
                    setUpdateTaskDrawer={setUpdateTaskDrawer}
                    settask={settask}
                    settaskType={settaskType}
                    settaskName={settaskName}
                    settime={settime}
                    settimeAllowed={settimeAllowed}
                    setdate={setdate}
                    setdesc={setdesc}
                    setimag={setimag}
                    setupdatetaskId={setupdatetaskId}
                    setupdateMonth={setupdateMonth}
                    setdeleteDialog={setdeleteDialog}
                    setDeleteTaskId={setDeleteTaskId}
                    viewImage={viewImage}
                    setViewImage={setViewImage}
                    setIsViewerOpen={setIsViewerOpen}
                    setTaskImage={setTaskImage}
                    setTaskStatus={setTaskStatus}
                    settaskId={settaskId}
                    setupdatedStatus={setupdatedStatus}
                    detail={false}
                    setsubStatus={setsubStatus}
                    setrecurringState={setrecurringState}
                    setrecurrType={setrecurrType}
                    setrecurringDate={setrecurringDate}
                    setweeklyDates={setweeklyDates}
                    recurringState={recurringState}
                    recurrType={recurrType}
                    recurringDate={recurringDate}
                    weeklyDates={weeklyDates}
                    setbaseId={setbaseId}
                  />
                )}
                {filters === "4" && ctr.status === "assigned" && (
                  //   <Accordion
                  //     className="player"
                  //     id="c4"
                  //     defaultExpanded={ind === 0 ? true : false}
                  //   >
                  //     <AccordionSummary>
                  //       <div
                  //         style={{
                  //           width: "100%",
                  //           display: "flex",
                  //           flexDirection: "row",
                  //           justifyContent: "space-between",
                  //         }}
                  //       >
                  //         {ctr.task && (
                  //           <div>
                  //             {ctr.task.map((title) => (
                  //               <div
                  //                 style={{
                  //                   display: "flex",
                  //                   flexDirection: "row",
                  //                   alignItems: "center",
                  //                   marginBottom: "15px",
                  //                 }}
                  //               >
                  //                 {tasks?.map((i) => {
                  //                   return (
                  //                     i.task === title &&
                  //                     i.type === ctr.type && (
                  //                       <img
                  //                         src={i.icon}
                  //                         height={"22px"}
                  //                         width={"22px"}
                  //                         alt=""
                  //                       />
                  //                     )
                  //                   );
                  //                 })}{" "}
                  //                 <h4 style={{ marginLeft: "10px" }}>{title}</h4>
                  //               </div>
                  //             ))}
                  //           </div>
                  //         )}
                  //         <div
                  //           style={{
                  //             float: "right",
                  //           }}
                  //         >
                  //           {ctr?.time && (
                  //             <span className="time" style={{ color: "white" }}>
                  //               {ctr?.timeAllowed}
                  //               {" mins"}
                  //             </span>
                  //           )}
                  //         </div>
                  //       </div>
                  //     </AccordionSummary>
                  //     <AccordionDetails style={{ padding: 0 }}>
                  //       <p>{ctr.description}</p>
                  //       <div
                  //         style={{
                  //           display: "flex",
                  //           flexDirection: "row",
                  //           justifyContent: "space-between",
                  //         }}
                  //       >
                  //         <div className="span">
                  //           <span
                  //             className="material-symbols-outlined"
                  //             onMouseOver={({ target }) =>
                  //               (target.style.color = "#a6a6a6")
                  //             }
                  //             onMouseOut={({ target }) =>
                  //               (target.style.color = "white")
                  //             }
                  //           >
                  //             chat
                  //           </span>
                  //           {/* <span
                  //                                   className="material-symbols-outlined"
                  //                                   onClick={() => {
                  //                                     setTaskStatus(true);
                  //                                     settaskId(ctr?.id);
                  //                                     setupdatedStatus("pending");
                  //                                   }}
                  //                                   onMouseOver={({ target }) =>
                  //                                     (target.style.color =
                  //                                       ctr.status === "pending"
                  //                                         ? "#8d5c0a"
                  //                                         : ctr.status === "stopped"
                  //                                         ? "#a73215"
                  //                                         : ctr.status === "completed"
                  //                                         ? "#0a7f00"
                  //                                         : "#a6a6a6")
                  //                                   }
                  //                                   onMouseOut={({ target }) =>
                  //                                     (target.style.color = "white")
                  //                                   }
                  //                                 >
                  //                                   task_alt
                  //                                 </span> */}
                  //         </div>
                  //         <div
                  //           className="span"
                  //           style={{ padding: "3.2px 16px 8px" }}
                  //         >
                  //           <ModeEditIcon
                  //             onClick={() => {
                  //               setUpdateTaskDrawer(true);
                  //               settask(ctr.type);
                  //               settaskType(ctr.task);
                  //               settaskName(ctr.name);
                  //               settime(ctr.time);
                  //               settimeAllowed(ctr.timeAllowed);
                  //               setdate(ctr.date);
                  //               setdesc(ctr.description);
                  //               setimag(ctr.image);
                  //               setupdatetaskId(ctr.id);
                  //               setupdateMonth(ctr.month);
                  //             }}
                  //             onMouseOver={({ target }) =>
                  //               (target.style.color =
                  //                 ctr.status === "pending"
                  //                   ? "#8d5c0a"
                  //                   : ctr.status === "stopped"
                  //                   ? "#a73215"
                  //                   : ctr.status === "completed"
                  //                   ? "#0a7f00"
                  //                   : "#a6a6a6")
                  //             }
                  //             onMouseOut={({ target }) =>
                  //               (target.style.color = "white")
                  //             }
                  //             style={{
                  //               marginRight: "8px",
                  //               cursor: "pointer",
                  //             }}
                  //           />
                  //           <DeleteIcon
                  //             // onClick={() => DeleteTask(ctr.id)}
                  //             onClick={() => {
                  //               setdeleteDialog(true);
                  //               setDeleteTaskId(ctr.id);
                  //             }}
                  //             onMouseOver={({ target }) =>
                  //               (target.style.color =
                  //                 ctr.status === "pending"
                  //                   ? "#8d5c0a"
                  //                   : ctr.status === "stopped"
                  //                   ? "#a73215"
                  //                   : ctr.status === "completed"
                  //                   ? "#0a7f00"
                  //                   : "#a6a6a6")
                  //             }
                  //             onMouseOut={({ target }) =>
                  //               (target.style.color = "white")
                  //             }
                  //             style={{
                  //               color: "white",
                  //               cursor: "pointer",
                  //             }}
                  //           />
                  //         </div>
                  //       </div>
                  //       {ctr?.image && (
                  //         <div
                  //           style={{ padding: "8px", cursor: "pointer" }}
                  //           onClick={() => {
                  //             setIsViewerOpen(true);
                  //             setTaskImage(ctr.image);
                  //           }}
                  //         >
                  //           <img
                  //             src={ctr.image}
                  //             height={"50%"}
                  //             width={"100%"}
                  //             style={{ borderRadius: "8px" }}
                  //             alt=""
                  //           />
                  //         </div>
                  //       )}
                  //     </AccordionDetails>
                  //   </Accordion>
                  <AccordionComponent
                    ctr={ctr}
                    Update={Update}
                    setUpdateTaskDrawer={setUpdateTaskDrawer}
                    settask={settask}
                    settaskType={settaskType}
                    settaskName={settaskName}
                    settime={settime}
                    settimeAllowed={settimeAllowed}
                    setdate={setdate}
                    setdesc={setdesc}
                    setimag={setimag}
                    setupdatetaskId={setupdatetaskId}
                    setupdateMonth={setupdateMonth}
                    setdeleteDialog={setdeleteDialog}
                    setDeleteTaskId={setDeleteTaskId}
                    viewImage={viewImage}
                    setViewImage={setViewImage}
                    setIsViewerOpen={setIsViewerOpen}
                    setTaskImage={setTaskImage}
                    setTaskStatus={setTaskStatus}
                    settaskId={settaskId}
                    setupdatedStatus={setupdatedStatus}
                    detail={false}
                    setsubStatus={setsubStatus}
                    setrecurringState={setrecurringState}
                    setrecurrType={setrecurrType}
                    setrecurringDate={setrecurringDate}
                    setweeklyDates={setweeklyDates}
                    recurringState={recurringState}
                    recurrType={recurrType}
                    recurringDate={recurringDate}
                    weeklyDates={weeklyDates}
                    setbaseId={setbaseId}
                  />
                )}
              </>
            )
          );
        })}
      </div>
    </>
  );
};

export default DisplayTasks;
