import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../../redux/dispatcher/login";
import { useForm } from "react-hook-form";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import { ConfirmationDialog } from "../../components/DialogModal";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

export default function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordToggle, setPasswordToggle] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [loading, setloading] = useState(false);

  const { loginData, loginError, isAuthenticated, error } = useSelector(
    (state) => state.log
  );

  const [confirmation, setconfirmation] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const onSubmit = () => {
    setloading(true);
    seterrorMsg("");
    axios
      .post(`${BASE_URL}/api/parent/ForgetPasswordEmail`, {
        email: email,
      })
      .then((res) => {
        console.log(res.data);
        setconfirmation(true);
        setloading(false);
        seterrorMsg("");
      })
      .catch((e) => {
        console.log(`reset error ${e.response.data}`);
        seterrorMsg(e.response.data);
        setloading(false);
      });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <section>
      <Header />
      <body>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="main">
          <div className="grid">
            <div className="child1">
              <img
                src={require("../../images/logo.png")}
                width="150px"
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              />
              <div>
                <h1>
                  Ladder of
                  <br /> Remote Access
                </h1>
                <p>
                  A sophisticated, yet easy way to communicate within
                  <br />a family unit where parents get the support they need
                  <br />
                  to convey messages to assignee in the household for
                  <br />
                  task purpose, etc.
                </p>
              </div>
            </div>
            <div className="child2">
              <h1>Reset Password</h1>
              <p>
                Hey, enter your email to get
                <br />a link to reset your password.
              </p>

              {errorMsg !== "" && (
                <p
                  style={{
                    color: "red",
                    alignSelf: "flex-start",
                  }}
                >
                  {errorMsg}
                </p>
              )}
              {errors.email && (
                <p
                  style={{
                    color: "red",
                    alignSelf: "flex-start",
                    paddingBottom: 0,
                  }}
                >
                  *Email address is required
                </p>
              )}
              <input
                type="email"
                id="email"
                name="email"
                {...register("email", { required: true })}
                aria-invalid={errors.email ? "true" : "false"}
                placeholder="Enter email address"
                required
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginTop: errors.email || loginError ? 5 : 16 }}
              />

              <button
                id="signin"
                onClick={handleSubmit(onSubmit)}
                style={{ cursor: "pointer" }}
              >
                Send email
              </button>

              <img id="img5" src="./images/Vector.png" width="100px" alt="" />
              <img id="img2" src="./images/Vector2.png" width="100px" alt="" />
            </div>
          </div>
          <Dialog
            open={confirmation}
            onClose={() => setconfirmation(false)}
            PaperProps={{
              sx: {
                width: "22.5%",
                height: "30%",
              },
            }}
          >
            <DialogTitle
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                paddingBottom: "5px",
                flexDirection: "row",
              }}
            >
              {/* <DoneIcon
                sx={{ color: "green", height: "25px", width: "25px" }}
              /> */}
              Confirmation
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ textAlign: "center" }}>
                An email has been sent to your email address. Please check your
                email
              </DialogContentText>
            </DialogContent>

            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "green",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#329932",
                  },
                  width: "60%",
                  borderRadius: "18px",
                  marginBottom: "15px",
                }}
                onClick={() => {
                  setconfirmation(false);
                  navigate("/sign-in");
                }}
              >
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </body>
    </section>
  );
}
