import { createAction, createReducer } from "@reduxjs/toolkit";
const initialState = {};

const createRequest = createAction("createConvoRequest");
const createSuccess = createAction("createConvoSuccess");
const createFail = createAction("createConvoFailure");

const getRequest = createAction("getConvoRequest");
const getSuccess = createAction("getConvoSuccess");
const getFail = createAction("getConvoFailure");

const getIdRequest = createAction("getConvoIdRequest");
const getIdSuccess = createAction("getConvoIdSuccess");
const getIdFail = createAction("getConvoIdFailure");

const postRequest = createAction("postMsgRequest");
const postSuccess = createAction("postMsgSuccess");
const postFail = createAction("postMsgFailure");

export const chatReducer = createReducer(initialState, (builder) => {
  builder
    //   Create Conversation
    .addCase(createRequest, (state, action) => {
      state.createConvoLoading = true;
    })
    .addCase(createSuccess, (state, action) => {
      state.convoid = action.payload.conversation.id;
      state.createConvoLoading = false;
    })
    .addCase(createFail, (state, action) => {
      state.createConvoLoading = false;
      state.createError = action.payload;
    })
    // Get Conversation
    .addCase(getRequest, (state, action) => {
      state.getConvoLoading = true;
    })
    .addCase(getSuccess, (state, action) => {
      state.getConvoLoading = false;
      state.message = action.payload.messages;
      state.getError = false;
    })
    .addCase(getFail, (state, action) => {
      state.getConvoLoading = false;
      state.getError = false;
    })
    // Get Convo Id
    .addCase(getIdRequest, (state, action) => {
      state.getIdConvoLoading = true;
    })
    .addCase(getIdSuccess, (state, action) => {
      state.getIdConvoLoading = false;
      state.convoid = action.payload.conversation.id;
      state.getIdError = false;
    })
    .addCase(getIdFail, (state, action) => {
      state.getIdConvoLoading = false;
      state.getIdError = false;
    })
    // Post
    .addCase(postRequest, (state, action) => {
      state.postLoading = true;
      state.done = false;
    })
    .addCase(postSuccess, (state, action) => {
      state.postLoading = false;
      state.done = true;
      state.postData = action.payload.child;
      state.postError = false;
    })
    .addCase(postFail, (state, action) => {
      state.postError = false;
      state.done = false;
    });
});
