import {
  Box,
  Switch,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Backdrop,
  CircularProgress,
  FormControl,
  FormGroup,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { repeatType, tasks, taskTypeDropDown, week } from "../assets/data";
import {
  clearSuccess,
  createTask,
  getFilteredTask,
  getTask,
  getTaskHistory,
} from "../redux/dispatcher/tasks";
import Header from "./Header";
import DatePicker from "react-datepicker";
import moment from "moment";
import { GreenDialogModal } from "./DialogModal";
import { imageUpload } from "../redux/dispatcher/login";
import axios from "axios";
import { BASE_URL } from "../constants/config";

const NewTas = ({ setOpenAddNewTask, assignedToId, setValue }) => {
  const current = new Date();

  const isNonMobile = useMediaQuery("(min-width: 760px)");
  const { getParent } = useSelector((state) => state.log);

  const { createTaskData, success } = useSelector((state) => state.task);

  const {
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (success) {
      setTaskDialog(true);
    }
  }, [success]);

  const setTab = () => {
    if (setValue) {
      if (createTaskData?.type === "Task List") {
        setValue("1");
      } else if (createTaskData?.type === "Schedule") {
        setValue("2");
      } else if (createTaskData?.type === "Grocery List") {
        setValue("3");
      }
    }
  };
  const dispatch = useDispatch();

  const [taskName, setTaskName] = useState("");
  const [taskType, setTaskType] = useState("");
  const [task, setTask] = useState([]);
  const [timerState, setTimerState] = useState(false);
  const [time, setTime] = useState("");
  const [taskDesc, settaskDesc] = useState("");
  const [descSwitch, setDescSwitch] = useState(false);
  const [t, sett] = useState([]);
  const [URL, setURL] = useState("");

  const [taskDialog, setTaskDialog] = useState(false);

  const [weeklyTask, setweeklyTask] = useState([]);
  const [taskRepeatType, settaskRepeatType] = useState("");
  const [monthlyDate, setmonthlyDate] = useState(current);

  const handleRepeat = (i) => {
    settaskRepeatType(i);
  };

  const handleTask = (id) => {
    const dt = tasks.filter((x) => x.type === id);
    sett(dt);
    setTaskType(id);
  };

  const onSubmit = () => {
    dispatch(
      createTask(
        taskName,
        taskType,
        task,
        timerState,
        timerState ? time : null,
        assignedToId,
        taskDesc,
        moment(startDate).format("MM-DD-YYYY"),
        moment(startDate).format("MMM"),
        URL,
        taskRepeatType === "" ? false : true,
        weeklyTask,
        monthlyDate,
        taskRepeatType
      )
    );
  };

  const [startDate, setStartDate] = useState(current);

  const [start, setStart] = useState(
    moment(current).subtract(1, "M").format("MM-DD-YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(current).add(1, "M").format("MM-DD-YYYY")
  );
  const [previewImage, setPreviewImage] = useState("");
  const [dataUri, setDataUri] = useState(null);

  const [imageLoading, setimageLoading] = useState(false);

  const onImageChange = (file) => {
    if (!file) {
      setDataUri("");
      return;
    }
    if (file.type === "image/png" || file.type === "image/jpeg") {
      fileToDataUri(file).then((dataUri) => {
        setDataUri(dataUri);
        setPreviewImage(dataUri);
        dataURItoBlob(file);
      });
    } else {
      console.log("Please select only png/jpeg format of image.");
    }
  };
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    setimageLoading(true);
    axios
      .post(`${BASE_URL}/api/aws/file?email=${getParent.email}`, formData)
      .then((res) => {
        setimageLoading(false);
        setURL(res.data.url);
      })
      .catch((err) => {
        setimageLoading(false);
        console.log("image", err);
      });
  }

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setTask((prevSelectedValues) =>
      event.target.checked
        ? [...prevSelectedValues, value]
        : prevSelectedValues.filter((val) => val !== value)
    );
  };

  const handleRepeatCheckboxChange = (event) => {
    const value = event.target.value;
    setweeklyTask((prevSelectedValues) =>
      event.target.checked
        ? [...prevSelectedValues, value]
        : prevSelectedValues.filter((val) => val !== value)
    );
  };

  return (
    <Box
      width={isNonMobile ? "700px" : "350px"}
      style={{
        padding: 0,
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={imageLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header />
      <GreenDialogModal
        value={taskDialog}
        setValue={() => setTaskDialog(false)}
        title="Confirmation"
        subtitle="Task Created Successfully"
        onClick={() => {
          setTaskDialog(false);
          dispatch(clearSuccess());
          dispatch(getTask());
          dispatch(getFilteredTask());
          dispatch(getTaskHistory(start, endDate));
          setOpenAddNewTask(false);
          setTab();
        }}
      />

      <div className="popup" id="myForm">
        <div className="children">
          <div className="heading">
            <h5 style={{ fontSize: !isNonMobile && "20px" }}>Assignee</h5>
            <h3 style={{ fontSize: !isNonMobile && "22px" }}>
              Create New Task
            </h3>
          </div>
          <button
            className="close-btn"
            id="close-modal"
            onClick={() => setOpenAddNewTask(false)}
          >
            X
          </button>
        </div>
        <hr />

        <div className="form-element">
          <div
            className="element"
            style={{ width: !isNonMobile && "max-content" }}
          >
            <label for="task">
              Task Type
              <br />
              <span id="mins">e.g. List, Schedule, Grocery List</span>
            </label>

            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => handleTask(e.target.value)}
            >
              {taskTypeDropDown.map((c, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={c.type}
                    control={<Radio />}
                    label={c.type}
                  />
                );
              })}
            </RadioGroup>
          </div>

          {t.length !== 0 && (
            <div
              className="element"
              style={{ width: !isNonMobile && "max-content" }}
            >
              <label for="task2">
                Task
                <br />
                <span id="mins">e.g. Fix bed, Fold Blankets...</span>
              </label>
              <div className="task2">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setTask(e.target.value)}
                >
                  {t.map((c, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={c.icon}
                          height={"22px"}
                          width={"22px"}
                          style={{
                            marginRight: "7.5px",
                            marginBottom: "2.5px",
                          }}
                          alt=""
                        />
                        {/* <FormControlLabel
                          key={index}
                          value={c.task}
                          control={<Radio />}
                          label={c.task}
                          style={{ alignItems: "center" }}
                        /> */}
                        <FormControlLabel
                          key={index}
                          value={c.task}
                          label={c.task}
                          control={
                            <Checkbox
                              value={c.task}
                              label={c.task}
                              onChange={handleCheckboxChange}
                            />
                          }
                        />
                      </div>
                    );
                  })}
                </RadioGroup>
              </div>
            </div>
          )}

          <div
            className="element"
            style={{ width: !isNonMobile && "max-content" }}
          >
            <label for="task">
              Task Repeat
              <br />
              <span id="mins">e.g. Weekly, Monthly</span>
            </label>

            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => handleRepeat(e.target.value)}
              style={{ width: "60%" }}
            >
              {repeatType.map((c, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={c.title}
                    control={<Radio />}
                    label={c.title}
                  />
                );
              })}
            </RadioGroup>
          </div>

          {taskRepeatType === "Weekly" ? (
            <div
              className="element"
              style={{ width: !isNonMobile && "max-content" }}
            >
              <label for="task2">
                Weekly
                <br />
              </label>
              <div className="task2">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setweeklyTask(e.target.value)}
                >
                  {week.map((c, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          key={index}
                          value={c.title}
                          label={c.title}
                          control={
                            <Checkbox
                              value={c.title}
                              label={c.title}
                              onChange={handleRepeatCheckboxChange}
                            />
                          }
                        />
                      </div>
                    );
                  })}
                </RadioGroup>
              </div>
            </div>
          ) : taskRepeatType === "Monthly" ? (
            <div
              className="element"
              style={{ width: !isNonMobile && "max-content" }}
            >
              <label for="name">
                Monthly
                <br />
                <span id="mins">e.g. 01/02/2023</span>
              </label>
              <Box id="name" style={{ width: "60%" }}>
                <DatePicker
                  selected={monthlyDate}
                  onChange={(date) => setmonthlyDate(date)}
                  // customInput={<ExampleCustomInput />}
                  minDate={new Date()}
                />
              </Box>
            </div>
          ) : null}

          {task.includes(`Other`) && (
            <div className="element">
              <label for="name">
                Task Name
                <br />
                <span id="mins">e.g. William Marshal</span>
              </label>
              <input
                type="text"
                id="name"
                placeholder="Enter task name"
                required
                onChange={(e) => setTaskName(e.target.value)}
              />
            </div>
          )}

          <div
            className="element"
            style={{ width: !isNonMobile && "max-content" }}
          >
            <label for="name">
              Date
              <br />
              <span id="mins">e.g. 01/02/2023</span>
            </label>
            <Box id="name" style={{ width: "60%" }}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                // customInput={<ExampleCustomInput />}
              />
            </Box>
          </div>
          <div className="element">
            <div className="timer">
              <label for="timer">
                Timer
                <br />
                <span id="mins">e.g. 20 mins remaining</span>
              </label>
            </div>
            <Switch onChange={(e) => setTimerState(e.target.checked)} />

            <div
              className="tmr-btn"
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <OutlinedInput
                sx={{
                  "& fieldset": { border: "none" },
                  fontWeight: "inherit",
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  width: "100%",
                }}
                name="time"
                type="number"
                onWheel={(e) => e.target.blur()}
                // {...register("time", { required: true })}
                aria-invalid={errors.time ? "true" : "false"}
                placeholder="Enter time in minutes"
                disabled={timerState ? false : true}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
                value={time}
                // variant="standard"
                endAdornment={
                  <InputAdornment position="end">mins</InputAdornment>
                }
              />
            </div>
          </div>

          <div className="element">
            <label for="describe">
              Description
              <br />
              <span id="mins">e.g. about assignee</span>
            </label>
            <Switch onChange={(e) => setDescSwitch(e.target.checked)} />

            <textarea
              id="describe"
              placeholder="Write Here..."
              name="desc"
              rows="4"
              maxlength="150"
              onChange={(e) => settaskDesc(e.target.value)}
              disabled={descSwitch ? false : true}
            ></textarea>
          </div>

          <div
            className="element"
            style={{
              display: "flex",
              flexDirection: isNonMobile ? "row" : "column",
              alignItems: isNonMobile && "center",
            }}
          >
            <label for="describe" style={{ alignSelf: "flex-start" }}>
              Attachments
              <br />
              <span id="mins">e.g. jpg, png</span>
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: isNonMobile && "center",
                marginTop: 10,
                marginBottom: 0,
                width: isNonMobile ? "60%" : "100%",
              }}
            >
              <input
                accept="image/*"
                type="file"
                id="select-image"
                style={{ display: "none" }}
                onChange={(event) =>
                  onImageChange(event.target.files[0] || null)
                }
              />
              <label htmlFor="select-image">
                <div
                  style={{
                    width: isNonMobile ? "365px" : "205px",
                    height: "200px",
                    borderRadius: "12px",
                    borderStyle: "dashed",
                    borderWidth: "1px",
                    borderColor: "grey",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  {dataUri === null ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p>Add attachments</p>

                      <span id="mins">e.g. jpg, png</span>
                    </div>
                  ) : (
                    <>
                      <img
                        src={previewImage}
                        height="75%"
                        width="75%"
                        style={{ borderRadius: "8px" }}
                        alt=""
                      />
                      {/* <button
                        className="bttn"
                        id="close-modal"
                        onClick={() => {
                          setPreviewImage("");
                        }}
                        style={{ position: "absolute", top: "5%", right: "5%" }}
                      >
                        X
                      </button> */}
                    </>
                  )}
                </div>
              </label>
            </div>
          </div>
        </div>
        <hr id="hr" />
        <div className="bttns">
          <button className="bttn" onClick={() => setOpenAddNewTask(false)}>
            Cancel
          </button>
          <button className="bttn" onClick={handleSubmit(onSubmit)}>
            Create
          </button>
        </div>
      </div>
    </Box>
  );
};

export default NewTas;
