import { createAction, createReducer } from "@reduxjs/toolkit";
const initialState = {};

const createRequest = createAction("createAssigneeRequest");
const createSuccess = createAction("createAssigneeSuccess");
const createFail = createAction("createAssigneeFailure");
const clearSuccAss = createAction("clearAssigneeSuccess");

const getRequest = createAction("getAssigneeRequest");
const getSuccess = createAction("getAssigneeSuccess");
const getFail = createAction("getAssigneeFailure");

const getByIdRequest = createAction("getAssigneeByIdRequest");
const getByIdSuccess = createAction("getAssigneeByIdSuccess");
const getByIdFail = createAction("getAssigneeByIdFailure");

const clearUpdAss = createAction("clearUpdAssSuccess");
const updateRequest = createAction("updateAssigneeRequest");
const updateSuccess = createAction("updateAssigneeSuccess");
const updateFail = createAction("updateAssigneeFailure");

export const assigneeReducer = createReducer(initialState, (builder) => {
  builder
    //   Create Assignee
    .addCase(createRequest, (state, action) => {
      state.createAssiLoading = true;
      state.succ = false;
    })
    .addCase(createSuccess, (state, action) => {
      state.createData = action.payload;
      state.createAssiLoading = false;
      state.succ = true;
    })
    .addCase(createFail, (state, action) => {
      state.createAssiLoading = false;
      state.createError = action.payload;
      state.succ = false;
    })
    .addCase(clearSuccAss, (state, action) => {
      state.succ = false;
    })
    // Update Assignee
    .addCase(updateRequest, (state, action) => {
      state.updateLoading = true;
      state.updateSucc = false;
    })
    .addCase(updateSuccess, (state, action) => {
      state.updateData = action.payload;
      state.updateAssiLoading = false;
      state.updateSucc = true;
    })
    .addCase(updateFail, (state, action) => {
      state.updateAssiLoading = false;
      // state.updateError = action.payload;
      state.updateSucc = false;
    })
    .addCase(clearUpdAss, (state, action) => {
      state.updateSucc = false;
    })
    // Get Assignee
    .addCase(getRequest, (state, action) => {
      state.getLoading = true;
    })
    .addCase(getSuccess, (state, action) => {
      state.getLoading = false;
      state.getAssigneeData = action.payload.childs;
      state.getError = false;
    })
    .addCase(getFail, (state, action) => {
      state.getError = false;
    })
    // Get by id
    .addCase(getByIdRequest, (state, action) => {
      state.getByIdLoading = true;
    })
    .addCase(getByIdSuccess, (state, action) => {
      state.getByIdLoading = false;
      state.getByIdData = action.payload.child;
      state.getByIdError = false;
    })
    .addCase(getByIdFail, (state, action) => {
      state.getByIdError = false;
    });
});
