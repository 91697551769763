import { createAction, createReducer } from "@reduxjs/toolkit";
const initialState = {};

// Parent

const clearSuccess = createAction("clearSuc");

const createRequest = createAction("createTaskRequest");
const createSuccess = createAction("createTaskSuccess");
const createFail = createAction("createTaskFailure");

const getTaskRequest = createAction("getTaskRequest");
const getTaskSuccess = createAction("getTaskSuccess");
const getTaskFail = createAction("getTaskFailure");

const getHistoryRequest = createAction("getTaskHistoryRequest");
const getHistorySuccess = createAction("getTaskHistorySuccess");
const getHistoryFail = createAction("getTaskHistoryFailure");

const getTaskStatusRequest = createAction("getTaskByStatusRequest");
const getTaskStatusSuccess = createAction("getTaskByStatusSuccess");
const getTaskStatusFail = createAction("getTaskByStatusFailure");

const getfilterReq = createAction("getFilteredTaskRequest");
const getfilterSuccess = createAction("getFilteredTaskSuccess");
const getfilterFail = createAction("getFilteredTaskFailure");

const updateReq = createAction("updateTaskRequest");
const updateSuccess = createAction("updateTaskSuccess");
const updateFail = createAction("updateTaskFailure");

const updateStatus = createAction("updateTaskSuccess");

// Child

const getFilChildR = createAction("getFilteredTaskChildRequest");
const getFilChildS = createAction("getFilteredTaskChildSuccess");
const getFilChildF = createAction("getFilteredTaskChildFailure");

const getTaskChildRequest = createAction("getTaskChildRequest");
const getTaskChildSuccess = createAction("getTaskChildSuccess");
const getTaskChildFail = createAction("getTaskChildFailure");

const getChildHistoryR = createAction("getChildHistoryRequest");
const getChildHistoryS = createAction("getChildHistorySuccess");
const getChildHistoryF = createAction("getChildHistoryFailure");

// Parent
export const taskReducer = createReducer(initialState, (builder) => {
  builder
    //   Create Task
    .addCase(createRequest, (state, action) => {
      state.taskLoading = true;
      state.success = false;
    })
    .addCase(createSuccess, (state, action) => {
      state.taskLoading = false;
      state.createTaskData = action.payload.newTask;
      state.createTaskRecurring = action.payload.newRecurringTask;
      state.success = true;
    })
    .addCase(createFail, (state, action) => {
      state.taskLoading = false;
      state.createTaskError = action.payload;
      state.success = false;
    })
    .addCase(clearSuccess, (state, action) => {
      state.taskLoading = false;
      state.success = false;
    })
    // Get Task
    .addCase(getTaskRequest, (state, action) => {
      state.getTaskLoading = true;
    })
    .addCase(getTaskSuccess, (state, action) => {
      state.getTaskLoading = false;
      state.taskData = action.payload.tasks;
    })
    .addCase(getTaskFail, (state, action) => {
      state.getTaskLoading = false;
      // state.getTaskError = action.payload;
    })
    // Get Task History
    .addCase(getHistoryRequest, (state, action) => {
      state.getHistoryLoading = true;
    })
    .addCase(getHistorySuccess, (state, action) => {
      state.getHistoryLoading = false;
      state.taskHistory = action.payload.tasks;
    })
    .addCase(getHistoryFail, (state, action) => {
      state.getHistoryLoading = false;
      // state.getHistoryError = action.payload;
    })
    // Get Task by Status
    .addCase(getTaskStatusRequest, (state, action) => {
      state.taskStatusLoading = true;
    })
    .addCase(getTaskStatusSuccess, (state, action) => {
      state.taskStatusLoading = false;
      state.cancelledTasksData = action.payload.cancelledTasks;
      state.pendingTasksData = action.payload.pendingTasks;
      state.completedTasksData = action.payload.completedasks;
      state.assignedTaskData = action.payload.assignedtask;
    })
    .addCase(getTaskStatusFail, (state, action) => {
      state.taskStatusLoading = false;
      // state.taskStatusError = action.payload;
    })
    // Get Task by months
    .addCase(getfilterReq, (state, action) => {
      state.getTaskFilterLoading = true;
    })
    .addCase(getfilterSuccess, (state, action) => {
      state.getTaskFilterLoading = false;
      state.pendingTaskData = action.payload.pendingtask;
      state.completedTaskData = action.payload.completedtask;
      state.stoppedTaskData = action.payload.stoppedtask;
      state.assignedTaskData = action.payload.assignedtask;
    })
    .addCase(getfilterFail, (state, action) => {
      state.getTaskFilterLoading = false;
      // state.filterTaskError = action.payload;
    })
    // Update Status of a task
    .addCase(updateReq, (state, action) => {
      state.updateTask = false;
      state.updateStatusLoading = true;
    })
    .addCase(updateSuccess, (state, action) => {
      state.updateTask = true;
      state.updateStatusLoading = false;
    })
    .addCase(updateFail, (state, action) => {
      state.updateTask = false;
      state.updateStatusLoading = false;
    });
});

// Child
export const taskReducerChild = createReducer(initialState, (builder) => {
  builder
    // Get Task
    .addCase(getTaskChildRequest, (state, action) => {
      state.getTaskChildLoading = true;
    })
    .addCase(getTaskChildSuccess, (state, action) => {
      state.getTaskChildLoading = false;
      state.taskDataChild = action.payload.tasks;
    })
    .addCase(getTaskChildFail, (state, action) => {
      state.getTaskChildLoading = false;
      state.getTaskChildError = true;
    })

    // Get Task by months
    .addCase(getFilChildR, (state, action) => {
      state.getTaskFilterCLoading = true;
    })
    .addCase(getFilChildS, (state, action) => {
      state.getTaskFilterCLoading = false;
      state.pendingTaskDataC = action.payload.pendingtask;
      state.completedTaskDataC = action.payload.completedtask;
      state.stoppedTaskDataC = action.payload.stoppedtask;
      state.assignedTaskDataC = action.payload.assignedtask;
    })
    .addCase(getFilChildF, (state, action) => {
      state.getTaskFilterCLoading = false;
      // state.filterTaskCError = action.payload;
    })
    // Update Status of a task
    .addCase(updateStatus, (state, action) => {
      state.updateTask = true;
    })
    // Get Child Task History
    .addCase(getChildHistoryR, (state, action) => {
      state.getHistoryLoading = true;
    })
    .addCase(getChildHistoryS, (state, action) => {
      state.getHistoryLoading = false;
      state.taskHistory = action.payload.tasks;
    })
    .addCase(getChildHistoryF, (state, action) => {
      state.getHistoryLoading = false;
      state.getHistoryError = action.payload;
    });
});
