import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";

const SideDrawerChild = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div
        className="sidebar-brand"
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
      >
        <img src="./images/logo.png" alt="Lora Web App" />
      </div>
      <div className="sidebar-menu">
        <ul>
          {/* <li>
            <Link to={"/dashboard"}>
              <span className="material-symbols-outlined">dashboard</span>
              <span>Dashboard</span>
            </Link>
          </li> */}
          <li>
            <Link to={"/chat-child"}>
              <span className="material-symbols-outlined">comment</span>
              <span>Chat</span>
            </Link>
          </li>
          <li>
            <Link to={"/task"}>
              <span className="material-symbols-outlined">add_task</span>
              <span>Tasks</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="version">
        <p>Version 1.0</p>
      </div>
    </>
  );
};

export default SideDrawerChild;
