import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  useMediaQuery,
  TextField,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "../constants/config";
import { GreenDialogModal } from "./DialogModal";
import Header from "./Header";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";

const ChangePasswordAssignee = ({ setChangePasswordDrawer, id }) => {
  const isNonMobile = useMediaQuery("(min-width: 760px)");
  const { loginData, getParent } = useSelector((state) => state.log);

  const passwordRegex = /^(?=.*\d).{6,}$/;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [changePassLoading, setchangePassLoading] = useState(false);

  const [passwordDialog, setpasswordDialog] = useState(false);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const ChangePass = () => {
    let token = localStorage.getItem("token");
    setchangePassLoading(true);
    axios
      .post(
        `${BASE_URL}/api/parent/parentResetPasswordChild`,
        {
          password: password,
          id: id,
        },
        {
          headers: {
            Authorization: `token ${JSON.parse(token)}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setchangePassLoading(false);
        setpasswordDialog(true);
      })
      .catch((e) => {
        console.log(`change pass error ${e}`);
        setchangePassLoading(false);
      });
  };

  return (
    <Box
      width={isNonMobile ? "700px" : "350px"}
      style={{
        padding: 0,
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Header />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={changePassLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <GreenDialogModal
        value={passwordDialog}
        setValue={() => setpasswordDialog(false)}
        title="Confirmation"
        subtitle="Password changed Successfully"
        onClick={() => {
          setpasswordDialog(false);
          setChangePasswordDrawer(false);
        }}
      />

      <div className="popup" id="myForm">
        <div className="children">
          <div className="heading">
            <h5 style={{ fontSize: !isNonMobile && "20px" }}>Assignee</h5>
            <h3 style={{ fontSize: !isNonMobile && "22px" }}>
              Update Password
            </h3>
          </div>
          <button
            className="close-btn"
            id="close-modal"
            onClick={() => setChangePasswordDrawer(false)}
          >
            X
          </button>
        </div>
        <hr />
        <div className="form-element">
          <div className="element">
            <label for="name">
              Password<i>*</i>
              <br />
            </label>
            <div style={{ width: "60%" }}>
              <div>
                {errors?.password?.type === "required" && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      alignSelf: "flex-start",
                      paddingBottom: "8px",
                    }}
                  >
                    *Password is required
                  </p>
                )}
                {errors?.password?.type === "minLength" && (
                  <p
                    style={{
                      maxWidth: "95%",
                      color: "red",
                      fontSize: "10px",
                      alignSelf: "flex-start",
                      paddingBottom: "8px",
                      textAlign: "left",
                    }}
                  >
                    *Password must contain at least 6 characters and 1 number
                  </p>
                )}
                {errors?.password?.type === "pattern" && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      alignSelf: "flex-start",
                      paddingBottom: "8px",
                      textAlign: "left",
                    }}
                  >
                    *Password must contain at least 6 characters and 1 number
                  </p>
                )}
              </div>
              <TextField
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                {...register("password", {
                  required: true,
                  pattern: passwordRegex,
                })}
                sx={{
                  padding: "0px",
                  backgroundColor: "#edf1f4",
                  borderRadius: "6px",
                  "& fieldset": { border: "none" },
                  fontFamily: "inherit",
                  fontWeight: "inherit",
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ marginLeft: "50px" }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Enter password"
              />
            </div>
          </div>
        </div>

        <hr id="hr" />
        <div className="bttns">
          <button
            className="bttn"
            onClick={() => setChangePasswordDrawer(false)}
          >
            Cancel
          </button>
          <button className="bttn" onClick={handleSubmit(ChangePass)}>
            Update
          </button>
        </div>
      </div>
    </Box>
  );
};

export default ChangePasswordAssignee;
