import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideDrawer from "./SideDrawer";
import {
  Drawer,
  Button,
  Box,
  MenuItem,
  Menu,
  Backdrop,
  CircularProgress,
  TextField,
  useMediaQuery,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  clear,
  clearUpdate,
  getParentbyId,
  imageUpload,
  setLogout,
  updateParent,
} from "../redux/dispatcher/login";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../constants/config";
import moment from "moment";
import {
  Sidebar,
  Menu as Meno,
  MenuItem as MenoItem,
  useProSidebar,
} from "react-pro-sidebar";
import { GreenDialogModal } from "./DialogModal";
import Notifications from "react-notifications-menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { clearSuccAss, clearSuccUpdateAss } from "../redux/dispatcher/assignee";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const DEFAULT_NOTIFICATION = {
  image:
    "https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png",
  message: "test notification",
  detailPage: "/",
  receivedTime: "12h ago",
};

const ChangePassword = ({ setChangePasswordDrawer }) => {
  const isNonMobile = useMediaQuery("(min-width: 760px)");
  const { loginData, getParent } = useSelector((state) => state.log);

  const passwordRegex = /^(?=.*\d).{6,}$/;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [changePassLoading, setchangePassLoading] = useState(false);

  const [passwordDialog, setpasswordDialog] = useState(false);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [newpassword, setnewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const ChangePass = () => {
    let token = localStorage.getItem("token");
    setchangePassLoading(true);
    axios
      .post(
        `${BASE_URL}/api/parent/resetPassword`,
        {
          password: password,
          id: loginData.id,
          newpassword: newpassword,
        },
        {
          headers: {
            Authorization: `token ${JSON.parse(token)}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setchangePassLoading(false);
        setpasswordDialog(true);
      })
      .catch((e) => {
        console.log(`change pass error ${e}`);
        setchangePassLoading(false);
      });
  };

  return (
    <Box
      width={isNonMobile ? "700px" : "350px"}
      style={{
        padding: 0,
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Header />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={changePassLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <GreenDialogModal
        value={passwordDialog}
        setValue={() => setpasswordDialog(false)}
        title="Confirmation"
        subtitle="Password changed Successfully"
        onClick={() => {
          setpasswordDialog(false);
          setChangePasswordDrawer(false);
        }}
      />

      <div className="popup" id="myForm">
        <div className="children">
          <div className="heading">
            <h5 style={{ fontSize: !isNonMobile && "20px" }}>Assignor</h5>
            <h3 style={{ fontSize: !isNonMobile && "22px" }}>
              Update Password
            </h3>
          </div>
          <button
            className="close-btn"
            id="close-modal"
            onClick={() => setChangePasswordDrawer(false)}
          >
            X
          </button>
        </div>
        <hr />
        <div className="form-element">
          <div className="element">
            <label for="name">
              Old Password<i>*</i>
              <br />
            </label>
            <div style={{ width: "60%" }}>
              <div>
                {errors?.oldPassword?.type === "required" && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      alignSelf: "flex-start",
                      paddingBottom: "8px",
                    }}
                  >
                    *Old Password is required
                  </p>
                )}
              </div>
              <TextField
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="oldPassword"
                {...register("oldPassword", {
                  required: true,
                })}
                sx={{
                  padding: "0px",
                  backgroundColor: "#edf1f4",
                  borderRadius: "6px",
                  "& fieldset": { border: "none" },
                  fontFamily: "inherit",
                  fontWeight: "inherit",
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ marginLeft: "50px" }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Enter old password"
              />
            </div>
          </div>
          <div className="element">
            <label for="name">
              New Password<i>*</i>
              <br />
            </label>
            <div style={{ width: "60%" }}>
              <div>
                {errors?.newPassword?.type === "required" && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      alignSelf: "flex-start",
                      paddingBottom: "8px",
                    }}
                  >
                    *Password is required
                  </p>
                )}
                {errors?.newPassword?.type === "minLength" && (
                  <p
                    style={{
                      maxWidth: "95%",
                      color: "red",
                      fontSize: "10px",
                      alignSelf: "flex-start",
                      paddingBottom: "8px",
                      textAlign: "left",
                    }}
                  >
                    *Password must contain at least 6 characters and 1 number
                  </p>
                )}
                {errors?.newPassword?.type === "pattern" && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      alignSelf: "flex-start",
                      paddingBottom: "8px",
                      textAlign: "left",
                    }}
                  >
                    *Password must contain at least 6 characters and 1 number
                  </p>
                )}
              </div>
              <TextField
                id="outlined-adornment-password"
                type={showNewPassword ? "text" : "password"}
                name="newPassword"
                {...register("newPassword", {
                  required: true,
                  pattern: passwordRegex,
                })}
                sx={{
                  padding: "0px",
                  backgroundColor: "#edf1f4",
                  borderRadius: "6px",
                  "& fieldset": { border: "none" },
                  fontFamily: "inherit",
                  fontWeight: "inherit",
                }}
                value={newpassword}
                onChange={(e) => setnewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ marginLeft: "50px" }}
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Enter new password"
              />
            </div>
          </div>
        </div>

        <hr id="hr" />
        <div className="bttns">
          <button
            className="bttn"
            onClick={() => setChangePasswordDrawer(false)}
          >
            Cancel
          </button>
          <button className="bttn" onClick={handleSubmit(ChangePass)}>
            Update
          </button>
        </div>
      </div>
    </Box>
  );
};

const HeaderParent = ({ title, toggle }) => {
  const navigate = useNavigate();
  const {
    isAuthenticated,
    getParent,
    loginData,
    updateLoading,
    update,
    token,
  } = useSelector((state) => state.log);

  const [changePasswordDrawer, setChangePasswordDrawer] = useState(false);

  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");

  const selectionChangeHandler = (event) => {
    setSelected(event.target.value);
  };

  const [notificationsData, setNotificationsData] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [editProfile, seteditProfile] = useState(false);

  const [dataUri, setDataUri] = useState(null);
  const [URL, setURL] = useState("");

  const onImageChange = (file) => {
    if (!file) {
      setDataUri("");
      return;
    }
    if (file.type === "image/png" || file.type === "image/jpeg") {
      fileToDataUri(file).then((dataUri) => {
        setDataUri(dataUri);
        setPreviewImage(dataUri);
        console.log(dataUri, file);
        dataURItoBlob(file);
        // dispatch(imageUpload(file, getParent?.email));
      });
    } else {
      console.log("Please select only png/jpeg format of image.");
    }
  };
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    setisImageLoading(true);
    axios
      .post(`${BASE_URL}/api/aws/file?email=${loginData.email}`, formData)
      .then((res) => {
        setURL(res.data.url);
        setPreviewImage(res.data.url);
        console.log(res.data);
        setisImageLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setisImageLoading(false);
      });
  }

  const GetNotification = () => {
    let token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}/api/notification`, {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        console.log(res.data.notifications);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [username, setUsername] = useState(getParent?.name);
  const [email, setEmail] = useState(getParent?.email);

  const [updated, setupdated] = useState(false);

  const { image, imageLoading } = useSelector((state) => state.image);

  const [previewImage, setPreviewImage] = useState("");

  const [isImageLoading, setisImageLoading] = useState(false);

  const [rotate, setRotate] = useState(true);

  return (
    <header>
      <Header />
      <div className="page-info">
        <span
          style={{
            color: "#000",
            transform: rotate ? "rotate(180deg)" : "",
            transition: "transform 500ms ease",
          }}
          className="material-symbols-outlined"
          onClick={() => {
            toggle();
            setRotate(!rotate);
          }}
        >
          {toggle === undefined ? "" : "menu_open"}
        </span>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isImageLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* <Sidebar>
          <Menu>
            <MenuItem> Documentation</MenuItem>
            <MenuItem> Calendar</MenuItem>
            <MenuItem> E-commerce</MenuItem>
          </Menu>
        </Sidebar> */}
        {/* <Drawer
          anchor={"left"}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <SideDrawer
            SideDrawer={SideDrawer}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        </Drawer> */}
        <Drawer
          anchor={"right"}
          open={editProfile}
          onClose={() => seteditProfile(false)}
        >
          <Box
            width="700px"
            style={{
              padding: 0,
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={updateLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <GreenDialogModal
              value={update}
              setValue={() => setupdated(false)}
              title="Confirmation"
              subtitle="Profile updated successfully"
              onClick={() => {
                setupdated(false);
                dispatch(getParentbyId(getParent?.id));
                dispatch(clearUpdate());
                seteditProfile(false);
              }}
            />
            {/* <Dialog open={update} onClose={() => setupdated(false)}>
              <DialogTitle>Confirmation</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Profile updated successfully
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setupdated(false);
                    dispatch(getParentbyId(getParent?.id));
                    dispatch(clearUpdate());
                  }}
                >
                  Okay
                </Button>
              </DialogActions>
            </Dialog> */}
            <div className="popup" id="myForm">
              <div className="children">
                <div className="heading">
                  <h3>Edit Profile</h3>
                </div>
                <button
                  className="close-btn"
                  id="close-modal"
                  onClick={() => {
                    seteditProfile(false);
                  }}
                >
                  X
                </button>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  {previewImage === null ? (
                    <img
                      src={require("../images/user.png")}
                      width="135px"
                      height="135px"
                      alt=""
                      style={{ borderRadius: "360px" }}
                    />
                  ) : (
                    <img
                      src={previewImage}
                      width="135px"
                      height="135px"
                      alt=""
                      style={{ borderRadius: "360px" }}
                    />
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                  marginBottom: 0,
                }}
              >
                <input
                  accept="image/*"
                  type="file"
                  id="select-image"
                  style={{ display: "none" }}
                  onChange={(event) =>
                    onImageChange(event.target.files[0] || null)
                  }
                />
                <label htmlFor="select-image">
                  <Button variant="contained" color="primary" component="span">
                    Update Avatar
                  </Button>
                </label>
              </div>
              <div className="form-element">
                <div className="element">
                  <label for="name">
                    User Name<i>*</i>
                    <br />
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter username"
                    required
                    defaultValue={getParent?.name}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="element">
                  <label for="name">
                    Email<i>*</i>
                    <br />
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter email"
                    required
                    defaultValue={getParent?.email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <hr id="hr" />
              <div className="bttns">
                <button className="bttn" onClick={() => seteditProfile(false)}>
                  Cancel
                </button>
                <button
                  className="bttn"
                  onClick={() =>
                    dispatch(updateParent(getParent?.id, username, email, URL))
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </Box>
        </Drawer>
        <Drawer
          anchor={"right"}
          open={changePasswordDrawer}
          onClose={() => setChangePasswordDrawer(false)}
        >
          <ChangePassword setChangePasswordDrawer={setChangePasswordDrawer} />
        </Drawer>
        <h3 style={{ color: "#000" }}>{title}</h3>
      </div>
      <div className="nav">
        {/* <span
          className="material-symbols-outlined"
          id="bell"
          style={{ justifyContent: "flex-end" }}
          onClick={() => console.log(getParent)}
        >
          notifications
        </span> */}
        {toggle !== undefined && (
          <Notifications
            data={notificationsData}
            className="material-symbols-outlined"
            id="bell"
            header={{
              title: "Notifications",
              option: {
                text: "View All",
                onClick: () => console.log("Clicked"),
              },
            }}
            markAsRead={(notificationsData) => {
              console.log(notificationsData);
            }}
            // icon={() => <NotificationsNoneIcon />}
          />
        )}
        <div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                seteditProfile(true);
                setAnchorEl(null);
                setPreviewImage(getParent?.image);
              }}
            >
              Edit Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                setChangePasswordDrawer(true);
                setAnchorEl(null);
              }}
            >
              Change Password
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/my-packages");
              }}
            >
              Payment History
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(setLogout());
                dispatch(clear());
                dispatch(clearSuccAss());
                dispatch(clearSuccUpdateAss());
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
        <div className="user-wrapper">
          <div className="user-name" style={{ maxWidth: "500px" }}>
            <h5>{getParent?.name}</h5>
            <small>Assignor</small>
          </div>
          {getParent?.image === null ? (
            <img
              src={require("../images/user.png")}
              width="35px"
              height="35px"
              alt=""
              style={{ borderRadius: "360px" }}
            />
          ) : (
            <img
              src={getParent?.image}
              width="35px"
              height="35px"
              alt=""
              style={{ borderRadius: "360px" }}
            />
          )}
          <span className="material-symbols-outlined" onClick={handleClick}>
            expand_more
          </span>
        </div>
      </div>
    </header>
  );
};

export default HeaderParent;
