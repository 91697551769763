import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const Banner = () => {
  const [close, setClose] = useState(true);
  const { getParent } = useSelector((state) => state.log);
  const current = new Date();

  useEffect(() => {
    setClose(true);
  }, [setClose]);

  return (
    <>
      {getParent?.expiryDate !== null &&
        moment(getParent?.expiryDate).diff(moment(current), "days") <= 5 && (
          <>
            {close && (
              <div
                style={{
                  display: "flex",
                  height: "30px",
                  backgroundColor: "#EF481E",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CloseIcon
                  style={{ color: "#EF481E" }}
                  onClick={() => {
                    console.log();
                  }}
                />
                <p
                  style={{
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {`Your package will expire ${moment(getParent?.expiryDate)
                    .startOf("L")
                    .fromNow()}. Please buy a package to continue
            using LORA. `}{" "}
                  <Link to={"/packages"} style={{ marginLeft: "5px" }}>
                    <p
                      style={{
                        color: "white",
                        textDecoration: "underline white",
                      }}
                    >
                      Renew.
                    </p>
                  </Link>
                </p>
                <CloseIcon
                  style={{ color: "white" }}
                  color="white"
                  onClick={() => setClose(false)}
                />
              </div>
            )}
          </>
        )}
    </>
  );
};

export default Banner;
