import { createAction, createReducer } from "@reduxjs/toolkit";
const initialState = {
  isAuthenticated: false,
  authChild: false,
  loading: false,
};

// Parent

const request = createAction("loginRequest");
const success = createAction("loginSuccess");
const fail = createAction("loginFailure");

const logoutRequest = createAction("logoutRequest");
const logoutSuccess = createAction("logoutSuccess");
const logoutFail = createAction("logoutFailure");
const clear = createAction("clearAuth");

const regReq = createAction("registerRequest");
const regSuc = createAction("registerSuccess");
const regF = createAction("registerFailure");

const getMeReq = createAction("getProfileRequest");
const getMeSuc = createAction("getProfileSuccess");
const getMeF = createAction("getProfileFailure");

const updateReq = createAction("updateParentRequest");
const updateSuc = createAction("updateParentSuccess");
const updateF = createAction("updateParentFailure");

const clearUpdateState = createAction("clearUpdateSucess");

// Child
const childLoginReq = createAction("loginChildRequest");
const childLoginSuc = createAction("loginChildSuccess");
const childLoginF = createAction("loginChildFailure");

const getChildReq = createAction("getProfileCRequest");
const getChildSuc = createAction("getProfileCSuccess");
const getChildF = createAction("getProfileCFailure");

// Image

const imageReq = createAction("imageRequest");
const imageSuc = createAction("imageSuccess");
const imageF = createAction("imageFailure");

export const loginCredentials = createReducer(initialState, (builder) => {
  builder
    // Parent Login
    .addCase(request, (state, action) => {
      state.loading = true;
      state.isAuthenticated = false;
      state.error = null;
    })
    .addCase(success, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.loginData = action.payload.parent;
      state.token = action.payload.token;
      state.error = null;
    })
    .addCase(fail, (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.loginError = action.payload;
      state.error = action.payload;
    })

    // Child Login
    .addCase(childLoginReq, (state, action) => {
      state.loginloading = true;
      state.isAuthenticated = false;
      state.check = false;
      state.childError = null;
    })
    .addCase(childLoginSuc, (state, action) => {
      state.check = true;
      state.loginloading = false;
      state.isAuthenticated = true;
      state.childLoginData = action.payload.child;
      state.token = action.payload.token;
      state.childError = null;
    })
    .addCase(childLoginF, (state, action) => {
      state.check = false;
      state.loginloading = false;
      state.isAuthenticated = false;
      state.childError = action.payload;
    })
    // Parent Register
    .addCase(regReq, (state, action) => {
      state.regLoading = true;
      state.regError = null;
    })
    .addCase(regSuc, (state, action) => {
      state.regLoading = false;
      state.loginData = action.payload.newParent;
      state.isAuthenticated = true;
      state.regError = null;
    })
    .addCase(regF, (state, action) => {
      state.regLoading = false;
      state.regError = action.payload.response.data.message;
    })
    // Get Profile
    .addCase(getMeReq, (state, action) => {
      state.getParentLoading = true;
    })
    .addCase(getMeSuc, (state, action) => {
      state.getParentLoading = false;
      state.getParent = action.payload.parent;
    })
    .addCase(getMeF, (state, action) => {
      state.getParentLoading = false;
      state.getParentError = action.payload;
    })
    // update profile
    .addCase(updateReq, (state, action) => {
      state.updateLoading = true;
      state.update = false;
    })
    .addCase(updateSuc, (state, action) => {
      state.updateLoading = false;
      state.update = true;
    })
    .addCase(updateF, (state, action) => {
      state.updateLoading = false;
      state.update = false;
      state.updateError = action.payload;
    })
    .addCase(clearUpdateState, (state) => {
      state.update = false;
    })
    .addCase(clear, (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.token = null;
      state.error = null;
      state.loginData = null;
      state.childLoginData = null;
    });
});

export const logoutRed = createReducer(initialState, (builder) => {
  builder
    .addCase(logoutRequest, (state, action) => {
      state.logoutLoading = true;
    })
    .addCase(logoutSuccess, (state, action) => {
      state.isAuthenticated = false;
      state.logoutLoading = false;
    })
    .addCase(logoutFail, (state, action) => {
      state.logoutLoading = false;
      state.loginerror = true;
    });
});

export const loginChildReducer = createReducer(initialState, (builder) => {
  builder
    // Login

    // .addCase(clear, (state, action) => {
    //   state.isAuthenticated = false;
    //   state.token = null;
    // })
    // Get Profile
    .addCase(getChildReq, (state, action) => {
      state.getChildLoading = true;
    })
    .addCase(getChildSuc, (state, action) => {
      state.getChildLoading = false;
      state.getChild = action.payload.child;
    })
    .addCase(getChildF, (state, action) => {
      state.getChildLoading = false;
      // state.getChildError = action.payload;
    });
});

export const imageUploadReducer = createReducer(initialState, (builder) => {
  builder
    // Get Profile
    .addCase(imageReq, (state, action) => {
      state.imageLoading = true;
    })
    .addCase(imageSuc, (state, action) => {
      state.imageLoading = false;
      state.image = action.payload.url;
    })
    .addCase(imageF, (state, action) => {
      state.imageLoading = false;
      state.imageError = action.payload;
    });
});
