import React, { Fragment, useEffect, useState } from "react";
import Countdown from "react-countdown";
import Timer from "../components/Timer";

const START_MINUTES = 5;
const START_SECOND = 0;
const START_DURATION = 10;
const Test = () => {
  return <Timer START_DURATION={10} START_MINUTES={115} START_SECOND={0} />;
};
export default Test;
