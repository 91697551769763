import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import HeaderComp from "../../components/HeaderParent";
import { BASE_URL } from "../../constants/config";
import { getAssigneeById } from "../../redux/dispatcher/assignee";
import AddIcon from "@mui/icons-material/Add";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Banner from "../../components/Banner";
import SideDrawerChild from "../../components/SideDrawerChild";
import SideDrawer from "../../components/SideDrawer";

const Chat = () => {
  const { getAssigneeData, getLoading, getError, getByIdLoading, getByIdData } =
    useSelector((state) => state.assignee);

  const dispatch = useDispatch();

  const { loginData } = useSelector((state) => state.log);

  const [conv, setconv] = useState([]);

  const [messages, setMessages] = useState([]);
  const [convoID, setConvoID] = useState("");

  const CreateConvo = () => {
    let token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}/api/conversation/createConversation`,
        {
          parentId: loginData?.id,
        },
        {
          headers: {
            Authorization: `token ${JSON.parse(token)}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data, "conv");
        setconv(res.data.conversation);
        GetConvoId();
      })
      .catch((e) => {
        console.log(`conver error ${e}`);
      });
  };

  const GetConvo = (id) => {
    axios
      .get(`${BASE_URL}/api/message/getMessage?conversationId=${id}`)
      .then((res) => {
        // console.log(res.data);
        setMessages(res.data.messages);
      })
      .catch((e) => {
        console.log(`msg error ${e}`);
      });
  };

  const GetConvoId = () => {
    let token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}/api/conversation/getConversationId`, {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setConvoID(res.data.conversation.id);
      })
      .catch((e) => {
        console.log(`msg error ${e}`);
      });
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const PostMessage = (id, msg) => {
    {
      msg !== "" &&
        axios
          .post(`${BASE_URL}/api/message/createMessage`, {
            conversationId: convoID,
            text: msg,
            senderId: loginData.id,
            senderRole: "parent",
          })
          .then((res) => {
            console.log(res.data);
            setMsg("");
            scrollToBottom();
            // setMessages(res.data.messages);
          })
          .catch((e) => {
            console.log(`msg error ${e}`);
          });
    }
  };

  const [msg, setMsg] = useState("");

  const [search, setsearch] = useState("");

  const [childID, setChildID] = useState(getAssigneeData[0]?.id);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (convoID === "") {
      CreateConvo();
    } else GetConvoId();
  }, [convoID]);

  useEffect(() => {
    if (convoID !== null) {
      GetConvo(convoID);
    }
    // scrollToBottom();
  }, [msg, convoID]);

  const [childName, setchildName] = useState(getAssigneeData[0]?.name);
  const [childImage, setchildImage] = useState(getAssigneeData[0]?.image);

  let menuBar = document.getElementById("menuBar");
  let mainBar = document.getElementById("mainBar");

  function toggleBar() {
    menuBar.classList.toggle("open-bar");
    mainBar.classList.toggle("open-bar2");
  }

  return (
    <section>
      <Header />
      <div class="sidebar" id="menuBar" style={{ zIndex: "999" }}>
        <SideDrawer />
      </div>
      <div className="main" id="mainBar">
        <Banner />
        <HeaderComp title={"Chat"} toggle={toggleBar} />
        <main>
          <div className="grid4">
            {/* <div className="col1">
              <div className="allchat">
                <p>All Chat</p>
                
              </div>
              <div className="searcher1">
                <input
                  type="search"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                  style={{ paddingLeft: 15 }}
                />
                {search === "" ? (
                  <span className="material-symbols-outlined">search</span>
                ) : (
                  <span onClick={() => setsearch("")}>X</span>
                )}
              </div>
              <div
                style={{
                  height: 400,
                  overflowY: "scroll",
                  paddingBottom: 20,
                  marginTop: 20,
                }}
              >
                {getAssigneeData
                  .filter((val) => {
                    return val.name
                      .toLocaleLowerCase()
                      .includes(search.toLocaleLowerCase());
                  })
                  .map((child, index) => {
                    return (
                      <>
                        <div
                          key={child.id}
                          className="chat"
                          id="cht1"
                          style={{
                            backgroundColor: child.id === childID && "#f3f3f3",
                            paddingLeft: 5,
                          }}
                          onClick={() => {
                            dispatch(getAssigneeById(child.id));
                            CreateConvo(child.id);
                            GetConvo(convoID);
                            setChildID(child.id);
                            setchildName(child.name);
                            setchildImage(child.image);
                          }}
                          borderRadius="5px"
                        >
                          {child.image ? (
                            <img
                              src={child?.image}
                              width="16%"
                              height="16%"
                              alt=""
                            />
                          ) : (
                            <img
                              src={require("../../images/user.png")}
                              width="16%"
                              height="16%"
                              alt=""
                            />
                          )}
                          <div className="cht">
                            <h6
                              style={{
                                backgroundColor:
                                  child.color.toLocaleLowerCase() === "white"
                                    ? "#FBFCF8"
                                    : child.color.toLocaleLowerCase() ===
                                      "yellow"
                                    ? "#e5e500"
                                    : child.color,
                                color:
                                  child.color.toLocaleLowerCase() === "white"
                                    ? "black"
                                    : "white",
                                border:
                                  child.color.toLocaleLowerCase() === "white"
                                    ? "1px solid black"
                                    : `0px solid ${child.color}`,
                                fontSize: 10,
                              }}
                            >
                              ASSIGNEE #{index + 1}
                            </h6>
                            <h4 style={{ color: "#000", fontSize: 20 }}>
                              {child.name}
                            </h4>
                            
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })}
              </div>
            </div> */}
            <div className="col2">
              <div className="openchat">
                <div className="name">
                  {getAssigneeData.map((child, index) => {
                    return (
                      <div
                        style={{
                          marginRight: "15px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {child.image ? (
                          <img
                            src={child?.image}
                            width="55px"
                            height="55px"
                            alt=""
                            style={{ borderRadius: "360px" }}
                          />
                        ) : (
                          <img
                            src={require("../../images/user.png")}
                            width="55px"
                            height="55px"
                            alt=""
                            style={{ borderRadius: "360px" }}
                          />
                        )}
                        <div>
                          <h4 style={{ color: "#000" }}>{child.name}</h4>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div id="spans">
                  <span
                    className="material-symbols-outlined"
                    onClick={() => GetConvo(convoID)}
                  >
                    refresh
                  </span>
                  <span className="material-symbols-outlined">more_vert</span>
                </div>
              </div>
              <hr />
              <div className="chatntime">
                <div style={{ height: 350, overflowY: "scroll" }}>
                  {messages.map((i, index) => {
                    return messages.length === 0 ? (
                      <div>You are connected</div>
                    ) : (
                      <>
                        {i.senderRole === "parent" ? (
                          <div
                            className="darker"
                            key={index}
                            style={{ paddingRight: 10 }}
                          >
                            <div>
                              <span className="time-left">
                                {moment(i.createdAt).startOf("LTS").fromNow()}
                              </span>
                              <p>{i.text}</p>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="container"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "0px",
                            }}
                          >
                            {i.senderRole === "child" && (
                              <>
                                {i?.child?.image ? (
                                  <img
                                    src={i?.child?.image}
                                    alt="Avatar"
                                    className="right"
                                    style={{
                                      height: 40,
                                      width: 40,
                                      borderRadius: "50%",
                                      marginRight: 5,
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../images/user.png")}
                                    alt="Avatar"
                                    className="right"
                                    style={{
                                      height: 40,
                                      width: 40,
                                      borderRadius: "50%",
                                      marginRight: 5,
                                    }}
                                  />
                                )}
                              </>
                            )}
                            <div style={{ flexDirection: "column" }}>
                              <span className="time-left">
                                {moment(i.createdAt).startOf("LTS").fromNow()}
                              </span>
                              <p>{i.text}</p>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                  <div ref={messagesEndRef} />
                </div>
                <hr />

                <div className="message">
                  {/* <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      background:
                        "linear-gradient(180deg, #0001e0 0%, #db0adf 100%)",
                      borderRadius: "50%",
                      color: "#ffffff",
                      height: "40px",
                      width: "50px",
                    }}
                  >
                    <input style={{ display: "none" }} type="file" />
                    
                  </div> */}
                  <span
                    className="material-symbols-outlined"
                    onClick={() => setOpen(!open)}
                  >
                    sentiment_satisfied
                  </span>
                  <input
                    type="text"
                    placeholder="Enter your message"
                    onChange={(e) => setMsg(e.target.value)}
                    value={msg}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        PostMessage(childID, msg);
                      }
                    }}
                  />
                  <div>
                    {/* <span className="material-symbols-outlined">
                      attachment
                    </span> */}

                    <span
                      className="material-symbols-outlined"
                      onClick={() => {
                        PostMessage(childID, msg);
                      }}
                    >
                      send
                    </span>
                  </div>
                </div>
              </div>
              {open && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "2.5%",
                    right: "2.5%",
                  }}
                >
                  <Picker
                    data={data}
                    previewPosition="none"
                    // onClickOutside={() => setOpen(false)}
                    onEmojiSelect={(e) => {
                      setMsg(msg.concat(e.native));
                      setOpen(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </section>
  );
};

export default Chat;
