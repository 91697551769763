import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import HeaderChild from "../../components/HeaderChild";
import { getChildbyId } from "../../redux/dispatcher/login";
import {
  getChildTaskHistory,
  getFilteredTaskChild,
  getTaskChild,
} from "../../redux/dispatcher/tasks";
import SideDrawerChild from "../../components/SideDrawerChild";
import "chart.js/auto";

export default function DashboardChild() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTaskChild());
    dispatch(getFilteredTaskChild());
  }, []);

  const current = new Date();

  const [startDate, setStartDate] = useState(
    moment(current).subtract(1, "M").format("MM-DD-YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(current).add(1, "M").format("MM-DD-YYYY")
  );

  useEffect(() => {
    dispatch(getChildTaskHistory(startDate, endDate));
  }, []);

  const { childLoginData } = useSelector((state) => state.log);

  useEffect(() => {
    dispatch(getChildbyId(childLoginData?.id));
  }, []);

  const {
    taskDataChild,
    pendingTaskDataC,
    completedTaskDataC,
    stoppedTaskDataC,
    assignedTaskDataC,
  } = useSelector((state) => state.childTask);

  const lineData = {
    labels:
      assignedTaskDataC?.length === 0
        ? pendingTaskDataC?.length === 0
          ? completedTaskDataC?.length === 0
            ? stoppedTaskDataC?.map((i) => i.month)
            : completedTaskDataC?.map((i) => i.month)
          : pendingTaskDataC?.map((i) => i.month)
        : assignedTaskDataC?.map((i) => i.month),
    datasets: [
      {
        label: "ASSIGNED",
        data: assignedTaskDataC?.map((i) => i.tasks),
        backgroundColor: ["#a6a6a6"],
        borderColor: "#a6a6a6",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "PENDING",
        data: pendingTaskDataC?.map((i) => i.tasks),
        backgroundColor: ["#EDA225"],
        borderColor: "#EDA225",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "STOPPED",
        data: stoppedTaskDataC?.map((i) => i.tasks),
        backgroundColor: ["#EF481E"],
        borderColor: "#EF481E",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "COMPLETED",
        data: completedTaskDataC?.map((i) => i.tasks),
        backgroundColor: ["#9ED732"],
        borderColor: "#9ED732",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const legend = {
    display: true,
    position: "bottom",
    labels: {
      fontColor: "#323130",
      fontSize: 14,
    },
  };

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 30,
        right: 30,
      },
    },
  };

  let menuBar = document.getElementById("menuBar");
  let mainBar = document.getElementById("mainBar");

  function toggleBar() {
    menuBar.classList.toggle("open-bar");
    mainBar.classList.toggle("open-bar2");
  }

  const pendingCount = taskDataChild?.filter(
    (i) => i.status === "pending"
  ).length;
  const stoppedCount = taskDataChild?.filter(
    (i) => i.status === "stopped"
  ).length;
  const assignedCount = taskDataChild?.filter(
    (i) => i.status === "assigned"
  ).length;
  const completedCount = taskDataChild?.filter(
    (i) => i.status === "completed"
  ).length;

  return (
    <section>
      <Header />
      <div class="sidebar" id="menuBar">
        <SideDrawerChild />
      </div>
      <div className="main" id="mainBar">
        {/* <!--HEADER--> */}
        <HeaderChild title="Dashboard" toggle={toggleBar} />

        <main>
          {/* <!--CARDS--> */}

          <div className="cards">
            <div className="card-one" id="four">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {assignedCount}
                  <span className="material-symbols-outlined">
                    trending_down
                  </span>
                </h2>
                <h6>TASKS ASSIGNED</h6>
              </div>
            </div>
            <div className="card-one" id="one">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {stoppedCount}{" "}
                  <span className="material-symbols-outlined">
                    trending_down
                  </span>
                </h2>

                <h6>TASKS INCOMPLETE</h6>
              </div>
            </div>
            <div className="card-one" id="two">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {pendingCount}{" "}
                  <span className="material-symbols-outlined">trending_up</span>
                </h2>

                <h6>TASKS PENDING</h6>
              </div>
            </div>
            <div className="card-one" id="three">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {completedCount}{" "}
                  <span className="material-symbols-outlined">trending_up</span>
                </h2>

                <h6>TASKS DONE</h6>
              </div>
            </div>
          </div>

          {/* <!--LINE CHART--> */}
          <div className="chartBox">
            <h3 style={{ color: "#000" }}>Tasks tracking</h3>
            <Line
              style={{ paddingHorizontal: 10 }}
              data={lineData}
              legend={legend}
              options={options}
            />
          </div>
        </main>
      </div>
    </section>
  );
}
