export const theme = {
  mouseOver: {
    assigned: "#a6a6a6",
    pending: "#8d5c0a",
    completed: "#0a7f00",
    stopped: "#a73215",
  },
  default: {
    pending: "#ec9a11",
    assigned: "#808080",
    stopped: "#ef481e",
    completed: "#0fb600",
  },
};
