import React from "react";
import { theme } from "../constants/theme";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";
import HideImageIcon from "@mui/icons-material/HideImage";
import { Link } from "react-router-dom";
import { tasks } from "../assets/data";
import { useState } from "react";
import { useSpeechSynthesis } from "react-speech-kit";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import moment from "moment";

const AccordionComponent = ({
  ctr,
  Update,
  setUpdateTaskDrawer,
  settask,
  settaskType,
  settaskName,
  settime,
  settimeAllowed,
  setdate,
  setdesc,
  setimag,
  setupdatetaskId,
  setupdateMonth,
  setdeleteDialog,
  setDeleteTaskId,
  setIsViewerOpen,
  setTaskImage,
  setTaskStatus,
  settaskId,
  setupdatedStatus,
  detail,
  setsubStatus,
  setrecurringState,
  setrecurrType,
  setrecurringDate,
  setweeklyDates,
  recurringState,
  recurrType,
  recurringDate,
  weeklyDates,
  setbaseId,
}) => {
  const [viewImage, setViewImage] = useState(false);
  const toggleImage = () => setViewImage(!viewImage);

  const { speak, cancel, speaking } = useSpeechSynthesis();
  return (
    <Accordion
      className="player"
      style={{
        backgroundColor:
          ctr.status === "pending"
            ? theme.default.pending
            : ctr.status === "stopped"
            ? theme.default.stopped
            : ctr.status === "completed"
            ? theme.default.completed
            : ctr.status === "assigned" && theme.default.assigned,
      }}
      defaultExpanded={true}
    >
      <AccordionSummary
        style={{ minHeight: "5px", height: "10px" }}
      ></AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        {ctr.task && (
          <>
            {ctr.sub_status &&
              ctr?.task?.map((title, index) => (
                <Accordion
                  id={
                    ctr?.sub_status[index] === "pending"
                      ? "c3"
                      : ctr?.sub_status[index] === "stopped"
                      ? "c2"
                      : ctr?.sub_status[index] === "completed"
                      ? "c1"
                      : ctr?.sub_status[index] === "assigned"
                      ? "c4"
                      : ctr?.sub_status[index] === undefined && "c4"
                  }
                  onClick={() => console.log(ctr.sub_status[index])}
                >
                  <AccordionSummary>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {tasks?.map((i) => {
                        return (
                          i.task === title &&
                          i.type === ctr.type && (
                            <img
                              src={i.icon}
                              height={"22px"}
                              width={"22px"}
                              alt=""
                            />
                          )
                        );
                      })}{" "}
                      <h4 style={{ marginLeft: "10px" }}>
                        {title === "Other" ? ctr.name : title}
                      </h4>
                      {/* <div
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "blue",
                          height: "30px",
                          width: "30px",
                          borderRadius: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <VolumeUpIcon
                          onClick={() => speak({ text: title })}
                          style={{
                            color: "white",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </div> */}
                      <VolumeUpIcon
                        onClick={() => speak({ text: title })}
                        style={{
                          color: "white",
                          height: "20px",
                          width: "20px",
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {ctr?.sub_status && (
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <p>Status: </p>
                          <span className="time" style={{ color: "#fff" }}>
                            {ctr?.sub_status[index] === undefined
                              ? "assigned"
                              : ctr?.sub_status[index].charAt(0).toUpperCase() +
                                ctr?.sub_status[index].slice(1)}
                          </span>
                        </div>
                        {ctr.sub_status[index] !== "completed" && (
                          <span
                            className="material-symbols-outlined"
                            onClick={() =>
                              Update(
                                ctr.id,
                                ctr.name,
                                ctr.type,
                                ctr.task,
                                ctr.time,
                                ctr.timeAllowed,
                                ctr.description,
                                ctr.data,
                                ctr.month,
                                ctr.image,
                                ctr.sub_status,
                                index,
                                ctr.isRecurring,
                                ctr?.recurring_task?.recurringType,
                                ctr?.recurring_task?.selectedDate,
                                ctr?.recurring_task?.selectedDays
                              )
                            }
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr.sub_status[index] === "pending"
                                  ? "#8d5c0a"
                                  : ctr.sub_status[index] === "stopped"
                                  ? "#a73215"
                                  : ctr.sub_status[index] === "completed"
                                  ? "#0a7f00"
                                  : "#a6a6a6")
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              marginRight: "8px",
                              cursor: "pointer",
                              color: "white",
                            }}
                          >
                            task_alt
                          </span>
                        )}
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
          </>
        )}
        {ctr?.isRecurring && ctr?.recurring_task === null ? (
          <p style={{ marginTop: "10px" }}>
            {"Recurring Task: " + ctr?.baseTask?.recurring_task?.recurringType}
            <p>
              {ctr?.baseTask?.recurring_task?.recurringType === "Weekly"
                ? ctr?.baseTask?.recurring_task?.selectedDays.join(" ")
                : ctr?.baseTask?.recurring_task?.recurringType === "Monthly"
                ? moment(ctr?.baseTask?.recurring_task?.selectedDate).format(
                    "DD-MM-YYYY"
                  )
                : null}
            </p>
          </p>
        ) : ctr?.isRecurring ? (
          <p style={{ marginTop: "10px" }}>
            {"Recurring Task: " + ctr?.recurring_task?.recurringType}
            <p>
              {ctr?.recurring_task?.recurringType === "Weekly"
                ? ctr?.recurring_task?.selectedDays.join(" ")
                : ctr?.recurring_task?.recurringType === "Monthly"
                ? moment(ctr?.recurring_task?.selectedDate).format("DD-MM-YYYY")
                : null}
            </p>
          </p>
        ) : null}
        <p>{ctr.description}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            className="span"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Link to="/chat" style={{ color: "white" }}>
              <span
                className="material-symbols-outlined"
                onMouseOver={({ target }) =>
                  (target.style.color =
                    ctr.status === "pending"
                      ? "#8d5c0a"
                      : ctr.status === "stopped"
                      ? "#a73215"
                      : ctr.status === "completed"
                      ? "#0a7f00"
                      : "#a6a6a6")
                }
                onMouseOut={({ target }) => (target.style.color = "white")}
              >
                chat
              </span>
            </Link>
          </div>
          <div
            style={{
              marginTop: "6px",
            }}
          >
            {ctr?.time && (
              <span className="time" style={{ color: "white" }}>
                {ctr?.timeAllowed}
                {" mins"}
              </span>
            )}
          </div>
          <div
            className="span"
            style={{
              padding: "3.2px 16px 8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "26px",
                width: "26px",
                borderRadius: "360px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ModeEditIcon
                onClick={() => {
                  setUpdateTaskDrawer(true);
                  settask(ctr.type);
                  settaskType(ctr.task);
                  settaskName(ctr.name);
                  settime(ctr.time);
                  settimeAllowed(ctr.timeAllowed);
                  setdate(ctr.date);
                  setdesc(ctr.description);
                  setimag(ctr.image);
                  setupdatetaskId(ctr.id);
                  setupdateMonth(ctr.month);
                  setsubStatus(ctr.sub_status);
                  setrecurringState(ctr.isRecurring);
                  setrecurrType(
                    ctr?.isRecurring && ctr?.recurring_task === null
                      ? ctr?.baseTask?.recurring_task?.recurringType
                      : ctr?.isRecurring && ctr?.recurring_task?.recurringType
                  );
                  setrecurringDate(
                    ctr?.isRecurring && ctr?.recurring_task === null
                      ? ctr?.baseTask?.recurring_task?.selectedDate
                      : ctr?.isRecurring && ctr?.recurring_task?.selectedDate
                  );
                  setweeklyDates(
                    ctr?.isRecurring && ctr?.recurring_task === null
                      ? ctr?.baseTask?.recurring_task?.selectedDays
                      : ctr?.isRecurring && ctr?.recurring_task?.selectedDays
                  );
                  setbaseId(ctr?.base_id);
                }}
                onMouseOver={({ target }) =>
                  (target.style.color =
                    ctr.status === "pending"
                      ? "#8d5c0a"
                      : ctr.status === "stopped"
                      ? "#a73215"
                      : ctr.status === "completed"
                      ? "#0a7f00"
                      : "#a6a6a6")
                }
                onMouseOut={({ target }) => (target.style.color = "white")}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              style={{
                height: "26px",
                width: "26px",
                borderRadius: "360px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DeleteIcon
                // onClick={() => DeleteTask(ctr.id)}
                onClick={() => {
                  setdeleteDialog(true);
                  setDeleteTaskId(ctr.id);
                }}
                onMouseOver={({ target }) =>
                  (target.style.color =
                    ctr.status === "pending"
                      ? "#8d5c0a"
                      : ctr.status === "stopped"
                      ? "#a73215"
                      : ctr.status === "completed"
                      ? "#0a7f00"
                      : "#a6a6a6")
                }
                onMouseOut={({ target }) => (target.style.color = "white")}
                style={{
                  color: "white",
                  cursor: "pointer",
                }}
              />
            </div>
            {!detail && (
              <>
                {viewImage ? (
                  <div
                    style={{
                      height: "26px",
                      width: "26px",
                      borderRadius: "360px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <HideImageIcon
                      onMouseOver={({ target }) =>
                        (target.style.color =
                          ctr.status === "pending"
                            ? "#8d5c0a"
                            : ctr.status === "stopped"
                            ? "#a73215"
                            : ctr.status === "completed"
                            ? "#0a7f00"
                            : "#a6a6a6")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "white")
                      }
                      style={{
                        color: "white",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={toggleImage}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      height: "26px",
                      width: "26px",
                      borderRadius: "360px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ImageIcon
                      onMouseOver={({ target }) =>
                        (target.style.color =
                          ctr.status === "pending"
                            ? "#8d5c0a"
                            : ctr.status === "stopped"
                            ? "#a73215"
                            : ctr.status === "completed"
                            ? "#0a7f00"
                            : "#a6a6a6")
                      }
                      onMouseOut={({ target }) =>
                        (target.style.color = "white")
                      }
                      style={{
                        color: "white",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={toggleImage}
                    />
                  </div>
                )}
              </>
            )}
            {ctr.status !== "completed" && (
              <span
                className="material-symbols-outlined"
                onClick={() => {
                  setTaskStatus(true);
                  settaskId(ctr.id);
                  setupdatedStatus("completed");
                  // Update(
                  //   ctr.id,
                  //   ctr.name,
                  //   ctr.type,
                  //   ctr.task,
                  //   ctr.time,
                  //   ctr.timeAllowed,
                  //   ctr.description,
                  //   ctr.data,
                  //   ctr.month,
                  //   ctr.image,
                  //   ctr.sub_status,
                  //   null
                  // );
                }}
                onMouseOver={({ target }) =>
                  (target.style.color =
                    ctr.status === "pending"
                      ? "#8d5c0a"
                      : ctr.status === "stopped"
                      ? "#a73215"
                      : ctr.status === "completed"
                      ? "#0a7f00"
                      : "#a6a6a6")
                }
                onMouseOut={({ target }) => (target.style.color = "white")}
                style={{ marginLeft: "12px", marginRight: "0px" }}
              >
                task_alt
              </span>
            )}
          </div>
        </div>

        {ctr.image && viewImage && (
          <div
            style={{ padding: "8px", cursor: "pointer" }}
            onClick={() => {
              setIsViewerOpen(true);
              setTaskImage(ctr.image);
            }}
          >
            <img
              src={ctr.image}
              height={"50%"}
              width={"100%"}
              style={{ borderRadius: "6px" }}
            />
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionComponent;
