import React from "react";

export default function Header() {
  return (
    <>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="stylesheet" href={require("../Styles/style2.css")} />
      <link rel="stylesheet" href={require("../Styles/style.css")} />
      <link rel="stylesheet" href={require("../Styles/modal-styles.css")} />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
      />
      <link
        rel="stylesheet"
        href="http://code.jquery.com/ui/1.9.2/themes/base/jquery-ui.css"
      />
      <script src="http://code.jquery.com/jquery-1.8.3.js"></script>
      <script src="http://code.jquery.com/ui/1.9.2/jquery-ui.js"></script>
      <script src="./chosen_v1.8.7/chosen.jquery.js"></script>
      <link rel="stylesheet" href="./chosen_v1.8.7/chosen.css" />
      <title>Lora Web App</title>
    </>
  );
}
