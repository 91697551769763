import React from "react";
import { Box } from "@mui/material";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";

const SideDrawer = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <div
        className="sidebar-brand"
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
      >
        <img src="./images/logo.png" alt="Lora Web App" />
      </div>
      <div className="sidebar-menu">
        <ul>
          {/* <li>
            <Link to={"/dashboard"}>
              <span className="material-symbols-outlined">dashboard</span>
              <span>Dashboard</span>
            </Link>
          </li> */}
          <li>
            <Link to={"/assignee"}>
              <span className="material-symbols-outlined">group</span>
              <span>Assignee</span>
            </Link>
          </li>
          <li>
            <Link to={"/chat"}>
              <span className="material-symbols-outlined">comment</span>
              <span>Chat</span>
            </Link>
          </li>
          <li>
            <Link to={"/task"}>
              <span className="material-symbols-outlined">add_task</span>
              <span>Tasks</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/my-packages"}>
              <span className="material-symbols-outlined">settings</span>
              <span>My Package</span>
            </Link>
          </li> */}
          <li>
            <Link to={"/packages"}>
              <span className="material-symbols-outlined">settings</span>
              <span>Pricing</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="version">
        <p>Version 1.0</p>
      </div>
    </>
  );
};

export default SideDrawer;
