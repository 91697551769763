import { TabContext, TabPanel } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import HeaderChild from "../../components/HeaderChild";
import {
  getChildTaskHistory,
  getFilteredTaskChild,
  getTask,
  getTaskChild,
  getTaskHistory,
  updateTaskStatus,
} from "../../redux/dispatcher/tasks";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import { TfiControlPlay, TfiControlPause } from "react-icons/tfi";
import SideDrawerChild from "../../components/SideDrawerChild";
import ImageViewer from "react-simple-image-viewer";
import { tasks } from "../../assets/data";

import {
  ConfirmationDialog,
  GreenDialogModal,
} from "../../components/DialogModal";
import { theme } from "../../constants/theme";

import ImageIcon from "@mui/icons-material/Image";
import HideImageIcon from "@mui/icons-material/HideImage";
import { getChildbyId } from "../../redux/dispatcher/login";

const AccordionComponent = ({
  ctr,
  // viewImage,
  // setViewImage,
  setIsViewerOpen,
  setTaskImage,
  Update,
  hours,
  minutes,
  seconds,
  startTimer,
  pauseTimer,
  ChangeStatus,
  setHours,
  setMinute,
  resetTimer,
  settaskId,
  setTaskStatus,
  setnewTaskStatus,
}) => {
  const START_MINUTES = minutes;
  const START_SECOND = 0;
  const START_DURATION = 10;
  const [viewImage, setViewImage] = useState(false);
  const [currentMinutes, setMinutes] = useState(START_MINUTES);
  const [currentSeconds, setSeconds] = useState(START_SECOND);
  const [isStop, setIsStop] = useState(false);
  const [duration, setDuration] = useState(START_DURATION);
  const [isRunning, setIsRunning] = useState(false);

  const startHandler = () => {
    setDuration(parseInt(START_SECOND, 10) + 60 * parseInt(START_MINUTES, 10));
    // setMinutes(60 * 5);
    // setSeconds(0);
    setIsRunning(true);
  };
  const stopHandler = () => {
    // stop timer
    setIsStop(true);
    setIsRunning(false);
  };
  const resetHandler = () => {
    setMinutes(START_MINUTES);
    setSeconds(START_SECOND);
    setIsRunning(false);
    setIsStop(false);
    setDuration(START_DURATION);
  };

  const resumeHandler = () => {
    let newDuration =
      parseInt(currentMinutes, 10) * 60 + parseInt(currentSeconds, 10);
    setDuration(newDuration);

    setIsRunning(true);
    setIsStop(false);
  };

  useEffect(() => {
    if (isRunning === true) {
      let timer = duration;
      var minutes, seconds;
      const interval = setInterval(function () {
        if (--timer <= 0) {
          resetHandler();
        } else {
          minutes = parseInt(timer / 60, 10);
          seconds = parseInt(timer % 60, 10);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          setMinutes(minutes);
          setSeconds(seconds);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isRunning]);

  return (
    <Accordion
      onClick={() => {
        setHours(Math.floor(ctr.timeAllowed / 60));
        setMinute(ctr.timeAllowed % 60);
      }}
      className="player"
      style={{
        backgroundColor:
          ctr.status === "pending"
            ? theme.default.pending
            : ctr.status === "stopped"
            ? theme.default.stopped
            : ctr.status === "completed"
            ? theme.default.completed
            : ctr.status === "assigned" && theme.default.assigned,
      }}
      defaultExpanded={true}
    >
      <AccordionSummary
        style={{ minHeight: "5px", height: "10px" }}
      ></AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        {ctr.task && (
          <>
            {ctr.sub_status &&
              ctr?.task?.map((title, index) => (
                <Accordion
                  id={
                    ctr?.sub_status[index] === "pending"
                      ? "c3"
                      : ctr?.sub_status[index] === "stopped"
                      ? "c2"
                      : ctr?.sub_status[index] === "completed"
                      ? "c1"
                      : ctr?.sub_status[index] === "assigned" && "c4"
                  }
                >
                  <AccordionSummary>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "15px",
                      }}
                    >
                      {tasks?.map((i) => {
                        return (
                          i.task === title &&
                          i.type === ctr.type && (
                            <img
                              src={i.icon}
                              height={"22px"}
                              width={"22px"}
                              alt=""
                            />
                          )
                        );
                      })}{" "}
                      <h4 style={{ marginLeft: "10px" }}>
                        {title === "Other" ? ctr.name : title}
                      </h4>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {ctr?.sub_status && (
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <p>Status: </p>
                          <span className="time" style={{ color: "#fff" }}>
                            {ctr?.sub_status[index].charAt(0).toUpperCase() +
                              ctr?.sub_status[index].slice(1)}
                          </span>
                        </div>
                        {ctr.sub_status[index] !== "completed" && (
                          <span
                            className="material-symbols-outlined"
                            onClick={() =>
                              Update(
                                ctr.id,

                                ctr.sub_status,
                                index
                              )
                            }
                            onMouseOver={({ target }) =>
                              (target.style.color =
                                ctr?.sub_status[index] === "pending"
                                  ? theme.mouseOver.pending
                                  : ctr?.sub_status[index] === "stopped"
                                  ? theme.mouseOver.stopped
                                  : ctr?.sub_status[index] === "completed"
                                  ? theme.mouseOver.completed
                                  : theme.mouseOver.assigned)
                            }
                            onMouseOut={({ target }) =>
                              (target.style.color = "white")
                            }
                            style={{
                              marginRight: "8px",
                              cursor: "pointer",
                              color: "white",
                            }}
                          >
                            task_alt
                          </span>
                        )}
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
          </>
        )}
        <p>{ctr.description}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            className="span"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Link to="/chat-child" style={{ color: "white" }}>
              <span
                className="material-symbols-outlined"
                onMouseOver={({ target }) =>
                  (target.style.color =
                    ctr.status === "pending"
                      ? theme.mouseOver.pending
                      : ctr.status === "stopped"
                      ? theme.mouseOver.stopped
                      : ctr.status === "completed"
                      ? theme.mouseOver.completed
                      : theme.mouseOver.assigned)
                }
                onMouseOut={({ target }) => (target.style.color = "white")}
              >
                chat
              </span>
            </Link>
          </div>
          {/* <span
            className="material-symbols-outlined"
            onClick={() => {
              setViewImage(true);
              setTaskImage(ctr.image);
            }}
            onMouseOver={({ target }) =>
              (target.style.color =
                ctr.status === "pending"
                  ? theme.mouseOver.pending
                  : ctr.status === "stopped"
                  ? theme.mouseOver.stopped
                  : ctr.status === "completed"
                  ? theme.mouseOver.completed
                  : theme.mouseOver.assigned)
            }
            onMouseOut={({ target }) => (target.style.color = "white")}
            style={{ color: "white", cursor: "pointer" }}
          >
            attachment
          </span> */}
          <div
            style={{
              marginTop: "6px",
            }}
          >
            {ctr?.time && (
              <span className="time" style={{ color: "white" }}>
                {ctr?.timeAllowed}
                {" mins"}
              </span>
            )}
          </div>
          <div
            className="span"
            style={{
              padding: "3.2px 16px 8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {viewImage ? (
              <div
                style={{
                  height: "26px",
                  width: "26px",
                  borderRadius: "360px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <HideImageIcon
                  onMouseOver={({ target }) =>
                    (target.style.color =
                      ctr.status === "pending"
                        ? "#8d5c0a"
                        : ctr.status === "stopped"
                        ? "#a73215"
                        : ctr.status === "completed"
                        ? "#0a7f00"
                        : "#a6a6a6")
                  }
                  onMouseOut={({ target }) => (target.style.color = "white")}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    setViewImage(!viewImage);
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  height: "26px",
                  width: "26px",
                  borderRadius: "360px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImageIcon
                  onMouseOver={({ target }) =>
                    (target.style.color =
                      ctr.status === "pending"
                        ? "#8d5c0a"
                        : ctr.status === "stopped"
                        ? "#a73215"
                        : ctr.status === "completed"
                        ? "#0a7f00"
                        : "#a6a6a6")
                  }
                  onMouseOut={({ target }) => (target.style.color = "white")}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={() => setViewImage(!viewImage)}
                />
              </div>
            )}
            {ctr.status !== "" && (
              <span
                className="material-symbols-outlined"
                onClick={() => {
                  settaskId(ctr.id);
                  setTaskStatus(true);
                  setnewTaskStatus(ctr.status);
                }}
                onMouseOver={({ target }) =>
                  (target.style.color =
                    ctr.status === "pending"
                      ? "#8d5c0a"
                      : ctr.status === "stopped"
                      ? "#a73215"
                      : ctr.status === "completed"
                      ? "#0a7f00"
                      : "#a6a6a6")
                }
                onMouseOut={({ target }) => (target.style.color = "white")}
                style={{ marginLeft: "12px", marginRight: "0px" }}
              >
                task_alt
              </span>
            )}
          </div>
        </div>
        {ctr.time && ctr.status === "pending" && (
          <>
            <hr />
            <div class="stopwatch">
              <h3 style={{ fontWeight: "bold" }}>
                {/* {`${hours} : ${
                  minutes === undefined ? 0 : minutes
                } : ${seconds}`} */}
                {currentMinutes}
                <span>:</span>
                {currentSeconds}
              </h3>
              <div class="buttons">
                {!isRunning && !isStop && (
                  <button onClick={startHandler} style={{ marginRight: "5px" }}>
                    <span class="material-symbols-outlined">
                      <TfiControlPlay />
                    </span>
                  </button>
                )}
                {isRunning ? (
                  <button onClick={stopHandler} style={{ marginRight: "5px" }}>
                    <span class="material-symbols-outlined">
                      <TfiControlPause />
                    </span>
                  </button>
                ) : (
                  isStop && (
                    <button
                      onClick={resumeHandler}
                      style={{ marginRight: "5px" }}
                    >
                      <span class="material-symbols-outlined">
                        <TfiControlPlay />
                      </span>
                    </button>
                  )
                )}

                <button
                  onClick={() => {
                    ChangeStatus(ctr.id, "stopped");
                  }}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                >
                  <span class="material-symbols-outlined" id="stp">
                    stop
                  </span>
                </button>
                <button onClick={resetHandler}>
                  <span class="material-symbols-outlined">refresh</span>
                </button>
              </div>
            </div>
          </>
        )}
        {ctr.image && viewImage && (
          <div
            style={{ padding: "8px", cursor: "pointer" }}
            onClick={() => {
              setIsViewerOpen(true);
              setTaskImage(ctr.image);
            }}
          >
            <img
              src={ctr.image}
              height={"50%"}
              width={"100%"}
              style={{ borderRadius: "6px" }}
            />
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const SecondComp = ({ type }) => {
  const [filters, setFilters] = useState("");

  const { taskHistory } = useSelector((state) => state.childTask);

  const [search, setsearch] = useState("");

  const [taskStatus, setTaskStatus] = useState(false);

  const dispatch = useDispatch();

  const [taskId, settaskId] = useState("");
  const [newTaskStatus, setnewTaskStatus] = useState("");
  const [taskStatusDialog, setTaskStatusDialog] = useState(false);

  const current = new Date();

  const [startDate, setStartDate] = useState(current);
  const [endDate, setEndDate] = useState(current);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    dispatch(
      getChildTaskHistory(
        moment(start).format("MM-DD-YYYY"),
        moment(end).format("MM-DD-YYYY")
      )
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     getChildTaskHistory(
  //       moment(startDate).format("MM-DD-YYYY"),
  //       moment(endDate).format("MM-DD-YYYY")
  //     )
  //   );
  // }, [endDate]);

  const [calendar, setCalendar] = useState(false);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button id="history" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [milliseconds, setMilliseconds] = useState(0);
  const [isRunning, setIsRunning] = useState(null);

  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        if (milliseconds > 0) {
          setMilliseconds((milliseconds) => milliseconds - 1);
        } else if (seconds > 0) {
          setSeconds((seconds) => seconds - 1);
          setMilliseconds(99);
        } else if (minutes > 0) {
          setMinutes((minutes) => minutes - 1);
          setSeconds(59);
          setMilliseconds(99);
        } else if (hours > 0) {
          setHours((hours) => hours - 1);
          setMinutes(59);
          setSeconds(59);
          setMilliseconds(99);
        }
      }, 0);
    }

    if (hours === 0 && minutes === 0 && seconds === 0 && milliseconds === 1) {
      resetTimer(0);
    }
    return () => clearInterval(interval);
  }, [milliseconds, seconds, minutes, hours, isRunning]);

  function startTimer() {
    if (hours !== 0 || minutes !== 0 || seconds !== 0 || milliseconds !== 0) {
      setIsRunning(true);
    } else {
      console.log("Add Time.");
    }
  }

  function pauseTimer() {
    setIsRunning(false);
  }

  function resetTimer(num) {
    setIsRunning(false);
    setMilliseconds(0);
    setSeconds(0);
    setMinutes(num % 60);
    setHours(Math.floor(num / 60));
  }

  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const ChangeStatus = (id, status) => {
    setChangeStatusLoading(true);
    let token = localStorage.getItem("token");
    let stat = "";
    console.log(newTaskStatus, status, "status");
    if (status === "stopped") {
      stat = status;
    }
    if (newTaskStatus === "assigned") {
      stat = "pending";
    } else if (newTaskStatus === "pending") {
      stat = "completed";
    } else if (newTaskStatus === "completed") {
      stat = "assigned";
    } else stat = status;
    axios
      .post(
        `${BASE_URL}/api/task/updateChildTaskStatus`,
        {
          id: id,
          status: stat,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      )
      .then((res) => {
        setChangeStatusLoading(false);
        setTaskStatusDialog(true);
        dispatch(
          getChildTaskHistory(
            moment(startDate).subtract(1, "M").format("MM-DD-YYYY"),
            moment(endDate).add(1, "M").format("MM-DD-YYYY")
          )
        );
      })
      .catch((e) => {
        setChangeStatusLoading(false);
        setTaskStatusDialog(false);
        dispatch(
          getChildTaskHistory(
            moment(startDate).subtract(1, "M").format("MM-DD-YYYY"),
            moment(endDate).add(1, "M").format("MM-DD-YYYY")
          )
        );
      });
  };

  useEffect(() => {
    dispatch(
      getChildTaskHistory(
        moment(startDate).subtract(1, "M").format("MM-DD-YYYY"),
        moment(endDate).add(1, "M").format("MM-DD-YYYY")
      )
    );
  }, []);

  const [taskImage, setTaskImage] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const [viewImage, setViewImage] = useState(false);

  const [taskDialog, setTaskDialog] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const Update = (
    id,

    sub_status,
    index
  ) => {
    let token = localStorage.getItem("token");

    let status = [];
    status.push(...sub_status);
    // status.splice(index, 1, "completed");
    if (status[index] === "assigned") {
      status.splice(index, 1, "pending");
    } else if (status[index] === "pending") {
      status.splice(index, 1, "completed");
    } else if (status[index] === "stopped") {
      status.splice(index, 1, "completed");
    }

    setUpdateLoading(true);
    axios
      .post(
        `${BASE_URL}/api/task/updateChildSubTaskStatus`,
        {
          id: id,
          sub_status: status,
        },
        {
          headers: {
            Authorization: `token ${JSON.parse(token)}`,
          },
        }
      )
      .then((res) => {
        setsingleTaskConfirmation(true);
        setUpdateLoading(false);
        if (status.every((e) => e === "completed")) {
          setTaskStatus(false);
          setnewTaskStatus("completed");
          ChangeStatus(id, "completed");
        }
      })
      .catch((e) => {
        console.log(`update task error ${e}`);
        setsingleTaskConfirmation(false);
        setUpdateLoading(false);
      });
  };

  const [singleTaskDialog, setsingleTaskDialog] = useState(false);
  const [singleTaskConfirmation, setsingleTaskConfirmation] = useState(false);
  const [deleteTaskId, setDeleteTaskId] = useState("");

  return (
    <>
      <div className="histry">
        {isViewerOpen && (
          <ImageViewer
            src={[taskImage]}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}

        <GreenDialogModal
          value={taskStatusDialog}
          setValue={() => setTaskStatusDialog(false)}
          title="Confirmation"
          subtitle="Task Updated Successfully"
          onClick={() => {
            setTaskStatusDialog(false);
            dispatch(getTaskChild());
            dispatch(getFilteredTaskChild());
            dispatch(
              getChildTaskHistory(
                moment(startDate).subtract(1, "M").format("MM-DD-YYYY"),
                moment(endDate).add(1, "M").format("MM-DD-YYYY")
              )
            );
          }}
        />

        <GreenDialogModal
          value={singleTaskConfirmation}
          setValue={() => setsingleTaskConfirmation(false)}
          title="Confirmation"
          subtitle="Task Updated Successfully"
          onClick={() => {
            setsingleTaskConfirmation(false);
            dispatch(getTaskChild());
            dispatch(getFilteredTaskChild());
            dispatch(
              getChildTaskHistory(
                moment(startDate).subtract(1, "M").format("MM-DD-YYYY"),
                moment(endDate).add(1, "M").format("MM-DD-YYYY")
              )
            );
          }}
        />

        {/* <Dialog open={taskStatus} onClose={() => setTaskStatus(false)}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>Set task as completed?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setTaskStatus(false);
                ChangeStatus(taskId, "completed");
              }}
            >
              Yes
            </Button>
            <Button onClick={() => setTaskStatusDialog(false)}>No</Button>
          </DialogActions>
        </Dialog> */}

        {/* <Dialog
          open={singleTaskDialog}
          onClose={() => setsingleTaskDialog(false)}
        >
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>Set this task as completed?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setsingleTaskDialog(false);
                Update();
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog> */}

        <ConfirmationDialog
          value={singleTaskDialog}
          setValue={() => setsingleTaskDialog(false)}
          title="Confirmation"
          subtitle="Set this task as completed?"
          onClickYes={() => {
            setsingleTaskDialog(false);
            Update();
          }}
          onClickNo={() => {
            setsingleTaskDialog(false);
          }}
          deleteTask={false}
        />
        <ConfirmationDialog
          value={taskStatus}
          setValue={() => setTaskStatus(false)}
          title="Confirmation"
          subtitle={
            newTaskStatus === "assigned"
              ? "Set this task as working?"
              : newTaskStatus === "pending"
              ? "Set this task as completed?"
              : "Set this task as completed?"
          }
          onClickYes={() => {
            setTaskStatus(false);
            ChangeStatus(taskId, "completed");
          }}
          onClickNo={() => {
            setTaskStatus(false);
          }}
          deleteTask={false}
        />

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={changeStatusLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={updateLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="microcards">
          <p
            id="p4"
            style={{
              cursor: "pointer",
              backgroundColor: filters === "4" ? "#a6a6a6" : "#e5e5e5",
            }}
            onClickCapture={() => setFilters("4")}
          >
            Assigned
          </p>
          <p
            id="p1"
            style={{
              cursor: "pointer",
              backgroundColor: filters === "1" ? "#ffe0a8" : "#fcf2e0",
            }}
            onClickCapture={() => setFilters("1")}
          >
            Working
          </p>
          <p
            id="p2"
            style={{
              cursor: "pointer",
              backgroundColor: filters === "2" ? "#f8a894" : "#FDE5DF",
            }}
            onClickCapture={() => setFilters("2")}
          >
            Stopped
          </p>
          <p
            id="p3"
            style={{
              cursor: "pointer",
              backgroundColor: filters === "3" ? "#afffa8" : "#DDF5DB",
            }}
            onClickCapture={() => setFilters("3")}
          >
            Completed
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {calendar && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 10,
              }}
            >
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                customInput={<ExampleCustomInput />}
              />
            </Box>
          )}
          <button id="history" onClick={() => setCalendar(!calendar)}>
            <i className="material-symbols-outlined">history</i> History
          </button>
        </div>
      </div>
      <div className="searcher2">
        <span className="material-symbols-outlined">search</span>
        <input
          type="search"
          placeholder="Search Task List, Schedule, Grocery List..."
          onChange={(e) => setsearch(e.target.value)}
        />
      </div>
      {taskHistory
        ?.filter((val) => {
          return val.type
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase());
        })
        ?.map((ctr, ind) => {
          return (
            ctr.type === type && (
              <>
                {filters === "" && (
                  <div className="grid5">
                    <div className="minicards">
                      <AccordionComponent
                        ctr={ctr}
                        viewImage={viewImage}
                        setViewImage={setViewImage}
                        setIsViewerOpen={setIsViewerOpen}
                        setTaskImage={setTaskImage}
                        Update={Update}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                        isRunning={isRunning}
                        startTimer={startTimer}
                        pauseTimer={pauseTimer}
                        resetTimer={resetTimer}
                        ChangeStatus={ChangeStatus}
                        setHours={setHours}
                        setMinute={setMinutes}
                        settaskId={settaskId}
                        setTaskStatus={setTaskStatus}
                        setnewTaskStatus={setnewTaskStatus}
                      />
                    </div>
                  </div>
                )}
                {filters === "1" && ctr.status === "pending" && (
                  <div className="grid5">
                    <div className="minicards">
                      {/* <Accordion className="player" id="c3">
                        <AccordionSummary
                          onClick={() => {
                            setHours(Math.floor(ctr.timeAllowed / 60));
                            setMinutes(ctr.timeAllowed % 60);
                            console.log(ctr);
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {ctr.task && (
                              <div>
                                {ctr.task.map((title) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    {tasks?.map((i) => {
                                      return (
                                        i.task === title &&
                                        i.type === ctr.type && (
                                          <img
                                            src={i.icon}
                                            height={"22px"}
                                            width={"22px"}
                                            alt=""
                                          />
                                        )
                                      );
                                    })}{" "}
                                    <h4 style={{ marginLeft: "10px" }}>
                                      {title}
                                    </h4>
                                  </div>
                                ))}
                              </div>
                            )}
                            <div
                              style={{
                                float: "right",
                              }}
                            >
                              {ctr?.time && (
                                <span
                                  className="time"
                                  style={{ color: "white" }}
                                >
                                  {ctr?.timeAllowed}
                                  {" mins"}
                                </span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <p>{ctr.description}</p>
                          <div className="span">
                            <Link
                              to="/chat-child"
                              style={{ color: "white" }}
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              <span className="material-symbols-outlined">
                                chat
                              </span>
                            </Link>
                            <span
                              className="material-symbols-outlined"
                              onClick={() => {
                                setIsViewerOpen(true);
                                setTaskImage(ctr.image);
                              }}
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              attachment
                            </span>
                            <span
                              className="material-symbols-outlined"
                              onClick={() => {
                                ChangeStatus(ctr.id, "completed");
                              }}
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              task_alt
                            </span>
                          </div>
                          {ctr.time && (
                            <>
                              <hr />
                              <div class="stopwatch">
                                <h3 style={{ fontWeight: "bold" }}>
                                  {`${hours} : ${
                                    minutes === undefined ? 0 : minutes
                                  } : ${seconds}`}
                                </h3>
                                <div class="buttons">
                                  {!isRunning ? (
                                    <button
                                      onClick={startTimer}
                                      style={{ marginRight: "5px" }}
                                    >
                                      <span class="material-symbols-outlined">
                                        <TfiControlPlay />
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={pauseTimer}
                                      style={{ marginRight: "5px" }}
                                    >
                                      <span class="material-symbols-outlined">
                                        <TfiControlPause />
                                      </span>
                                    </button>
                                  )}
                                  <button
                                    onClick={() => {
                                      ChangeStatus(ctr.id, "stopped");
                                    }}
                                    style={{ marginRight: "5px" }}
                                  >
                                    <span
                                      class="material-symbols-outlined"
                                      id="stp"
                                    >
                                      stop
                                    </span>
                                  </button>
                                  <button
                                    onClick={() => resetTimer(ctr.timeAllowed)}
                                  >
                                    <span class="material-symbols-outlined">
                                      refresh
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                          {ctr.image && (
                            <div
                              style={{ padding: "8px", cursor: "pointer" }}
                              onClick={() => {
                                setIsViewerOpen(true);
                                setTaskImage(ctr.image);
                              }}
                            >
                              <img
                                src={ctr.image}
                                height={"50%"}
                                width={"100%"}
                                style={{ borderRadius: "8px" }}
                              />
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion> */}
                      <AccordionComponent
                        ctr={ctr}
                        viewImage={viewImage}
                        setViewImage={setViewImage}
                        setIsViewerOpen={setIsViewerOpen}
                        setTaskImage={setTaskImage}
                        Update={Update}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                        isRunning={isRunning}
                        startTimer={startTimer}
                        pauseTimer={pauseTimer}
                        resetTimer={resetTimer}
                        ChangeStatus={ChangeStatus}
                        setHours={setHours}
                        setMinute={setMinutes}
                        settaskId={settaskId}
                        setTaskStatus={setTaskStatus}
                        setnewTaskStatus={setnewTaskStatus}
                      />
                    </div>
                  </div>
                )}
                {filters === "2" && ctr.status === "stopped" && (
                  <div className="grid5">
                    <div className="minicards">
                      {/* <Accordion className="player" id="c2">
                        <AccordionSummary>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {ctr.task && (
                              <div>
                                {ctr.task.map((title) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    {tasks?.map((i) => {
                                      return (
                                        i.task === title &&
                                        i.type === ctr.type && (
                                          <img
                                            src={i.icon}
                                            height={"22px"}
                                            width={"22px"}
                                            alt=""
                                          />
                                        )
                                      );
                                    })}{" "}
                                    <h4 style={{ marginLeft: "10px" }}>
                                      {title}
                                    </h4>
                                  </div>
                                ))}
                              </div>
                            )}
                            <div
                              style={{
                                float: "right",
                              }}
                            >
                              {ctr?.time && (
                                <span
                                  className="time"
                                  style={{ color: "white" }}
                                >
                                  {ctr?.timeAllowed}
                                  {" mins"}
                                </span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <p>{ctr.description}</p>
                          <div className="span">
                            <span
                              className="material-symbols-outlined"
                              onClick={() => {
                                ChangeStatus(ctr.id, "pending");
                              }}
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              task_alt
                            </span>
                            <Link
                              to="/chat-child"
                              style={{ color: "white" }}
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              <span className="material-symbols-outlined">
                                chat
                              </span>
                            </Link>
                            <span
                              className="material-symbols-outlined"
                              onClick={() => {
                                setIsViewerOpen(true);
                                setTaskImage(ctr.image);
                              }}
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              attachment
                            </span>
                          </div>
                          {ctr.image && (
                            <div
                              style={{ padding: "8px", cursor: "pointer" }}
                              onClick={() => {
                                setIsViewerOpen(true);
                                setTaskImage(ctr.image);
                              }}
                            >
                              <img
                                src={ctr.image}
                                height={"50%"}
                                width={"100%"}
                                style={{ borderRadius: "8px" }}
                              />
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion> */}
                      <AccordionComponent
                        ctr={ctr}
                        viewImage={viewImage}
                        setViewImage={setViewImage}
                        setIsViewerOpen={setIsViewerOpen}
                        setTaskImage={setTaskImage}
                        Update={Update}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                        isRunning={isRunning}
                        startTimer={startTimer}
                        pauseTimer={pauseTimer}
                        resetTimer={resetTimer}
                        ChangeStatus={ChangeStatus}
                        setHours={setHours}
                        setMinute={setMinutes}
                        settaskId={settaskId}
                        setTaskStatus={setTaskStatus}
                        setnewTaskStatus={setnewTaskStatus}
                      />
                    </div>
                  </div>
                )}
                {filters === "3" && ctr.status === "completed" && (
                  <div className="grid5">
                    <div className="minicards">
                      {/* <Accordion className="player" id="c1">
                        <AccordionSummary>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {ctr.task && (
                              <div>
                                {ctr.task.map((title) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    {tasks?.map((i) => {
                                      return (
                                        i.task === title &&
                                        i.type === ctr.type && (
                                          <img
                                            src={i.icon}
                                            height={"22px"}
                                            width={"22px"}
                                            alt=""
                                          />
                                        )
                                      );
                                    })}{" "}
                                    <h4 style={{ marginLeft: "10px" }}>
                                      {title}
                                    </h4>
                                  </div>
                                ))}
                              </div>
                            )}
                            <div
                              style={{
                                float: "right",
                              }}
                            >
                              {ctr?.time && (
                                <span
                                  className="time"
                                  style={{ color: "white" }}
                                >
                                  {ctr?.timeAllowed}
                                  {" mins"}
                                </span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <p>{ctr.description}</p>
                          <div className="span">
                            <Link
                              to="/chat-child"
                              style={{ color: "white" }}
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              <span className="material-symbols-outlined">
                                chat
                              </span>
                            </Link>
                            <span
                              className="material-symbols-outlined"
                              onClick={() => {
                                setIsViewerOpen(true);
                                setTaskImage(ctr.image);
                              }}
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              attachment
                            </span>
                            <span
                            className="material-symbols-outlined"
                            onClick={() => {
                              ChangeStatus(ctr.id, "pending");
                            }}
                          >
                            task_alt
                          </span>
                          </div>
                          {ctr.image && (
                            <div
                              style={{ padding: "8px", cursor: "pointer" }}
                              onClick={() => {
                                setIsViewerOpen(true);
                                setTaskImage(ctr.image);
                              }}
                            >
                              <img
                                src={ctr.image}
                                height={"50%"}
                                width={"100%"}
                                style={{ borderRadius: "8px" }}
                              />
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion> */}
                      <AccordionComponent
                        ctr={ctr}
                        viewImage={viewImage}
                        setViewImage={setViewImage}
                        setIsViewerOpen={setIsViewerOpen}
                        setTaskImage={setTaskImage}
                        Update={Update}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                        isRunning={isRunning}
                        startTimer={startTimer}
                        pauseTimer={pauseTimer}
                        resetTimer={resetTimer}
                        ChangeStatus={ChangeStatus}
                        setHours={setHours}
                        setMinute={setMinutes}
                        settaskId={settaskId}
                        setTaskStatus={setTaskStatus}
                        setnewTaskStatus={setnewTaskStatus}
                      />
                    </div>
                  </div>
                )}
                {filters === "4" && ctr.status === "assigned" && (
                  <div className="grid5">
                    <div className="minicards">
                      {/* <Accordion className="player" id="c4">
                        <AccordionSummary>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {ctr.task && (
                              <div>
                                {ctr.task.map((title) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    {tasks?.map((i) => {
                                      return (
                                        i.task === title &&
                                        i.type === ctr.type && (
                                          <img
                                            src={i.icon}
                                            height={"22px"}
                                            width={"22px"}
                                            alt=""
                                          />
                                        )
                                      );
                                    })}{" "}
                                    <h4 style={{ marginLeft: "10px" }}>
                                      {title}
                                    </h4>
                                  </div>
                                ))}
                              </div>
                            )}
                            <div
                              style={{
                                float: "right",
                              }}
                            >
                              {ctr?.time && (
                                <span
                                  className="time"
                                  style={{ color: "white" }}
                                >
                                  {ctr?.timeAllowed}
                                  {" mins"}
                                </span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <p>{ctr.description}</p>
                          <div className="span">
                            <Link
                              to="/chat-child"
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                              style={{ color: "white" }}
                            >
                              <span className="material-symbols-outlined">
                                chat
                              </span>
                            </Link>
                            <span
                              className="material-symbols-outlined"
                              onClick={() => {
                                setIsViewerOpen(true);
                                setTaskImage(ctr.image);
                              }}
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              attachment
                            </span>
                            <span
                              className="material-symbols-outlined"
                              onClick={() => {
                                ChangeStatus(ctr.id, "pending");
                              }}
                              onMouseOver={({ target }) =>
                                (target.style.color =
                                  ctr.status === "pending"
                                    ? "#8d5c0a"
                                    : ctr.status === "stopped"
                                    ? "#a73215"
                                    : ctr.status === "completed"
                                    ? "#0a7f00"
                                    : "#a6a6a6")
                              }
                              onMouseOut={({ target }) =>
                                (target.style.color = "white")
                              }
                            >
                              task_alt
                            </span>
                          </div>
                          {ctr.image && (
                            <div
                              style={{ padding: "8px", cursor: "pointer" }}
                              onClick={() => {
                                setIsViewerOpen(true);
                                setTaskImage(ctr.image);
                              }}
                            >
                              <img
                                src={ctr.image}
                                height={"50%"}
                                width={"100%"}
                                style={{ borderRadius: "8px" }}
                              />
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion> */}
                      <AccordionComponent
                        ctr={ctr}
                        viewImage={viewImage}
                        setViewImage={setViewImage}
                        setIsViewerOpen={setIsViewerOpen}
                        setTaskImage={setTaskImage}
                        Update={Update}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                        isRunning={isRunning}
                        startTimer={startTimer}
                        pauseTimer={pauseTimer}
                        resetTimer={resetTimer}
                        ChangeStatus={ChangeStatus}
                        setHours={setHours}
                        setMinute={setMinutes}
                        settaskId={settaskId}
                        setTaskStatus={setTaskStatus}
                        setnewTaskStatus={setnewTaskStatus}
                      />
                    </div>
                  </div>
                )}
              </>
            )
          );
        })}
    </>
  );
};

const TaskChild = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTaskChild());
    dispatch(getFilteredTaskChild());
  }, []);

  const { childLoginData } = useSelector((state) => state.log);

  useEffect(() => {
    dispatch(getChildbyId(childLoginData?.id));
  }, []);

  function toggleBar() {
    document.getElementById("menuBar").classList.toggle("open-bar");
    document.getElementById("mainBar").classList.toggle("open-bar2");
  }
  const {
    taskDataChild,
    pendingTaskDataC,
    completedTaskDataC,
    stoppedTaskDataC,
    assignedTaskDataC,
  } = useSelector((state) => state.childTask);

  const pendingCount = taskDataChild?.filter(
    (i) => i.status === "pending"
  ).length;
  const stoppedCount = taskDataChild?.filter(
    (i) => i.status === "stopped"
  ).length;
  const assignedCount = taskDataChild?.filter(
    (i) => i.status === "assigned"
  ).length;
  const completedCount = taskDataChild?.filter(
    (i) => i.status === "completed"
  ).length;

  return (
    <section>
      <Header />
      <div class="sidebar" id="menuBar">
        <SideDrawerChild />
      </div>
      <div className="main" id="mainBar">
        <HeaderChild title="Task" toggle={toggleBar} />
        <main>
          <div className="cards" style={{ marginBottom: "20px" }}>
            <div className="card-one" id="four">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {assignedCount}
                  <span className="material-symbols-outlined">
                    trending_down
                  </span>
                </h2>
                <h6>TASKS ASSIGNED</h6>
              </div>
            </div>
            <div className="card-one" id="one">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {stoppedCount}{" "}
                  <span className="material-symbols-outlined">
                    trending_down
                  </span>
                </h2>

                <h6>TASKS INCOMPLETE</h6>
              </div>
            </div>
            <div className="card-one" id="two">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {pendingCount}{" "}
                  <span className="material-symbols-outlined">trending_up</span>
                </h2>

                <h6>TASKS PENDING</h6>
              </div>
            </div>
            <div className="card-one" id="three">
              <span className="material-symbols-outlined">add_task</span>
              <div className="text">
                <h2 style={{ fontFamily: "inherit", fontSize: "24px" }}>
                  {completedCount}{" "}
                  <span className="material-symbols-outlined">trending_up</span>
                </h2>

                <h6>TASKS DONE</h6>
              </div>
            </div>
          </div>

          <div className="page5">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="fullWidth"
                  centered
                  TabIndicatorProps={{ style: { background: "black" } }}
                  style={{
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderWidthRight: 0,
                  }}
                >
                  <Tab
                    value="1"
                    label="Task List"
                    style={{
                      color: "#000",
                      fontSize: "15.72px",
                      fontWeight: "600",
                      fontFamily: "wrchivo",
                    }}
                  />
                  <Tab
                    value="2"
                    label="Schedule"
                    style={{
                      color: "#000",
                      fontSize: "15.72px",
                      fontWeight: "600",
                      fontFamily: "wrchivo",
                    }}
                  />
                  <Tab
                    value="3"
                    label=" Grocery List"
                    style={{
                      color: "#000",
                      fontSize: "15.72px",
                      fontWeight: "600",
                      fontFamily: "wrchivo",
                    }}
                  />
                </Tabs>
              </Box>
              <TabPanel value="1" style={{ padding: 0 }}>
                <SecondComp type="Task List" />
              </TabPanel>
              <TabPanel value="2" style={{ padding: 0 }}>
                <SecondComp type="Schedule" />
              </TabPanel>
              <TabPanel value="3" style={{ padding: 0 }}>
                <SecondComp type="Grocery List" />
              </TabPanel>
            </TabContext>
          </div>
        </main>
      </div>
    </section>
  );
};

export default TaskChild;
