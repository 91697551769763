import React, { useState } from "react";
import { Drawer, Button, Box, MenuItem, Menu } from "@mui/material";
import Header from "./Header";
import { clear, imageUpload, setLogout } from "../redux/dispatcher/login";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../constants/config";
import { useEffect } from "react";
import Notifications from "react-notifications-menu";
import { useNavigate } from "react-router-dom";

const DEFAULT_NOTIFICATION = {
  image:
    "https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png",
  message: "test notification",
  detailPage: "/",
  receivedTime: "12h ago",
};

const HeaderChild = ({ title, toggle }) => {
  const [rotate, setRotate] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { childLoginData } = useSelector((state) => state.log);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editProfile, seteditProfile] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [dataUri, setDataUri] = useState(null);

  const onImageChange = (file) => {
    if (!file) {
      setDataUri("");
      return;
    }
    if (file.type === "image/png" || file.type === "image/jpeg") {
      fileToDataUri(file).then((dataUri) => {
        setDataUri(dataUri);
        // dataURItoBlob(file);
        dispatch(imageUpload(file, childLoginData?.email));
      });
    } else {
      console.log("Please select only png/jpeg format of image.");
    }
  };
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const { getChild } = useSelector((state) => state.childLog);

  const [notificationsData, setnotificationsData] =
    useState(DEFAULT_NOTIFICATION);

  const getNoti = () => {
    let token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}/api/notification/getNotificationChild`, {
        headers: {
          Authorization: `token ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setnotificationsData(res.data.notifications);
      })
      .catch((e) => {
        console.log(`msg error ${e}`);
      });
  };

  return (
    <header>
      <Header />
      <div className="page-info">
        <span
          className="material-symbols-outlined"
          style={{
            color: "#000",
            transform: rotate ? "rotate(180deg)" : "",
            transition: "transform 500ms ease",
          }}
          onClick={() => {
            toggle();
            setRotate(!rotate);
          }}
        >
          menu_open
        </span>

        <Drawer
          anchor={"right"}
          open={editProfile}
          onClose={() => seteditProfile(false)}
        >
          <Box
            width="700px"
            style={{
              padding: 0,
              height: "100%",
              overflow: "hidden",
            }}
          >
            <div className="popup" id="myForm">
              <div className="children">
                <div className="heading">
                  <h3>Edit Profile</h3>
                </div>
                <button
                  className="close-btn"
                  id="close-modal"
                  onClick={() => seteditProfile(false)}
                >
                  X
                </button>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  {!getChild?.image ? (
                    <img
                      src={require("../images/user.png")}
                      width="150px"
                      height="150px"
                      style={{ borderRadius: "75px" }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={getChild?.image}
                      width="150px"
                      height="150px"
                      style={{ borderRadius: "75px" }}
                      alt=""
                    />
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                  marginBottom: 0,
                }}
              >
                <input
                  accept="image/*"
                  type="file"
                  id="select-image"
                  style={{ display: "none" }}
                  onChange={(event) =>
                    onImageChange(event.target.files[0] || null)
                  }
                />
                <label htmlFor="select-image">
                  <Button variant="contained" color="primary" component="span">
                    Update Avatar
                  </Button>
                </label>
              </div>
              <div className="form-element">
                <div className="element">
                  <label for="name">
                    User Name<i>*</i>
                    <br />
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter username"
                    required
                    defaultValue={getChild?.name}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
              <hr id="hr" />
              <div className="bttns">
                <button className="bttn" onClick={() => seteditProfile(false)}>
                  Cancel
                </button>
                <button
                  className="bttn"
                  // onClick={() =>
                  //   dispatch(
                  //     updateParent(
                  //       getParent?.id,
                  //       username,
                  //       email,
                  //       getParent?.image
                  //     )
                  //   )
                  // }
                >
                  Update
                </button>
              </div>
            </div>
          </Box>
        </Drawer>
        <h3 style={{ color: "#000" }}>{title}</h3>
      </div>
      <div className="nav">
        {/* <span className="material-symbols-outlined" id="bell">
          notifications
        </span> */}

        <Notifications
          data={notificationsData}
          className="material-symbols-outlined"
          id="bell"
          header={{
            title: "Notifications",
            option: {
              text: "View All",
              onClick: () => console.log("Clicked"),
            },
          }}
          markAsRead={(notificationsData) => {
            console.log(notificationsData);
          }}
          // icon={() => <NotificationsNoneIcon />}
        />

        <div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* <MenuItem
              onClick={() => {
                seteditProfile(true);
                setAnchorEl(null);
              }}
            >
              Edit Profile
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                dispatch(setLogout());
                dispatch(clear());
                navigate("/sign-in-child");
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
        <div className="user-wrapper">
          <div className="user-name">
            <h5>{getChild?.name}</h5>
            <small>Assignee</small>
          </div>
          {getChild?.image ? (
            <img src={getChild?.image} width="35px" height="35px" alt="" />
          ) : (
            <img
              src={require("../images/user.png")}
              width="35px"
              height="35px"
              alt=""
            />
          )}
          <span className="material-symbols-outlined" onClick={handleClick}>
            expand_more
          </span>
        </div>
      </div>
    </header>
  );
};

export default HeaderChild;
