import {
  imageUploadReducer,
  loginChildReducer,
  loginCredentials,
  logoutRed,
} from "./reducers/loginReducer";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { taskReducer, taskReducerChild } from "./reducers/taskReducer";
import { assigneeReducer } from "./reducers/assigneeReducer";
import { chatReducer } from "./reducers/chatReducer";

const { configureStore, combineReducers } = require("@reduxjs/toolkit");
const rootPersistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  log: loginCredentials,
  childLog: loginChildReducer,
  logout: logoutRed,
  task: taskReducer,
  assignee: assigneeReducer,
  childTask: taskReducerChild,
  image: imageUploadReducer,
  chat: chatReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export default store;

export const persistor = persistStore(store);
