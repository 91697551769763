import React, { useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Slide,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const RedDialogModal = ({
  value,
  setValue,
  onClick,
  title,
  subtitle,
  width,
  buttonText,
}) => {
  return (
    <Dialog
      open={value}
      onClose={() => setValue(false)}
      PaperProps={{
        sx: {
          width: width,
          height: "65%",
          backgroundColor: "#E65849",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          paddingBottom: "5px",
          color: "white",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", color: "#FADDDA" }}>
          {subtitle}
        </DialogContentText>
        <div
          style={{
            margin: "5px 0px 5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75%",
          }}
        >
          <div
            style={{
              display: "flex",
              border: "1px solid",
              borderColor: "#FDF6F5",
              height: "85px",
              width: "85px",
              borderRadius: "360px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CloseIcon
              sx={{ color: "#FDF6F5", height: "55px", width: "55px" }}
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            backgroundColor: "#A3352C",
            color: "white",
            "&:hover": {
              backgroundColor: "#b55d56",
            },
            width: "50%",
            borderRadius: "18px",
            marginBottom: "15px",
            fontSize: "12px",
            textTransform: "none",
          }}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const GreenDialogModal = ({
  value,
  setValue,
  onClick,
  title,
  subtitle,
}) => {
  return (
    <Dialog
      open={value}
      onClose={() => setValue(false)}
      PaperProps={{
        sx: {
          width: "22.5%",
          height: "60%",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          paddingBottom: "5px",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center" }}>
          {subtitle}
        </DialogContentText>
        <div
          style={{
            margin: "15px 0px 5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75%",
          }}
        >
          <div
            style={{
              display: "flex",
              border: "1px solid",
              borderColor: "green",
              height: "85px",
              width: "85px",
              borderRadius: "360px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DoneIcon sx={{ color: "green", height: "55px", width: "55px" }} />
          </div>
        </div>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            backgroundColor: "green",
            color: "white",
            "&:hover": {
              backgroundColor: "#329932",
            },
            width: "50%",
            borderRadius: "18px",
            marginBottom: "15px",
          }}
          onClick={onClick}
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ConfirmationDialog = ({
  value,
  setValue,
  onClickYes,
  onClickNo,
  title,
  subtitle,
  deleteTask,
}) => {
  return (
    <Dialog
      open={value}
      onClose={() => setValue(false)}
      PaperProps={{
        sx: {
          width: "22.5%",
          height: "30%",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          paddingBottom: "5px",
          flexDirection: "row",
        }}
      >
        {/* {deleteTask ? (
          <DeleteForeverIcon
            sx={{ color: "red", height: "25px", width: "25px" }}
          />
        ) : (
          <DoneIcon sx={{ color: "green", height: "25px", width: "25px" }} />
        )} */}
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center" }}>
          {subtitle}
        </DialogContentText>
        {/* <div
          style={{
            margin: "15px 0px 5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75%",
          }}
        >
          <div
            style={{
              display: "flex",
              border: "1px solid",
              borderColor: deleteTask ? "red" : "green",
              height: "85px",
              width: "85px",
              borderRadius: "360px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {deleteTask ? (
              <DeleteForeverIcon
                sx={{ color: "red", height: "55px", width: "55px" }}
              />
            ) : (
              <DoneIcon
                sx={{ color: "green", height: "55px", width: "55px" }}
              />
            )}
          </div>
        </div> */}
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            backgroundColor: "green",
            color: "white",
            "&:hover": {
              backgroundColor: "#329932",
            },
            width: "40%",
            borderRadius: "18px",
            marginBottom: "15px",
          }}
          onClick={onClickYes}
        >
          Yes
        </Button>
        <Button
          sx={{
            backgroundColor: "#cc0000",
            color: "white",
            "&:hover": {
              backgroundColor: "#ff3232",
            },
            width: "40%",
            borderRadius: "18px",
            marginBottom: "15px",
          }}
          onClick={onClickNo}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
