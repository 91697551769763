import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../../redux/dispatcher/login";
import { useForm } from "react-hook-form";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../constants/config";

export default function ResetPassword() {
  const { id, token } = useParams();

  const [confirmPassword, setconfirmPassword] = useState("");
  const [password, setPassword] = useState("");

  const passwordRegex = /^(?=.*\d).{6,}$/;

  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);

  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const [confirmation, setconfirmation] = useState(false);

  const [errorState, seterrorState] = useState(false);

  const onSubmit = () => {
    seterrorState(false);
    setloading(true);

    if (password !== confirmPassword) {
      seterrorState(true);
    } else {
      axios
        .post(`${BASE_URL}/api/parent/reset-password/${id}/${token}`, {
          password: password,
          confirmPassword: confirmPassword,
        })
        .then((res) => {
          console.log(res.data);
          setconfirmation(true);
          setloading(false);
        })
        .catch((e) => {
          console.log(`reset error ${e}`);
          setloading(false);
        });
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <section>
      <Header />
      <body>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="main">
          <div className="grid">
            <div className="child1">
              <img
                src={require("../../images/logo.png")}
                width="150px"
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              />
              <div>
                <h1>
                  Ladder of
                  <br /> Remote Access
                </h1>
                <p>
                  A sophisticated, yet easy way to communicate within
                  <br />a family unit where parents get the support they need
                  <br />
                  to convey messages to assignee in the household for
                  <br />
                  task purpose, etc.
                </p>
              </div>
            </div>
            <div className="child2">
              <h1>Reset Password</h1>
              <p>
                Hey, enter your email to get
                <br />a link to reset your password.
              </p>

              {errors?.password?.type === "required" && (
                <p
                  style={{
                    color: "red",
                    fontSize: "10px",
                    alignSelf: "flex-start",
                    paddingBottom: "4px",
                  }}
                >
                  *Password is required
                </p>
              )}
              {errors?.password?.type === "minLength" && (
                <p
                  style={{
                    maxWidth: "95%",
                    color: "red",
                    fontSize: "10px",
                    alignSelf: "flex-start",
                    paddingBottom: "4px",
                    textAlign: "left",
                  }}
                >
                  *Password must contain at least 6 characters and 1 number
                </p>
              )}
              {errors?.password?.type === "pattern" && (
                <p
                  style={{
                    color: "red",
                    fontSize: "10px",
                    alignSelf: "flex-start",
                    paddingBottom: "4px",
                    textAlign: "left",
                  }}
                >
                  *Password must contain at least 6 characters
                  <br /> and 1 number
                </p>
              )}
              {errorState && (
                <p
                  style={{
                    color: "red",
                    fontSize: "10px",
                    alignSelf: "flex-start",
                    paddingBottom: 0,
                  }}
                >
                  *Password does not match
                </p>
              )}
              <div
                className="psswrd"
                style={{ marginTop: errors.password ? 0 : 16 }}
              >
                <input
                  type={!passwordToggle ? "password" : "text"}
                  id="password"
                  name="password"
                  {...register("password", {
                    required: true,

                    pattern: passwordRegex,
                  })}
                  placeholder="Enter password"
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span onClick={() => setPasswordToggle(!passwordToggle)}>
                  {passwordToggle ? (
                    <i className="material-symbols-outlined" id="eye">
                      visibility
                    </i>
                  ) : (
                    <i className="material-symbols-outlined" id="eye">
                      visibility_off
                    </i>
                  )}
                </span>
              </div>
              <div
                className="psswrd"
                style={{ marginTop: errors.password ? 0 : 16 }}
              >
                <input
                  type={!confirmPasswordToggle ? "password" : "text"}
                  id="password"
                  name="confirmPassword"
                  {...register("confirmPassword", {
                    required: true,

                    pattern: passwordRegex,
                  })}
                  placeholder="Confirm password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setconfirmPassword(e.target.value)}
                />
                <span
                  onClick={() =>
                    setConfirmPasswordToggle(!confirmPasswordToggle)
                  }
                >
                  {confirmPasswordToggle ? (
                    <i className="material-symbols-outlined" id="eye">
                      visibility
                    </i>
                  ) : (
                    <i className="material-symbols-outlined" id="eye">
                      visibility_off
                    </i>
                  )}
                </span>
              </div>

              <button
                id="signin"
                onClick={handleSubmit(onSubmit)}
                style={{ cursor: "pointer" }}
              >
                Reset
              </button>

              <img id="img5" src="./images/Vector.png" width="100px" alt="" />
              <img id="img2" src="./images/Vector2.png" width="100px" alt="" />
            </div>
          </div>
          <Dialog
            open={confirmation}
            onClose={() => setconfirmation(false)}
            PaperProps={{
              sx: {
                width: "22.5%",
                height: "30%",
              },
            }}
          >
            <DialogTitle
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                paddingBottom: "5px",
                flexDirection: "row",
              }}
            >
              {/* <DoneIcon
                sx={{ color: "green", height: "25px", width: "25px" }}
              /> */}
              Confirmation
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ textAlign: "center" }}>
                Your password has been reset successfully.
              </DialogContentText>
            </DialogContent>

            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "green",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#329932",
                  },
                  width: "60%",
                  borderRadius: "18px",
                  marginBottom: "15px",
                }}
                onClick={() => {
                  setconfirmation(false);
                  navigate("/sign-in");
                }}
              >
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </body>
    </section>
  );
}
