import React, { useState } from "react";
import Header from "./Header";
import HeaderComp from "./HeaderParent";
import { Backdrop, CircularProgress } from "@mui/material";
import { packages } from "../assets/data";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, publishableKey } from "../constants/config";
import axios from "axios";
import StripeCheckout from "react-stripe-checkout";
import moment from "moment";
import { getParentbyId } from "../redux/dispatcher/login";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SideDrawer from "./SideDrawer";
import { GreenDialogModal, RedDialogModal } from "./DialogModal";

export default function FirstTimePackage() {
  const { loginData, getParent } = useSelector((state) => state.log);

  const dispatch = useDispatch();

  const [confirm, setConfirm] = useState(false);

  const navigate = useNavigate();

  const [loading, setloading] = useState(false);

  const current = new Date();

  const [restrict, setRestrict] = useState(false);

  const payNow = async (token, price, assignee, id) => {
    setloading(true);
    setConfirm(false);

    try {
      let access_token = localStorage.getItem("token");
      console.log(price);
      const response = await axios.post(
        `${BASE_URL}/api/payment/paymentApi`,
        {
          price: price,
          currency: "usd",
          token: token,
          userId: loginData?.id,
          maxAssignee: assignee,
          expiryDate: moment(current).add(1, "M").format("MM-DD-YYYY"),
          packageId: id,
        },
        {
          headers: {
            Authorization: `token ${JSON.parse(access_token)}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response);
        setConfirm(true);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      setConfirm(false);
      setloading(false);
    }
  };

  useEffect(() => {
    dispatch(getParentbyId(loginData?.id));
  }, []);

  return (
    <>
      <div class="sidebar" id="menuBar">
        <SideDrawer />
      </div>
      <div className="main" id="mainBar">
        <Header />
        {/* <!--HEADER--> */}

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <RedDialogModal
          value={restrict}
          setValue={setRestrict}
          onClick={() => setRestrict(false)}
          title="Package"
          subtitle="You cannot buy the same package again."
          width={"20%"}
          buttonText="Okay"
        />
        <GreenDialogModal
          value={confirm}
          setValue={setConfirm}
          onClick={() => {
            setConfirm(false);
            navigate("/assignee");
            dispatch(getParentbyId(loginData?.id));
          }}
          title="Success!"
          subtitle="Payment Successful"
        />

        <HeaderComp title="Lora Packages" />

        <main id="bg-clr">
          <div className="grid1">
            <div className="lora">
              <p style={{ fontSize: 32, color: "#000" }}>Lora Packages</p>
              <p>World's best website for adults and minors...</p>
              <div className="butns">
                <p style={{ color: "#000" }} id="but">
                  MONTHLY
                </p>
              </div>
            </div>
            <div className="packages">
              {packages.map((pac, index) => {
                return (
                  <div className="caard" id={pac.color} key={pac.id}>
                    <img src={pac.image} width="30px" alt="" />
                    <p>
                      Full 1 Parental
                      <br />
                      Access With{" "}
                      <span>
                        {pac.assignee}
                        <br /> Assignee
                      </span>
                    </p>
                    <h3>${pac.price}</h3>

                    <StripeCheckout
                      stripeKey={publishableKey}
                      label="Pay Now"
                      name="Pay With Credit Card"
                      amount={pac.price * 100}
                      description={`Your total is $${pac.price}`}
                      token={(token) => {
                        {
                          getParent.packageId === pac.id
                            ? setRestrict(true)
                            : payNow(
                                token,
                                Math.floor(pac.price * 100),
                                pac.assignee,
                                pac.id
                              );
                        }
                      }}
                    >
                      <button id="buy">Buy Now</button>
                    </StripeCheckout>

                    {/* <Drawer
                      anchor={"right"}
                      open={open}
                      onClose={() => setOpen(false)}
                    >
                      <Box
                        width="700px"
                        style={{
                          padding: 0,
                          height: "100%",
                          marginLeft: null,
                          marginRight: 0,
                          overflow: "hidden",
                        }}
                      >
                        <div class="popup" id="myForm">
                          <div class="children">
                            <div class="heading">
                              <h5>Lora Packages</h5>
                              <h3>Buy 3 Assignees Package</h3>
                            </div>
                            <button
                              class="close-btn"
                              id="close-modal"
                              onClick={() => setOpen(false)}
                            >
                              X
                            </button>
                          </div>

                          <hr />
                          <div class="form-element">
                            <div class="element">
                              <label for="name">
                                Card Holder Name<i>*</i>
                                <br />
                                <span id="mins">e.g. William Marshal</span>
                              </label>
                              <input
                                type="text"
                                id="name"
                                placeholder="Enter name"
                                required
                              />
                            </div>
                            <div class="element">
                              <label for="task">
                                Card CVV<i>*</i>
                                <br />
                                <span id="mins">e.g. 123</span>
                              </label>
                              <input type="tel" placeholder="Enter CVV no." />
                            </div>
                            <div class="element">
                              <div class="timer">
                                <label for="timer">
                                  Card Expiry<i>*</i>
                                  <br />
                                  <span id="mins">e.g. 12/22</span>
                                </label>
                              </div>
                              <div class="tmr-btn">
                                <input
                                  type="date"
                                  id="time"
                                  value="12/22/2022"
                                />
                              </div>
                            </div>
                          </div>
                          <hr id="hr" />
                          <div class="bttns">
                            <button class="bttn">Cancel</button>
                            <button class="bttn">Buy</button>
                          </div>
                        </div>
                      </Box>
                    </Drawer> */}
                    {/* <Modal
                        open={open}
                        onClose={() => setOpenWithHeader(true)}
                      >
                        <Modal.Header>
                          <Modal.Title>Modal Title</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Placeholder.Paragraph />
                        </Modal.Body>
                        <Modal.Footer>
                          {!success ? (
                            <form onSubmit={() => handleSubmit(pac.price)}>
                              <fieldset className="FormGroup">
                                <div className="FormRow">
                                  <CardElement options={CARD_OPTIONS} />
                                </div>
                              </fieldset>
                              <button>Pay</button>
                            </form>
                          ) : (
                            <div>
                              <h2>
                                You just bought a sweet spatula congrats this is
                                the best decision of you're life
                              </h2>
                            </div>
                          )}
                          <Button onClick={handleClose} appearance="primary">
                            Ok
                          </Button>
                          <Button onClick={handleClose} appearance="subtle">
                            Cancel
                          </Button>
                        </Modal.Footer>
                      </Modal> */}
                  </div>
                );
              })}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
